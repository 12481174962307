<template>
    <div class="product-intro__thumb">
        <v-sheet>
            <!-- 기본 이미지 -->
            <!-- <v-card tile class="product-intro__thumb-main">
                <div class="square-img" :style="`background-image:url(${imgUrl})`" />
            </v-card> -->

            <div class="pt-16px pt-sm-24px">
                <template v-for="(image, index) in product?.images">
                    <v-img v-if="image" tile flat :src="image?.url" :key="index" class="product-image" />
                    <!-- <div v-if="image" class="square-img" :style="`background-image:url(${image?.url})`" /> -->
                </template>
            </div>
        </v-sheet>

        <!-- legacy -->
        <!-- <div class="pt-16px pt-sm-24px">
            <v-row class="row--sm">
                <template v-for="(image, index) in product?.images">
                    <v-col cols="auto" :key="`thumb-${index}`" style="width: 20%">
                        <v-card class="product-intro__thumb-button overflow-hidden" role="button" :aria-pressed="index == selectedIndex" @click="selectedIndex = index">
                            <div v-if="image" class="square-img" :style="`background-image:url(${image?.url})`" />
                        </v-card>
                    </v-col>
                </template>
            </v-row>

            <div class="product-intro-thumb-slide">
                <swiper class="swiper" :options="swiperOptions">
                    <template v-for="(image, index) in product?.images">
                        <swiper-slide :key="`thumb-${index}`">
                            <v-card class="product-intro__thumb-button overflow-hidden" role="button" :aria-pressed="index == selectedIndex" @click="selectedIndex = index">
                                <div v-if="image" class="square-img" :style="`background-image:url(${image?.url})`" />
                            </v-card>
                        </swiper-slide>
                    </template>
                    <div slot="pagination">
                        <div class="swiper-button-prev">
                            <v-icon>mdi-chevron-left</v-icon>
                        </div>
                        <div class="swiper-button-next">
                            <v-icon>mdi-chevron-right</v-icon>
                        </div>
                    </div>
                </swiper>
            </div>
        </div> -->
    </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import { initProduct } from "@/assets/variables";

export default {
    components: {
        Swiper,
        SwiperSlide,
    },
    props: {
        product: { type: Object, default: initProduct },
    },
    data: () => ({
        selectedIndex: 0,

        swiperOptions: {
            allowTouchMove: true,
            centeredSlides: false,
            slidesPerView: 1,
            spaceBetween: 10,
            speed: 500,
            lazy: {
                loadPrevNext: true,
            },
            loop: false,
            pagination: {
                el: ".swiper-pagination",
                clickable: true,
            },
            // navigation: {
            //     nextEl: ".product-intro-thumb-slide .swiper-button-next",
            //     prevEl: ".product-intro-thumb-slide .swiper-button-prev",
            // },
            // breakpoints: {
            //     576: {
            //         slidesPerView: 4,
            //         spaceBetween: 10,
            //     },
            //     1200: {
            //         slidesPerView: 5,
            //         spaceBetween: 12,
            //     },
            // },
        },
    }),
    computed: {
        imgUrl() {
            return this.product.images?.[this.selectedIndex]?.url;
        },
    },
};
</script>

<style lang="scss" scoped>
.product-intro-thumb-slide {
    .swiper-container {
        padding: 0 32px;
    }
    .swiper-button-prev,
    .swiper-button-next {
        width: 24px !important;
        height: calc(100% + 2px) !important;
        margin-top: 0;
        border-radius: 0;
        background-color: #fff !important;
        -webkit-transform: translate(0, -50%);
        -ms-transform: translate(0, -50%);
        transform: translate(0, -50%);
        .v-icon {
            color: var(--v-grey-darken4);
        }
        &.swiper-button-disabled {
            opacity: 1 !important;
            .v-icon {
                opacity: 0.35;
            }
        }
    }
}

.product-image {
    max-width: calc(100vw - var(--grid-gutter) * 2);
}
</style>
