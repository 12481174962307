var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.detect == 'pc' ? [_c('v-breadcrumbs', {
    staticClass: "d-none d-lg-flex",
    attrs: {
      "items": _vm.breadcrumbsItems
    },
    scopedSlots: _vm._u([{
      key: "divider",
      fn: function () {
        return [_c('v-icon', {
          attrs: {
            "small": ""
          }
        }, [_vm._v("mdi-chevron-right")])];
      },
      proxy: true
    }, {
      key: "item",
      fn: function (_ref) {
        var item = _ref.item;
        return [item.text == 'HOME' ? [_c('v-breadcrumbs-item', {
          attrs: {
            "to": item.to,
            "exact": ""
          }
        }, [_vm._v(" " + _vm._s(item.text) + " ")])] : item.text == 'Category' ? [_c('v-breadcrumbs-item', {
          attrs: {
            "to": item.to,
            "exact": ""
          }
        }, [_vm._v(" " + _vm._s(item.text) + " ")])] : [_c('v-breadcrumbs-item', {
          attrs: {
            "to": item.to,
            "exact": ""
          }
        }, [_vm._v(" " + _vm._s(item.text) + " ")])]];
      }
    }], null, false, 953569401)
  })] : _vm._e(), _vm.detect == 'mobile' ? [_c('v-breadcrumbs', {
    staticClass: "d-lg-none",
    attrs: {
      "items": _vm.breadcrumbsItems
    },
    scopedSlots: _vm._u([{
      key: "divider",
      fn: function () {
        return [_c('v-icon', {
          attrs: {
            "small": ""
          }
        }, [_vm._v("mdi-chevron-right")])];
      },
      proxy: true
    }, {
      key: "item",
      fn: function (_ref2) {
        var item = _ref2.item;
        return [item.text == 'HOME' ? [_c('v-breadcrumbs-item', {
          attrs: {
            "to": "/",
            "exact": ""
          }
        }, [_c('v-icon', {
          staticClass: "font-size-20"
        }, [_vm._v("mdi-home-outline")])], 1)] : item.text == 'Category' ? [_c('v-breadcrumbs-item', {
          attrs: {
            "to": "/shop/products",
            "exact": ""
          }
        }, [_vm._v(" " + _vm._s(item.text) + " ")])] : [_c('v-breadcrumbs-item', {
          on: {
            "click": function ($event) {
              _vm.categoryMenu !== item.categories ? _vm.categoryMenu = item.categories : _vm.categoryMenu = undefined;
            }
          }
        }, [_vm._v(" " + _vm._s(item.text) + " ")])]];
      }
    }], null, false, 268177375)
  }), _vm._l(_vm.breadcrumbsItems, function (item) {
    var _item$parentTo, _item$to;
    return [item.categories ? _c('v-expand-transition', {
      key: item.text
    }, [_c('v-list', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.categoryMenu === item.categories,
        expression: "categoryMenu === item.categories"
      }],
      staticClass: "breadcrumbs-list"
    }, [item.to.query.category != undefined && item.to.query.category == _vm.category.code ? [_c('v-list-item', {
      attrs: {
        "exact": "",
        "to": Object.assign({}, item.parentTo, {
          query: Object.assign({}, _vm.$route.query, (item === null || item === void 0 ? void 0 : (_item$parentTo = item.parentTo) === null || _item$parentTo === void 0 ? void 0 : _item$parentTo.query) || {})
        })
      },
      on: {
        "click": function ($event) {
          $event.preventDefault();
          ((_vm.categoryMenu = undefined) || true) && _vm.$router.push({
            query: {
              category: item.parentTo.query.category
            }
          });
        }
      }
    }, [_vm._v(" 전체보기 ")])] : [_c('v-list-item', {
      attrs: {
        "exact": "",
        "to": Object.assign({}, item.to, {
          query: Object.assign({}, _vm.$route.query, (item === null || item === void 0 ? void 0 : (_item$to = item.to) === null || _item$to === void 0 ? void 0 : _item$to.query) || {})
        })
      },
      on: {
        "click": function ($event) {
          $event.preventDefault();
          ((_vm.categoryMenu = undefined) || true) && _vm.$router.push({
            query: {
              category: item.to.query.category
            }
          });
        }
      }
    }, [_vm._v(" 전체보기 ")])], _vm._l(item.categories, function (item) {
      return [_c('v-list-item', {
        key: item._id,
        attrs: {
          "exact": "",
          "to": {
            query: Object.assign({}, _vm.$route.query, {
              category: item.code
            })
          }
        },
        on: {
          "click": function ($event) {
            $event.preventDefault();
            ((_vm.categoryMenu = undefined) || true) && _vm.$router.push({
              query: {
                category: item.to.query.category
              }
            });
          }
        }
      }, [_vm._v(" " + _vm._s(item.name) + " ")])];
    })], 2)], 1) : _vm._e()];
  })] : _vm._e()], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }