var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', _vm._b({
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_vm._t("activator", null, null, {
          attrs,
          on
        })];
      }
    }], null, true),
    model: {
      value: _vm.shows,
      callback: function ($$v) {
        _vm.shows = $$v;
      },
      expression: "shows"
    }
  }, 'v-dialog', Object.assign({}, _vm.$attrs, {
    width: _vm.width
  }), false), [_c('v-card', {
    attrs: {
      "tile": ""
    }
  }, [_c('v-row', {
    attrs: {
      "no-gutters": "",
      "justify": "end"
    }
  }, [_c('v-btn', {
    attrs: {
      "large": "",
      "icon": "",
      "tile": "",
      "text": "",
      "color": "primary"
    },
    on: {
      "click": _vm.closeDialog
    }
  }, [_c('v-icon', [_vm._v("mdi-close")])], 1)], 1), _c('v-sheet', {
    staticClass: "px-12px pb-12px px-md-52px pb-md-52px"
  }, [_vm._t("default")], 2)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }