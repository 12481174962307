<template>
    <v-tab-item value="list">
        <v-row class="row--xs">
            <template v-for="image in images">
                <v-col cols="3" sm="2" :key="image._id">
                    <v-card outlined rounded="md" :color="value?._image == image?._id ? 'primary' : 'transparent'" class="overflow-hidden" @click="$emit('input', { _image: image._id, _review: image._review })">
                        <v-img cover :aspect-ratio="1 / 1" v-bind="image" />
                    </v-card>
                </v-col>
            </template>
        </v-row>
        <div class="pagination-wrap pagination-wrap--sm">
            <v-pagination v-model="page" :length="pageCount" :total-visible="11" />
        </div>
    </v-tab-item>
</template>

<script>
import api from "@/api";

export default {
    props: {
        value: { type: Object, default: () => ({ _image: null, _review: null }) },
        _product: { type: String },
    },
    data: () => ({
        images: [],

        page: 1,
        limit: 18,
        summary: { totalCount: 0 },

        loading: false,
    }),
    computed: {
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
    },
    mounted() {
        this.search();
    },
    watch: {
        page() {
            this.search();
        },
    },
    methods: {
        async init() {
            try {
                this.images = [];
                this.page = 1;

                this.search();
            } catch (error) {
                this.$handleError(error);
            }
        },
        async search() {
            if (this.loading) return;
            else this.loading = true;

            try {
                let { skip, limit } = this;
                var { summary, images } = await api.v1.shop.reviewImages.gets({
                    headers: { skip, limit },
                    params: { _product: this.$props._product },
                });

                this.summary = summary;
                this.images = images;
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style></style>
