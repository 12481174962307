<template>
    <v-sheet color="transparent" class="text-center d-flex justify-space-between justify-md-center">
        <div class="d-md-none grey--text text--darken-4 font-weight-medium text-left mb-4px">할인</div>
        <div>{{ totalDiscountPrice?.format?.() }}원</div>
        <!-- <cart-item-coupons-products v-model="coupons" v-bind="{ order: value, carts, product }" @input="emit" ref="cart-item-coupons">
            <template #activator="{ attrs, isActive, on }">
                <v-btn v-bind="{ ...btn_secondary, ...attrs, outlined: !isActive }" v-on="on" x-small class="mt-2">
                    <template v-if="isActive"> 쿠폰 적용완료 </template>
                    <template v-else> 쿠폰 사용하기 </template>
                </v-btn>
            </template>
        </cart-item-coupons-products> -->
    </v-sheet>
</template>

<script>
import { mapGetters } from "vuex";

import { btn_secondary } from "@/assets/variables/attrs";
import { initOrder } from "@/assets/variables/inits";
import { USAGE_TARGET_PRICES } from "@/assets/variables/constants";

import CartItemCouponsProducts from "@/components/client/shop/order/carts/cart-item-coupons-products.vue";

export default {
    components: {
        CartItemCouponsProducts,
    },
    props: {
        value: { type: Object, default: initOrder },
        productGroup: { type: Object, default: () => ({}) },
    },
    data: () => ({
        coupons: [],
        btn_secondary,
    }),
    computed: {
        ...mapGetters(["userLevel"]),

        carts() {
            return this.productGroup.carts;
        },
        product() {
            return this.productGroup.product;
        },
        discountPrice() {
            return this.$getDiscountPrice(this.productGroup.carts);
        },
        couponPrice() {
            return this.$getCouponPrice(this.productGroup.carts, this.coupons, this.value?.receiver?.geolocation, this.userLevel);
        },
        levelDiscountPrice() {
            return this.$getLevelDiscountPrice(this.productGroup.carts, this.userLevel);
        },
        totalDiscountPrice() {
            // return this.discountPrice + this.couponPrice + this.levelDiscountPrice;
            return this.couponPrice;
        },
    },
    methods: {
        async init() {
            await this.$refs["cart-item-coupons"]?.init?.();
        },
        sync() {
            this.coupons = this.value.coupons.filter((coupon) => !["_product"].some((key) => coupon[key] != this.productGroup[key]) && coupon?.usage?.target?.price == USAGE_TARGET_PRICES.SHOP_PRODUCT_PRICE.value);
        },
        emit() {
            let [...coupons] = this.coupons;
            coupons = coupons.concat(this.value.coupons.filter((coupon) => ["_product"].some((key) => coupon[key] != this.productGroup[key]) || coupon?.usage?.target?.price != USAGE_TARGET_PRICES.SHOP_PRODUCT_PRICE.value));

            this.$emit("input", initOrder({ ...this.value, coupons }));
        },
    },
    watch: {
        value() {
            this.sync();
        },
    },
    mounted() {
        this.sync();
    },
};
</script>

<style>
</style>