<template>
    <div v-show="activePoints" class="py-16px px-20px border-b">
        <v-row align="center" class="row--xs txt txt--xs txt--dark">
            <v-col cols="5" sm="3" md="2" xl="5" class="font-weight-medium">
                <span>예상 적립 혜택</span>
            </v-col>
            <v-col cols="7" sm="9" md="10" xl="7">
                <order-point-benefit-view v-bind="{ type: ISSUE_EVENT_TYPES.PURCHASE_COMPLETE.value }" @search="count" />
                <!-- <order-point-benefit-view v-bind="{ type: ISSUE_EVENT_TYPES.REVIEW_TEXT_WRITE.value }" @search="count"/>
                <order-point-benefit-view v-bind="{ type: ISSUE_EVENT_TYPES.REVIEW_IMG_UPLOAD.value }" @search="count"/> -->
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { ISSUE_EVENT_TYPES } from "@/assets/variables";

import OrderPointBenefitView from "@/components/client/shop/order/order-benefits/order-point-benefit-view.vue";

export default {
    components: {
        OrderPointBenefitView,
    },
    data: () => ({
        ISSUE_EVENT_TYPES,
        activePoints: 0,
    }),
    methods: {
        count(point) {
            if (point?.usage?.isActive) this.activePoints++;
        },
    },
};
</script>

<style>
</style>