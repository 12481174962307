<template>
    <form ref="payForm" name="payForm" method="post" :action="actionUrl" accept-charset="euc-kr">
        <input type="hidden" name="PayMethod" value="CARD,BANK" />
        <input type="hidden" name="GoodsName" :value="goodsName" />
        <input type="hidden" name="Amt" :value="amt" />
        <input type="hidden" name="MID" :value="merchantID" />
        <input type="hidden" name="Moid" :value="moid" />
        <input type="hidden" name="BuyerName" :value="buyerName" />
        <input type="hidden" name="BuyerEmail" :value="buyerEmail" />
        <input type="hidden" name="BuyerTel" :value="buyerTel" />
        <input type="hidden" name="ReturnURL" :value="returnURL" />
        <input type="hidden" name="VbankExpDate" value="" />
        <!-- <input type="hidden" name="WapUrl" :value="WapUrl" /> -->
        <!-- <input type="hidden" name="IspCancelUrl" :value="IspCancelUrl" /> -->

        <input type="hidden" name="NpLang" value="KO" />
        <!-- EN:English, CN:Chinese, KO:Korean -->
        <input type="hidden" name="GoodsCl" value="1" />
        <!-- products(1), contents(0)) -->
        <input type="hidden" name="TransType" value="0" />
        <!-- USE escrow false(0)/true(1) -->
        <input type="hidden" name="CharSet" value="utf-8" />
        <!-- Return CharSet -->
        <input type="hidden" name="ReqReserved" value="" />
        <!-- mall custom field -->

        <!-- DO NOT CHANGE -->
        <input type="hidden" name="EdiDate" :value="ediDate" />
        <!-- YYYYMMDDHHMISS -->
        <input type="hidden" name="SignData" :value="hashString" />
        <!-- EncryptData -->
    </form>
</template>

<script>
import api from "@/api";
import CryptoJS from "crypto-js";
import { NICEPAY_PURCAHSE_TYPES } from '@/assets/variables';
export default {
    data() {
        return {
            // 요청시간
            // ediDate: null,
            // 상품 금액
            amt: null,
            // 요청 응답 URL
            returnURL: null,
            // 결제상품명
            goodsName: null,
            // 상품 주문번호
            moid: null,
            // 구매자명
            buyerName: null,
            // 구매자 이메일
            buyerEmail: null,
            // 구매자 전화번호
            buyerTel: null,
            // 가맹점 아이디
            merchantID: null,
            // 위변조 구분 문자열
            hashString: null,
            // 결제 후 돌아올 스키마 값
            // WapUrl: null,
            // IspCancelUrl: null
            // 결제 타입 (NICEPAY_PURCAHSE_TYPES)
            type: null
        };
    },
    computed: {
        ediDate() {
            return this.$dayjs().format("YYYYMMDDHHmmss");
        },
        actionUrl() {
            switch(this.type){
                //상품구매
                case NICEPAY_PURCAHSE_TYPES.SHOP_ORDERS.value:
                    return `/api/plugins/nice/pay/shop-products`;
                //개인결제
                case NICEPAY_PURCAHSE_TYPES.USER_PAYMENTS.value:
                    return `/api/plugins/nice/pay/user-payments`;
                //리퀘스트
                case NICEPAY_PURCAHSE_TYPES.SHOP_SERVICES.value:
                    return `/api/plugins/nice/pay/shop-services`;
            };
        }
    },
    async mounted() {
        window.nicepaySubmit = () => {
            this.$refs.payForm.submit();
        };
        window.nicepayClose = () => {
            alert("결제가 취소 되었습니다");
        };

        let { merchantID } = await api.plugins.nice.pay.config.get();
        this.merchantID = merchantID;
        // this.WapUrl = "appSchemaValue://"
        // this.IspCancelUrl = "appSchemaValue://"
    },
    methods: {
        async nicepayStart() {
            const { ediDate, amt } = this;
            this.hashString = (await api.plugins.nice.pay.generate({ ediDate, amt }))?.hashString;
            switch(this.type){
                //상품구매
                case NICEPAY_PURCAHSE_TYPES.SHOP_ORDERS.value:
                    this.returnURL = `${window.location.protocol.replace(/[^a-z]/g, "")}://${window.location.host}/api/plugins/nice/pay/shop-products`;
                    break;
                //개인결제
                case NICEPAY_PURCAHSE_TYPES.USER_PAYMENTS.value:
                    this.returnURL = `${window.location.protocol.replace(/[^a-z]/g, "")}://${window.location.host}/api/plugins/nice/pay/user-payments`;
                    break;
                //리퀘스트
                case NICEPAY_PURCAHSE_TYPES.SHOP_SERVICES.value:
                    this.returnURL = `${window.location.protocol.replace(/[^a-z]/g, "")}://${window.location.host}/api/plugins/nice/pay/shop-services`;
                    break;
            };

            this.$nextTick((_) => {
                if (this.checkPlatform(window.navigator.userAgent) == "mobile") {
                    document.payForm.action = "https://web.nicepay.co.kr/v3/v3Payment.jsp";
                    document.payForm.acceptCharset = "euc-kr";
                    document.payForm.submit();
                } else {
                    goPay(document.payForm);
                }
            });
        },
        checkPlatform(ua) {
            if (ua === undefined) {
                ua = window.navigator.userAgent;
            }

            ua = ua.toLowerCase();
            var platform = {};
            var matched = {};
            var userPlatform = "pc";
            var platform_match = /(ipad)/.exec(ua) || /(ipod)/.exec(ua) || /(windows phone)/.exec(ua) || /(iphone)/.exec(ua) || /(kindle)/.exec(ua) || /(silk)/.exec(ua) || /(android)/.exec(ua) || /(win)/.exec(ua) || /(mac)/.exec(ua) || /(linux)/.exec(ua) || /(cros)/.exec(ua) || /(playbook)/.exec(ua) || /(bb)/.exec(ua) || /(blackberry)/.exec(ua) || [];

            matched.platform = platform_match[0] || "";

            if (matched.platform) {
                platform[matched.platform] = true;
            }

            if (platform.android || platform.bb || platform.blackberry || platform.ipad || platform.iphone || platform.ipod || platform.kindle || platform.playbook || platform.silk || platform["windows phone"]) {
                userPlatform = "mobile";
            }

            if (platform.cros || platform.mac || platform.linux || platform.win) {
                userPlatform = "pc";
            }

            return userPlatform;
        },
    },
};
</script>
