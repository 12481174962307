<template>
    <div v-if="$vuetify.breakpoint.xlOnly" class="product-list-filter">
        <div class="py-20px py-md-30px">
            <filter-categories v-bind="{ brand }" :code="categoryCode" @search="$emit('search')" />
            <v-divider dark />
        </div>
        <div class="py-20px py-md-30px">
            <filter-prices />
        </div>
    </div>

    <div v-else>
        <filter-prices />
    </div>
</template>

<script>
import FilterPrices from "./filter-prices.vue";
import FilterCategories from "./filter-categories.vue";

export default {
    components: {
        FilterPrices,
        FilterCategories,
    },
    props: {
        brand: { type: Object },
        categoryCode: { type: String },
    },
};
</script>

<style>
.product-list-filter {
    transform: translateY(-28px);
}
</style>
