<template>
    <div id="nicepay" class="nicepay">
        <iframe v-if="show" ref="iframe" :src="'/plugins/nicepayments/request?' + queryString" width="100%" height="100%" marginwidth="0" marginheight="0" frameborder="no" scrolling="no" class="screen" allowtransparency="true" style="display: none"></iframe>
    </div>
</template>
    
<script>
export default {
    data() {
        return {
            show: false,

            collection: "",
            orderNo: null,
            orderName: "",
            paymentGateway: "",
            paymentMethod: "",
            paymentAmount: 0,
            buyerName: "",
            buyerPhone: "",
            buyerEmail: "",
            vbankHolder: ""
        }
    },
    methods: {
        close(){
            this.show = false;
            window.nicepayComplete = undefined;
            window.nicepayClose = undefined;
        },
        async pay() {
            this.show = true;
            this.$nextTick(() => {
                window.nicepayComplete = (payload) => {
                    this.$emit("complete", payload);
                    this.$emit("change", payload);
                    this.$emit("input", payload);
                    this.close();
                };
                window.nicepayClose = () => {
                    this.close();
                };

                this.$refs.iframe.src = `/plugins/nicepayments/request?${this.queryString}`;
                this.$refs.iframe.style.display = this.display;
            });
        },
    },
    computed: {
        queryString(){
            return Object.keys(this.$data).reduce((qs, key) => `${qs}${qs ? '&' : ''}${key}=${encodeURI(this.$data[key])}`, "");
        },
        display(){
            if (this.show) {
                // 모바일일때 iframe 화면 보이기
                return "";
            } else {
                // PC일때 iframe 가리고, 팝업
                return "none";
            }
        }
    }
};
</script>

<style scoped>
#nicepay .screen {
    position: fixed;
    width: 100%;
    height: 100vh;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 99999;
    background: rgba(0, 0, 0, 0.5);
}
</style>