var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-sheet', [_c('v-simple-table', {
    staticClass: "v-data-table--default v-data-table--simple-mobile v-data-table--no-hover"
  }, [_c('result-head'), _vm._l(_vm.merchantGroups, function (merchantGroup, index) {
    return [_c('result-body', _vm._b({
      key: index,
      model: {
        value: _vm.value,
        callback: function ($$v) {
          _vm.value = $$v;
        },
        expression: "value"
      }
    }, 'result-body', {
      merchantGroup
    }, false))];
  })], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }