var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.activePoints,
      expression: "activePoints"
    }],
    staticClass: "py-16px px-20px border-b"
  }, [_c('v-row', {
    staticClass: "row--xs txt txt--xs txt--dark",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "font-weight-medium",
    attrs: {
      "cols": "5",
      "sm": "3",
      "md": "2",
      "xl": "5"
    }
  }, [_c('span', [_vm._v("예상 적립 혜택")])]), _c('v-col', {
    attrs: {
      "cols": "7",
      "sm": "9",
      "md": "10",
      "xl": "7"
    }
  }, [_c('order-point-benefit-view', _vm._b({
    on: {
      "search": _vm.count
    }
  }, 'order-point-benefit-view', {
    type: _vm.ISSUE_EVENT_TYPES.PURCHASE_COMPLETE.value
  }, false))], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }