<template>
    <dialog-basic>
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" v-on="on" />
        </template>

        <div class="tit-wrap tit-wrap--sm">
            <v-card-title class="tit tit--xxs pa-0">AS 협력점 정보확인</v-card-title>
        </div>
        <v-simple-table class="v-data-table--no-hover">
            <template #default>
                <tbody>
                    <template v-for="item in items">
                        <tr :key="item.th">
                            <th>{{ item.th }}</th>
                            <td>{{ item.td }}</td>
                        </tr>
                    </template>
                </tbody>
            </template>
        </v-simple-table>
        <div class="txt txt--sm txt--dark mt-16px mt-md-18px mt-lg-20px">
            <p class="font-weight-bold secondary--text">AS 협력점(전화번호, 이메일, 주소, 등)은 해당 AS 협력점의 명시적 동의 없이영리 목적인 마케팅·광고 등 용도로 활용할 수 없습니니다.</p>
            <p>판매자의 명시적 동의 없이 판매자의 정보를수집 및 활용하여 영리 목적인 마케팅·광고 등 용도로 활용하는 경우 정보통신망법 등 관련 법령에 의거하여 과태료 부과 및 형사처럴의 대상이 될 수 있습니다.</p>
        </div>
    </dialog-basic>
</template>

<script>
import DialogBasic from "@/components/client/dumb/dialog-basic.vue";

export default {
    components: {
        DialogBasic,
    },
    props: {
        value: { type: Object, default: () => ({}) },
    },
    data: () => ({
        shows: false,
    }),
    computed: {
        items() {
            return [
                { th: "상호명", td: this.value.companyName },
                { th: "대표자", td: this.value.ceoName },
                { th: "사업장번호", td: this.value?.tel },
                { th: "사업자등록번호", td: this.value.registrationNumber },
                { th: "사업장소재지", td: `${this.value.address1} ${this.value.address2}` },
                { th: "통신판매업번호", td: this.value.licenseCode },
                { th: "e-mail", td: this.value.email },
            ];
        },
    },
    methods: {
        closeDialog() {
            this.shows = !this.shows;
        },
    },
};
</script>

<style lang="scss" scoped>
.v-data-table {
    border-top: 2px solid var(--v-primary-base);
    tr {
        border-bottom: 1px solid var(--border-color);
        > th {
            border-right: 1px solid var(--border-color);
            text-align: center !important;
        }
    }
}
</style>