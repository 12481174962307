<template id="modal-template">
    <u-dialog v-model="shows" name="modal">
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>

        <div class="tit-wrap tit-wrap--sm">
            <v-card-title class="tit tit--xxs pa-0">문의하기</v-card-title>
        </div>

        <v-simple-table class="v-data-table--no-hover">
            <tbody>
                <tr>
                    <th class="w-20">문의유형</th>
                    <td>
                        <v-select v-model="inquire.type" :items="types" dense outlined hide-details placeholder="문의유형을 선택해 주세요." />
                    </td>
                </tr>
                <tr>
                    <th>제목</th>
                    <td>
                        <v-text-field v-model="inquire.subject" dense outlined hide-details />
                    </td>
                </tr>
                <tr>
                    <th>내용</th>
                    <td>
                        <v-textarea v-model="inquire.content" auto-grow dense outlined hide-details class="pb-8px" />
                        <v-checkbox v-model="inquire.secret" v-bind="{ ...checkbox_primary }" :value="true" hide-details class="d-inline-flex" id="secret"/>
                        <label for="secret" class="d-inline-flex align-center">
                            <v-img max-width="16" src="/images/icon/icon-lock.svg"></v-img>
                            <span class="txt ml-4px">비밀글</span>
                        </label>
                    </td>
                </tr>
                <tr>
                    <th>첨부파일 등록</th>
                    <td>
                        <form-files v-model="inquire.images" />
                    </td>
                </tr>
            </tbody>
        </v-simple-table>

        <!-- <tit-wrap-x-small title="문의 제목"></tit-wrap-x-small> -->
        
        <template #actions>
            <v-row no-gutters justify="center">
                <v-col cols="12" md="auto">
                    <v-btn v-bind="{ ...btn_primary }" class="v-size--xx-large w-100 min-w-sm-200px" @click="submit">문의하기</v-btn>
                </v-col>
            </v-row>
        </template>
    </u-dialog>
</template>

<script>
import api from "@/api";
import { checkForBannedKeywords } from '@/plugins/vue-filter-contents';
import { btn_primary, checkbox_primary, INQUIRY_TYPES } from "@/assets/variables";

import PageSection from "@/components/client/templates/page-section.vue";
import UDialog from "@/components/client/dumb/u-dialog.vue";
import FormFiles from "@/components/client/dumb/form-files.vue";

const types = Object.values(INQUIRY_TYPES);

export default {
    props: {
        product: { type: Object, default: null },
    },
    components: {
        PageSection,
        UDialog,
        FormFiles,
    },
    data: () => ({
        btn_primary,
        checkbox_primary,
        types,

        shows: false,

        inquire: {
            subject: null,
            content: null,
            secret: false,
            images: [],
        },
    }),
    methods: {
        async submit() {
            try {
                if (!this.validates()) return;

                var { inquire } = await api.v1.me.inquires.post({ ...this.inquire, _product: this.product._id });

                let fileArr = [];
                await Promise.all(
                    this.inquire.images.map(async (data)=> {
                        if(data instanceof File) data = (await api.v1.files.post({ path:"inquiries", index: inquire._id}, data))?.file;
                        fileArr.push(data._id);
                    })
                );

                inquire = (await api.v1.me.inquires.put({ _id: inquire._id, _images: fileArr }))?.inquiry;

                alert("문의가 완료되었습니다");
                this.$emit("search");
                this.shows = false;
            } catch(error){
                this.$handleError(error);
            }
        },
        validates(){
            const { inquire } = this;
            if (!this.accessToken) throw new Error("로그인 이후 이용가능합니다");
            if(!inquire.type) throw new Error("문의유형을 선택해주세요");
            if(!inquire.subject) throw new Error("제목을 입력해주세요");
            if(!inquire.content) throw new Error("내용을 입력해주세요");
            
            const bannedKeywords = this.$store.state.setting.bannedKeywords;
            checkForBannedKeywords(inquire.subject, bannedKeywords);
            checkForBannedKeywords(inquire.content, bannedKeywords);

            return true;
        }
    },
    computed: {
        accessToken() {
            return this.$store.state.accessToken;
        },
    },
};
</script>

<style lang="scss" scoped>
.v-data-table {
    border-top: 2px solid var(--v-primary-base);
    tr {
        border-bottom: 1px solid var(--border-color);
        > th {
            border-right: 1px solid var(--border-color);
            text-align: center !important;
        }
    }
}
</style>
