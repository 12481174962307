var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.$vuetify.breakpoint.xlOnly ? _c('div', {
    staticClass: "product-list-filter"
  }, [_c('div', {
    staticClass: "py-20px py-md-30px"
  }, [_c('filter-categories', _vm._b({
    attrs: {
      "code": _vm.categoryCode
    },
    on: {
      "search": function ($event) {
        return _vm.$emit('search');
      }
    }
  }, 'filter-categories', {
    brand: _vm.brand
  }, false)), _c('v-divider', {
    attrs: {
      "dark": ""
    }
  })], 1), _c('div', {
    staticClass: "py-20px py-md-30px"
  }, [_c('filter-prices')], 1)]) : _c('div', [_c('filter-prices')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }