var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-data-table', _vm._b({
    attrs: {
      "item-key": "_id",
      "sort-by": "couponPrice",
      "sort-desc": "",
      "show-select": "",
      "single-select": "",
      "selectable-key": "selectable",
      "hide-default-header": "",
      "hide-default-footer": ""
    },
    on: {
      "click:row": function (item, _ref) {
        var isSelected = _ref.isSelected,
          select = _ref.select;
        return select(!isSelected);
      },
      "input": _vm.emit
    },
    scopedSlots: _vm._u([_vm._l(_vm.headers.filter(function (header) {
      return header.hasOwnProperty('formatter');
    }), function (header, index) {
      return {
        key: `item.${header.value}`,
        fn: function (_ref2) {
          var value = _ref2.value,
            item = _ref2.item;
          return [_c('span', {
            key: index,
            domProps: {
              "innerHTML": _vm._s(header.formatter.bind(item)(value, item))
            }
          })];
        }
      };
    }), {
      key: `item.couponPrice`,
      fn: function (_ref3) {
        var _item$couponPrice, _item$couponPrice$for;
        var item = _ref3.item;
        return [_c('h2', {
          staticClass: "red--text"
        }, [_vm._v(" " + _vm._s(item.couponPrice ? `-${((_item$couponPrice = item.couponPrice) === null || _item$couponPrice === void 0 ? void 0 : (_item$couponPrice$for = _item$couponPrice.format) === null || _item$couponPrice$for === void 0 ? void 0 : _item$couponPrice$for.call(_item$couponPrice)) || item.couponPrice}원` : "-") + " ")])];
      }
    }, {
      key: `item.coupon`,
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_c('item-chip', {
          attrs: {
            "coupon": item
          }
        }), _c('item-name', {
          attrs: {
            "coupon": item
          }
        }), _c('item-price', {
          attrs: {
            "coupon": item
          }
        }), _c('item-desc', {
          staticClass: "font-size-12 grey--text",
          attrs: {
            "coupon": item,
            "mode": "user-coupons"
          }
        }), _c('item-usage', _vm._b({
          attrs: {
            "coupon": item
          }
        }, 'item-usage', {
          order: _vm.order
        }, false))];
      }
    }], null, true),
    model: {
      value: _vm.selected,
      callback: function ($$v) {
        _vm.selected = $$v;
      },
      expression: "selected"
    }
  }, 'v-data-table', {
    headers: _vm.headers,
    items: _vm.items,
    itemClass: _vm.itemClass,
    loading: _vm.loading
  }, false));

}
var staticRenderFns = []

export { render, staticRenderFns }