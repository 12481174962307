var render = function render(){
  var _vm$product, _vm$product2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-sheet', [_vm.isHead ? _c('div', [_c('product-view-content-detail', _vm._b({}, 'product-view-content-detail', {
    type: _vm.type,
    _category: _vm._parentCategory
  }, false)), _c('product-view-content-detail', _vm._b({}, 'product-view-content-detail', {
    type: _vm.type,
    _category: _vm._parentCategory,
    _seller: (_vm$product = _vm.product) === null || _vm$product === void 0 ? void 0 : _vm$product._seller
  }, false))], 1) : _c('div', [_c('product-view-content-detail', _vm._b({}, 'product-view-content-detail', {
    type: _vm.type,
    _category: _vm._parentCategory,
    _seller: (_vm$product2 = _vm.product) === null || _vm$product2 === void 0 ? void 0 : _vm$product2._seller
  }, false)), _c('product-view-content-detail', _vm._b({}, 'product-view-content-detail', {
    type: _vm.type,
    _category: _vm._parentCategory
  }, false))], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }