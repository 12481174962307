var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-sheet', {
    staticClass: "product-details__content"
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--xs"
  }, [_c('h3', {
    staticClass: "tit tit--xxs"
  }, [_vm._v("출고점 정보")])]), _c('v-simple-table', {
    staticClass: "v-data-table--no-hover",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function () {
        return [_c('tbody', [_vm._l(_vm.items, function (item) {
          return [_c('tr', {
            key: item.th
          }, [_c('th', [_vm._v(_vm._s(item.th))]), _c('td', [_c('span', {
            staticClass: "primary--text"
          }, [_vm._v(_vm._s(item.td))])])])];
        })], 2)];
      },
      proxy: true
    }])
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }