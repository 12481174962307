var render = function render(){
  var _vm$value, _vm$value2, _vm$value2$user, _vm$value2$user$usern, _vm$value2$user$usern2, _vm$value2$user$usern3, _vm$value2$user$usern4, _vm$value3, _vm$value3$user, _vm$value3$user$usern, _vm$value4, _vm$value4$createdAt, _vm$value4$createdAt$, _vm$value5, _vm$value6, _vm$value7, _vm$value9, _vm$value10, _vm$value11, _vm$value11$_images, _vm$value12, _vm$value13, _vm$value13$repliedAt, _vm$value13$repliedAt2, _vm$value14;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "border-b py-20px py-md-32px"
  }, [_c('div', {
    staticClass: "pb-16px pb-md-20px"
  }, [_c('v-row', {
    staticClass: "row--sm"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": ""
    }
  }, [_c('v-row', {
    staticClass: "row--xxs",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-rating', {
    attrs: {
      "half-increments": "",
      "readonly": "",
      "size": "18",
      "color": "grey darken-4",
      "background-color": "grey darken-4",
      "value": ((_vm$value = _vm.value) === null || _vm$value === void 0 ? void 0 : _vm$value.star) || 0
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "txt txt--dark font-weight-medium"
  }, [_c('span', [_vm._v(_vm._s(((_vm$value2 = _vm.value) === null || _vm$value2 === void 0 ? void 0 : (_vm$value2$user = _vm$value2.user) === null || _vm$value2$user === void 0 ? void 0 : (_vm$value2$user$usern = _vm$value2$user.username) === null || _vm$value2$user$usern === void 0 ? void 0 : (_vm$value2$user$usern2 = _vm$value2$user$usern.slice) === null || _vm$value2$user$usern2 === void 0 ? void 0 : (_vm$value2$user$usern3 = _vm$value2$user$usern2.call(_vm$value2$user$usern, 0, 3)) === null || _vm$value2$user$usern3 === void 0 ? void 0 : (_vm$value2$user$usern4 = _vm$value2$user$usern3.padEnd) === null || _vm$value2$user$usern4 === void 0 ? void 0 : _vm$value2$user$usern4.call(_vm$value2$user$usern3, (_vm$value3 = _vm.value) === null || _vm$value3 === void 0 ? void 0 : (_vm$value3$user = _vm$value3.user) === null || _vm$value3$user === void 0 ? void 0 : (_vm$value3$user$usern = _vm$value3$user.username) === null || _vm$value3$user$usern === void 0 ? void 0 : _vm$value3$user$usern.length, "*")) || "탈퇴회원"))])])]), _c('v-spacer'), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', {
    staticClass: "txt txt--xs"
  }, [_vm._v(" " + _vm._s(((_vm$value4 = _vm.value) === null || _vm$value4 === void 0 ? void 0 : (_vm$value4$createdAt = _vm$value4.createdAt) === null || _vm$value4$createdAt === void 0 ? void 0 : (_vm$value4$createdAt$ = _vm$value4$createdAt.toDateTime) === null || _vm$value4$createdAt$ === void 0 ? void 0 : _vm$value4$createdAt$.call(_vm$value4$createdAt)) || "-") + " ")])])], 1)], 1)], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": ""
    }
  }, [_c('div', {
    staticClass: "txt txt--xs pb-4px"
  }, [_vm._v(_vm._s(((_vm$value5 = _vm.value) === null || _vm$value5 === void 0 ? void 0 : _vm$value5.productName) || "-") + " / " + _vm._s(_vm.$decode__productOptionName((_vm$value6 = _vm.value) === null || _vm$value6 === void 0 ? void 0 : _vm$value6.purchaseName) || "-"))]), _c('div', {
    staticClass: "txt"
  }, [_vm._v(" " + _vm._s(((_vm$value7 = _vm.value) === null || _vm$value7 === void 0 ? void 0 : _vm$value7.content) || "-") + " ")])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "auto"
    }
  }, [_c('review-images-dialog', _vm._b({
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var _vm$value8, _vm$value8$images;
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_c('v-card', _vm._g(_vm._b({
          staticClass: "w-sm-60px ml-auto overflow-hidden",
          attrs: {
            "rounded": "md"
          }
        }, 'v-card', attrs, false), on), [_c('v-img', _vm._b({
          attrs: {
            "cover": "",
            "aspect-ratio": 1 / 1
          }
        }, 'v-img', (_vm$value8 = _vm.value) === null || _vm$value8 === void 0 ? void 0 : (_vm$value8$images = _vm$value8.images) === null || _vm$value8$images === void 0 ? void 0 : _vm$value8$images[0], false))], 1)];
      }
    }])
  }, 'review-images-dialog', {
    _product: (_vm$value9 = _vm.value) === null || _vm$value9 === void 0 ? void 0 : _vm$value9._product,
    _review: (_vm$value10 = _vm.value) === null || _vm$value10 === void 0 ? void 0 : _vm$value10._id,
    _image: (_vm$value11 = _vm.value) === null || _vm$value11 === void 0 ? void 0 : (_vm$value11$_images = _vm$value11._images) === null || _vm$value11$_images === void 0 ? void 0 : _vm$value11$_images[0]
  }, false))], 1)], 1), (_vm$value12 = _vm.value) !== null && _vm$value12 !== void 0 && _vm$value12.reply ? _c('div', {
    staticClass: "pt-20px pt-md-32px"
  }, [_c('div', {
    staticClass: "pb-8px pb-md-12px"
  }, [_c('v-row', {
    staticClass: "row--sm"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": ""
    }
  }, [_c('v-row', {
    staticClass: "row--xs",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-card', {
    staticClass: "w-40px",
    attrs: {
      "rounded": "circle",
      "color": "#d9d9d9"
    }
  }, [_c('v-img', {
    attrs: {
      "aspect-ratio": 1 / 1
    }
  }, [_c('div', {
    staticClass: "d-flex align-center justify-center h-100"
  }, [_c('span', {
    staticClass: "material-icons font-size-20 font-size-md-24 white--text"
  }, [_vm._v("person")])])])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "txt txt--xs txt--dark font-weight-medium"
  }, [_c('span', [_vm._v("판매자")])])])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('div', {
    staticClass: "d-flex align-center"
  }, [_c('span', {
    staticClass: "txt txt--xs txt--light"
  }, [_vm._v(_vm._s((_vm$value13 = _vm.value) === null || _vm$value13 === void 0 ? void 0 : (_vm$value13$repliedAt = _vm$value13.repliedAt) === null || _vm$value13$repliedAt === void 0 ? void 0 : (_vm$value13$repliedAt2 = _vm$value13$repliedAt.toDateTime) === null || _vm$value13$repliedAt2 === void 0 ? void 0 : _vm$value13$repliedAt2.call(_vm$value13$repliedAt)))]), _c('span', {
    staticClass: "txt txt--xs txt--light px-4px"
  }, [_vm._v("·")]), _c('v-btn', {
    staticClass: "v-btn--text-fit",
    attrs: {
      "small": "",
      "text": "",
      "color": "grey lighten-1"
    }
  }, [_vm._v("신고")])], 1)])], 1)], 1), _c('v-card', {
    staticClass: "pa-12px grey lighten-5",
    attrs: {
      "rounded": "md"
    }
  }, [_c('div', {
    staticClass: "txt txt--xs"
  }, [_vm._v(" " + _vm._s(((_vm$value14 = _vm.value) === null || _vm$value14 === void 0 ? void 0 : _vm$value14.reply) || "-") + " ")])])], 1) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }