<template>
    <v-sheet color="transparent" class="text-center d-flex justify-space-between justify-md-center">
        <div class="d-md-none grey--text text--darken-4 font-weight-medium text-left mb-4px">상품금액(할인포함)</div>
        <v-row no-gutters align="center" justify="end">
            <v-col cols="auto" md="12" order-md="2">
                <strong class="d-inline-flex align-center font-size-18 font-size-md-20 grey--text text--darken-4"> {{ totalPrice.format() }}<small class="font-size-14 font-weight-regular pl-2px">원</small> </strong>
            </v-col>
            <v-col v-if="productPrice != totalPrice" cols="auto" md="12" order-md="1">
                <span class="d-md-block font-size-14 text-decoration-line-through grey--text text--lighten-1 pl-8px pl-md-0">{{ productPrice.format() }}원</span>
            </v-col>
        </v-row>
    </v-sheet>
</template>

<script>
import { mapGetters } from "vuex";

import { initOrder } from "@/assets/variables/inits";

export default {
    props: {
        value: { type: Object, default: initOrder },
        productGroup: { type: Object, default: () => ({}) },
    },
    computed: {
        ...mapGetters(["userLevel"]),
        productPrice() {
            return this.$getProductPrice(this.productGroup.carts);
        },
        discountPrice() {
            return this.$getDiscountPrice(this.productGroup.carts);
        },
        couponPrice() {
            const coupons = this.productGroup.coupons;
            return this.$getCouponPrice(this.productGroup.carts, coupons, this.value?.receiver?.geolocation, this.userLevel);
        },
        levelDiscountPrice() {
            return this.$getLevelDiscountPrice(this.productGroup.carts, this.userLevel);
        },
        totalDiscountPrice() {
            return this.discountPrice + this.couponPrice + this.levelDiscountPrice;
        },
        totalPrice() {
            return this.productPrice - this.totalDiscountPrice;
        },
    },
};
</script>

<style></style>
