var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('tit-wrap-line', {
    attrs: {
      "noDivider": "",
      "title": "주문서",
      "titleClass": "tit--xs"
    }
  }), _c('vertical-form-table', _vm._b({
    scopedSlots: _vm._u([{
      key: "주문고객",
      fn: function () {
        var _vm$value, _vm$value$sender, _vm$value2, _vm$value2$sender, _vm$value3, _vm$value3$sender;
        return [_c('div', {
          staticClass: "divider-group divider-group--lg"
        }, [_c('ul', [_c('li', [_vm._v(_vm._s((_vm$value = _vm.value) === null || _vm$value === void 0 ? void 0 : (_vm$value$sender = _vm$value.sender) === null || _vm$value$sender === void 0 ? void 0 : _vm$value$sender.name))]), _c('li', [_vm._v(_vm._s((_vm$value2 = _vm.value) === null || _vm$value2 === void 0 ? void 0 : (_vm$value2$sender = _vm$value2.sender) === null || _vm$value2$sender === void 0 ? void 0 : _vm$value2$sender.phone))]), _c('li', [_vm._v(_vm._s((_vm$value3 = _vm.value) === null || _vm$value3 === void 0 ? void 0 : (_vm$value3$sender = _vm$value3.sender) === null || _vm$value3$sender === void 0 ? void 0 : _vm$value3$sender.email))])])])];
      },
      proxy: true
    }, {
      key: "받는분",
      fn: function () {
        var _vm$value4, _vm$value4$receiver;
        return [_vm._v(" " + _vm._s((_vm$value4 = _vm.value) === null || _vm$value4 === void 0 ? void 0 : (_vm$value4$receiver = _vm$value4.receiver) === null || _vm$value4$receiver === void 0 ? void 0 : _vm$value4$receiver.name) + " ")];
      },
      proxy: true
    }, {
      key: "주소",
      fn: function () {
        var _vm$value5, _vm$value5$receiver, _vm$value6, _vm$value6$receiver;
        return [_c('p', [_vm._v(_vm._s((_vm$value5 = _vm.value) === null || _vm$value5 === void 0 ? void 0 : (_vm$value5$receiver = _vm$value5.receiver) === null || _vm$value5$receiver === void 0 ? void 0 : _vm$value5$receiver.title))]), _c('p', [_vm._v(_vm._s(_vm.address))]), _c('p', [_vm._v(_vm._s((_vm$value6 = _vm.value) === null || _vm$value6 === void 0 ? void 0 : (_vm$value6$receiver = _vm$value6.receiver) === null || _vm$value6$receiver === void 0 ? void 0 : _vm$value6$receiver.address2))])];
      },
      proxy: true
    }, {
      key: "휴대폰",
      fn: function () {
        var _vm$value7, _vm$value7$receiver;
        return [_c('p', [_vm._v(_vm._s((_vm$value7 = _vm.value) === null || _vm$value7 === void 0 ? void 0 : (_vm$value7$receiver = _vm$value7.receiver) === null || _vm$value7$receiver === void 0 ? void 0 : _vm$value7$receiver.phone))])];
      },
      proxy: true
    }, {
      key: "전화번호",
      fn: function () {
        var _vm$value8, _vm$value8$receiver;
        return [_c('p', [_vm._v(_vm._s((_vm$value8 = _vm.value) === null || _vm$value8 === void 0 ? void 0 : (_vm$value8$receiver = _vm$value8.receiver) === null || _vm$value8$receiver === void 0 ? void 0 : _vm$value8$receiver.tel))])];
      },
      proxy: true
    }])
  }, 'vertical-form-table', {
    items: _vm.items
  }, false))], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }