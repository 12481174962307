<template>
    <v-card tile outlined>
        <div class="pa-18px px-md-40px py-md-32px">
            <v-row align="center">
                <v-col cols="12">
                    <div class="tit-wrap">
                        <div class="tit font-weight-light">결제정보</div>
                    </div>
                    <v-row align="center" justify="center" class="row--sm txt font-weight-light">
                        <v-col cols="12">
                            <v-row align="center" justify="space-between" class="row--xxs">
                                <v-col cols="auto">
                                    <span>총 주문금액</span>
                                </v-col>
                                <v-col cols="auto">
                                    <span> {{ totalProductPrice.format() }}원</span>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="12">
                            <v-row align="center" justify="space-between" class="row--xxs">
                                <v-col cols="auto">
                                    <span class="txt font-weight-light">+ 총 배송비</span>
                                </v-col>
                                <v-col cols="auto">
                                    <span>{{ totalDeliveryPrice.format() }}원</span>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="12">
                            <v-row align="center" justify="space-between" class="row--xxs">
                                <v-col cols="auto">
                                    <span class="txt font-weight-light">- 총 할인금액</span>
                                </v-col>
                                <v-col cols="auto">
                                    <span>{{ totalDiscountPrice.format() }}원</span>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="12" v-if="value.pointPrice">
                            <v-row align="center" justify="space-between" class="row--xxs">
                                <v-col cols="auto">
                                    <span class="txt font-weight-light">마일리지 사용</span>
                                </v-col>
                                <v-col cols="auto">
                                    <span>{{ totalPointPrice.format() }}원</span>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="12">
                            <v-divider />
                        </v-col>
                        <v-col cols="12">
                            <v-row align="center" class="row--xxs">
                                <v-col cols="12" class="text-end">
                                    <span>총 결제 금액</span>
                                </v-col>
                                <v-col cols="12" class="text-end">
                                    <div class="tit font-weight-light">
                                        <span class="red--text">{{ totalPrice.format() }}</span
                                        >원
                                    </div>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </div>
    </v-card>
</template>

<script>
import { initOrder } from "@/assets/variables";
export default {
    props: {
        value: { type: Object, default: initOrder },
    },
    computed: {
        totalProductPrice() {
            return this.value?.productPrice || 0;
        },
        totalDeliveryPrice() {
            let { deliveryPrice = 0, servicePrice = 0, islandPrice = 0 } = this.value;
            return deliveryPrice + servicePrice + islandPrice || 0;
        },
        totalDiscountPrice() {
            let { discountPrice = 0, levelDiscountPrice = 0, couponPrice = 0 } = this.value;
            return discountPrice + levelDiscountPrice + couponPrice || 0;
        },
        totalPointPrice() {
            return this.value.pointPrice || 0;
        },
        totalPrice() {
            return this.totalProductPrice + this.totalDeliveryPrice - this.totalDiscountPrice - this.totalPointPrice || 0;
        },
    },
};
</script>

<style>
</style>