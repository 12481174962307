var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('terms-of-agreements-short', _vm._b({
    on: {
      "isValid": function (isValid) {
        return _vm.$emit('isValid', isValid);
      },
      "input": function (value) {
        return _vm.$emit('input', value);
      }
    }
  }, 'terms-of-agreements-short', {
    params: {
      code: {
        $in: ['order']
      }
    }
  }, false))], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }