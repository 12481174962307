<template>
    <tbody>
        <template v-for="(shippingGroup, i) in sellerGroup.shippingGroups">
            <template v-for="(productGroup, j) in shippingGroup.productGroups">
                <body-item
                    v-model="order"
                    v-bind="{
                        seller: sellerGroup.seller,
                        // seller: i == 0 && j == 0 ? sellerGroup.seller : null,
                        shippingGroup: j == 0 ? shippingGroup : null,
                        productGroup,

                        // col2Props: j == 0 ? col2Props : null,

                        col5Props: { rowspan: i == 0 ? shippingGroup.productGroups.length : null },
                    }"
                    @input="emit"
                    :key="productGroup._id"
                    ref="body-item"
                />
            </template>
        </template>
    </tbody>
</template>

<script>
import { initOrder } from "@/assets/variables";

import BodyItem from "./body/body-item.vue";

export default {
    components: {
        BodyItem,
    },
    props: {
        value: { type: Object, default: initOrder },
        sellerGroup: { type: Object, default: () => ({}) },

        loading: { type: Boolean, default: false },
    },
    data: () => ({
        order: initOrder(),
    }),
    computed: {
        col2Props() {
            return {
                rowspan: this.sellerGroup.shippingGroups.reduce((sum, shippingGroup) => sum + shippingGroup.productGroups.length, 0),
            };
        },
    },
    methods: {
        async init() {
            await Promise.all([this.$refs["body-item"] || []].flat().map(async (child) => await child.init()));
        },
        async sync() {
            this.order = initOrder(this.value);
        },
        async emit() {
            this.order = initOrder(this.order);
            this.$emit("input", this.order);
        },
    },
    watch: {
        value() {
            this.sync();
        },
    },
    mounted() {
        this.sync();
    },
};
</script>

<style>
</style>