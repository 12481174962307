<template>
    <client-page>
        <template #subHead>
            <sub-visual-primary :tit="tit" bg="/images/cart/visual.jpg">
                <template #path>
                    <v-breadcrumbs exact :items="breadcrumbsItems">
                        <template #divider>
                            <v-icon small>mdi-chevron-right</v-icon>
                        </template>
                    </v-breadcrumbs>
                </template>
            </sub-visual-primary>
        </template>

        <page-section class="page-section--first page-section--last" containerSize="container--lg">
            <div class="tit-wrap tit-wrap--lg">
                <v-row justify="center" align="center" class="row--xs tit">
                    <v-col cols="auto" class="font-weight-light">장바구니</v-col>
                    <v-col cols="auto">
                        <v-card width="26" height="26" color="grey" outlined class="d-flex justify-center align-center">
                            <v-icon small class="grey--text">mdi-chevron-right</v-icon>
                        </v-card>
                    </v-col>
                    <v-col cols="auto" class="font-weight-light">주문서작성</v-col>
                    <v-col cols="auto">
                        <v-card width="26" height="26" color="grey" outlined class="d-flex justify-center align-center">
                            <v-icon small class="grey--text">mdi-chevron-right</v-icon>
                        </v-card>
                    </v-col>
                    <v-col cols="auto" class="font-weight-light">결제완료</v-col>
                </v-row>
            </div>
            <slot />
        </page-section>
    </client-page>
</template>

<script>
import ClientPage from "@/components/client/templates/client-page.vue";
import PageSection from "@/components/client/templates/page-section.vue";

import SubVisualPrimary from "@/components/client/sub/sub-visual-primary.vue";

export default {
    components: {
        ClientPage,
        PageSection,
        SubVisualPrimary,
    },
    props: {
        tit: { type: String, default: "" }
    },
    computed: {
        breadcrumbsItems() {
            const items = [
                { text: "HOME", to: { path: "/" } },
                { text: "장바구니", to: { path: "/cart" } },
            ];
            return items.map((item) => ({ ...item, disabled: false }));
        },
    },
};
</script>