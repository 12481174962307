var render = function render(){
  var _vm$product;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "product-intro__thumb"
  }, [_c('v-sheet', [_c('div', {
    staticClass: "pt-16px pt-sm-24px"
  }, [_vm._l((_vm$product = _vm.product) === null || _vm$product === void 0 ? void 0 : _vm$product.images, function (image, index) {
    return [image ? _c('v-img', {
      key: index,
      staticClass: "product-image",
      attrs: {
        "tile": "",
        "flat": "",
        "src": image === null || image === void 0 ? void 0 : image.url
      }
    }) : _vm._e()];
  })], 2)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }