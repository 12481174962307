var render = function render(){
  var _vm$seller;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    staticClass: "row--xxs",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "txt txt--sm txt--dark font-weight-bold"
  }, [_vm._v("출고점")])]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('router-link', {
    staticClass: "d-inline-flex align-center txt txt--sm grey--text",
    attrs: {
      "to": {
        path: `/minishop/${(_vm$seller = _vm.seller) === null || _vm$seller === void 0 ? void 0 : _vm$seller._id}`
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.seller.nickname) + " "), _c('v-img', {
    staticClass: "ml-4px",
    attrs: {
      "width": "16",
      "height": "16",
      "src": "/images/icon/icon-home-grey.svg"
    }
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }