<template>
    <tbody>
        <template v-for="(shippingGroup, i) in merchantGroup.shippingGroups">
            <template v-for="(purchaseGroup, j) in shippingGroup.purchaseGroups">
                <result-item 
                    v-bind="{
                        shippingGroup,
                        purchaseGroup,
                    }"
                    :key="`${i}-${j}`"
                />
            </template>
        </template>
    </tbody>
</template>

<script>
import { initOrder } from '@/assets/variables';
import ResultItem from "./result-item.vue";
export default {
    components: {
        ResultItem,
    },
    props: {
        value: { type: Object, default: initOrder },
        merchantGroup: { type: Object, default: () => ({}) },

        loading: { type: Boolean, default: false },
    },
};
</script>

<style>
</style>