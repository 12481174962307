<template>
    <carts-row v-bind="{ col2Props, col5Props }">
        <template #col1>
            <item-product v-bind="productGroup" @input="emit" />
        </template>
        <template #col2 v-if="!!seller">
            <v-sheet class="d-flex justify-space-between justify-md-center">
                <div class="d-md-none grey--text text--darken-4 font-weight-medium text-left mb-4px">판매사</div>
                <div class="text-center">{{ seller?.nickname }}</div>
            </v-sheet>
        </template>
        <template #col3>
            <item-price v-bind="{ value, productGroup }" />
        </template>
        <template #col4>
            <item-discount v-model="order" v-bind="{ productGroup }" @input="emit" ref="item-discount" />
        </template>
        <template #col5 v-if="!!shippingGroup">
            <item-shipping v-model="order" v-bind="{ shippingGroup }" @input="emit" ref="item-shipping" />
        </template>
    </carts-row>
</template>

<script>
import { initOrder } from "@/assets/variables";

import CartsRow from "../carts-row.vue";
import ItemDiscount from "./item/item-discount.vue";
import ItemPrice from "./item/item-price.vue";
import ItemProduct from "./item/item-product.vue";
import ItemShipping from "./item/item-shipping.vue";

export default {
    components: {
        CartsRow,
        ItemDiscount,
        ItemPrice,
        ItemProduct,
        ItemShipping,
    },
    props: {
        value: { type: Object, default: initOrder },

        seller: { type: Object, default: null },
        shippingGroup: { type: Object },
        productGroup: { type: Object },

        col2Props: { type: Object, default: () => ({}) },
        col5Props: { type: Object, default: () => ({}) },

        loading: { type: Boolean, default: false },
    },
    data: () => ({
        order: initOrder(),
    }),
    methods: {
        async init() {
            await this.$refs["item-shipping"]?.init?.();
            await Promise.all([this.$refs["item-discount"] || []].flat().map(async (child) => await child.init()));
        },
        async sync() {
            this.order = initOrder(this.value);
        },
        async emit() {
            this.order = initOrder(this.order);
            this.$emit("input", this.order);
        },
    },
    watch: {
        value() {
            this.sync();
        },
    },
    mounted() {
        this.sync();
    },
};
</script>

<style>
</style>