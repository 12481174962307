var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('dialog-basic', {
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_vm._t("activator", null, null, {
          attrs,
          on
        })];
      }
    }], null, true)
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--sm"
  }, [_c('v-card-title', {
    staticClass: "tit tit--xxs pa-0"
  }, [_vm._v("AS 협력점 정보확인")])], 1), _c('v-simple-table', {
    staticClass: "v-data-table--no-hover",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function () {
        return [_c('tbody', [_vm._l(_vm.items, function (item) {
          return [_c('tr', {
            key: item.th
          }, [_c('th', [_vm._v(_vm._s(item.th))]), _c('td', [_vm._v(_vm._s(item.td))])])];
        })], 2)];
      },
      proxy: true
    }])
  }), _c('div', {
    staticClass: "txt txt--sm txt--dark mt-16px mt-md-18px mt-lg-20px"
  }, [_c('p', {
    staticClass: "font-weight-bold secondary--text"
  }, [_vm._v("AS 협력점(전화번호, 이메일, 주소, 등)은 해당 AS 협력점의 명시적 동의 없이영리 목적인 마케팅·광고 등 용도로 활용할 수 없습니니다.")]), _c('p', [_vm._v("판매자의 명시적 동의 없이 판매자의 정보를수집 및 활용하여 영리 목적인 마케팅·광고 등 용도로 활용하는 경우 정보통신망법 등 관련 법령에 의거하여 과태료 부과 및 형사처럴의 대상이 될 수 있습니다.")])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }