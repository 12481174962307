var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('carts-row', {
    staticClass: "border-b",
    attrs: {
      "colTag": "th",
      "colProps": {
        class: 'py-3'
      }
    },
    scopedSlots: _vm._u([{
      key: "col1",
      fn: function () {
        return [_vm._v(" 상품정보 ")];
      },
      proxy: true
    }, {
      key: "col2",
      fn: function () {
        return [_vm._v(" 판매사 ")];
      },
      proxy: true
    }, {
      key: "col3",
      fn: function () {
        return [_vm._v(" 판매금액 ")];
      },
      proxy: true
    }, {
      key: "col4",
      fn: function () {
        return [_vm._v(" 쿠폰적용가 ")];
      },
      proxy: true
    }, {
      key: "col5",
      fn: function () {
        return [_vm._v(" 배송정보 ")];
      },
      proxy: true
    }])
  });

}
var staticRenderFns = []

export { render, staticRenderFns }