<template>
    <v-row align="center" class="row--xs">
        <v-col cols="3" md="2"><div class="txt txt--sm txt--dark">상품 쿠폰</div></v-col>
        <v-col cols="9" md="10">
            <cart-item-coupons-products v-model="coupons" v-bind="{ order: value, carts, product }" v-on="{ pullCoupon }" @input="emit" ref="cart-item-coupons">
                <template #activator="{ attrs, on, selected }">
                    <v-btn v-bind="{ ...btn_secondary, ...attrs }" block v-on="on">
                        {{ attrs?.disabled ? "사용 가능한 쿠폰이 없습니다." : selected.length ? selected.map(({ name }) => name).join(", ") : "선택안함" }}
                    </v-btn>
                </template>
            </cart-item-coupons-products>
        </v-col>
    </v-row>
</template>

<script>
import { mapGetters } from 'vuex';
import { initOrder, btn_secondary, USAGE_TARGET_PRICES } from "@/assets/variables";
import CartItemCouponsProducts from "../carts/cart-item-coupons-products.vue";

export default {
    components: {
        CartItemCouponsProducts,
    },
    props: {
        value: { type: Object, default: initOrder },
        productGroup: { type: Object, default: () => ({}) },
    },
    data: () => ({
        btn_secondary,
        loading: false,

        coupons: []
    }),
    computed: {
        ...mapGetters(["userLevel"]),

        carts() {
            return this.productGroup.carts;
        },
        product() {
            return this.productGroup.product;
        },
        discountPrice() {
            return this.$getDiscountPrice(this.productGroup.carts);
        },
        couponPrice() {
            return this.$getCouponPrice(this.productGroup.carts, this.coupons, this.value?.receiver?.geolocation, this.userLevel);
        },
        levelDiscountPrice() {
            return this.$getLevelDiscountPrice(this.productGroup.carts, this.userLevel);
        },
        totalDiscountPrice() {
            return this.discountPrice + this.couponPrice + this.levelDiscountPrice;
        },
    },
    methods: {
        async init() {
            await this.$refs["cart-item-coupons"]?.init?.();
        },
        sync() {
            this.coupons = this.value.coupons.filter((coupon) => !["_product"].some((key) => coupon[key] != this.productGroup[key]) && coupon?.usage?.target?.price == USAGE_TARGET_PRICES.SHOP_PRODUCT_PRICE.value);
        },
        emit() {
            let [...coupons] = this.coupons;
            coupons = coupons.concat(this.value.coupons.filter((coupon) => ["_product"].some((key) => coupon[key] != this.productGroup[key]) || coupon?.usage?.target?.price != USAGE_TARGET_PRICES.SHOP_PRODUCT_PRICE.value));

            this.$emit("input", initOrder({ ...this.value, coupons }));
        },
        pullCoupon(coupon){
            this.$emit('pullCoupon', coupon);
        }
    },
    watch: {
        value() {
            this.sync();
        },
    },
    mounted() {
        this.sync();
    },
};
</script>

<style>
</style>