var render = function render(){
  var _this$point, _this$point$usage;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return (_this$point = this.point) !== null && _this$point !== void 0 && (_this$point$usage = _this$point.usage) !== null && _this$point$usage !== void 0 && _this$point$usage.isActive ? _c('span', {
    staticClass: "d-inline-flex flex-wrap align-center"
  }, [_vm._v(" " + _vm._s(_vm.pointText) + "시 마일리지 최대 " + _vm._s(_vm.pointAmount) + " ")]) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }