<template>
    <div>
        <product-item v-bind="{ product }" hideDivider>
            <template #content>
                <v-row align="center" class="ma-n6px ma-lg-n12px">
                    <!-- <template v-for="(cart, index) in carts"> -->
                        <v-col class="pa-6px pa-lg-12px">
                            <div class="txt txt--sm" v-for="(cart, index) in carts" :key="index">
                                {{ $decode__productOptionName(cart?.name) }} / 수량 : {{ cart?.amount }}개
                                <!-- <template v-if="!withQuantity"> / 수량 : {{ cart?.amount }}개 </template> -->
                            </div>
                            <div class="txt txt--dark">{{ totalPrice.format() }}원</div>
                        </v-col>
                    <!-- </template> -->
                </v-row>
            </template>
        </product-item>
        <v-row class="ma-n4px">
            <v-col cols="12" class="pa-4px">
                <order-coupons-item-discount v-model="order" v-bind="{ productGroup }" v-on="{ pullCoupon }" @input="emit" ref="item-discount"/>
            </v-col>
            <v-col cols="12" class="pa-4px">
                <order-coupons-item-shipping v-model="order" v-bind="{ shippingGroup }" v-on="{ pullCoupon }" @input="emit" ref="item-shipping"/>
            </v-col>
        </v-row>
        <v-divider class="mt-16px mt-md-20px" />
    </div>
</template>

<script>
import { initOrder, btn_secondary } from "@/assets/variables";
import ProductItem from "@/components/client/mypage/product/product-item.vue";
import OrderCouponsItemDiscount from "./order-coupons-item-discount.vue";
import OrderCouponsItemShipping from "./order-coupons-item-shipping.vue";

export default {
    components: {
        ProductItem,
        OrderCouponsItemDiscount,
        OrderCouponsItemShipping,
    },
    props: {
        value: { type: Object, default: initOrder },

        seller: { type: Object, default: null },
        shippingGroup: { type: Object },
        productGroup: { type: Object },
    },
    data: () => ({
        btn_secondary,
        loading: false,

        order: initOrder(),
    }),
    computed: {
        product() {
            return this.productGroup?.product;
        },
        carts() {
            return this.productGroup?.carts;
        },
        totalPrice() {
            return this.carts?.reduce((acc, cart) => acc + cart?.salePrice * cart?.amount, 0);
        }
    },
    methods: {
        async init() {
            await this.$refs["item-shipping"]?.init?.();
            await this.$refs["item-discount"]?.init?.();
            // await Promise.all([this.$refs["item-discount"] || []].flat().map(async (child) => await child.init()));
        },
        async sync() {
            this.order = initOrder(this.value);
        },
        async emit() {
            this.order = initOrder(this.order);
            this.$emit("input", this.order);
        },
        pullCoupon(coupon){
            this.$emit('pullCoupon', coupon);
        }
    },
    watch: {
        value() {
            this.sync();
        },
    },
    mounted() {
        this.sync();
    },
};
</script>

<style>
</style>