<template>
    <!-- S: 카테고리 사이드바 -->
    <div :class="`category-sidebar${shows ? '' : ' sidebar-hide'}`">
        <div class="category-sidebar__inner">
            <!-- 카테고리 네비게이션 -->
            <nav class="product-category">
                <!-- <button :aria-pressed="JSON.stringify(shows)" class="toggle" @click="shows = !shows"><span class="blind">카테고리 사이드바 토글</span></button> -->
                <div class="product-category__inner">
                    <div class="mb-10px">
                        <h3 class="tit tit--xs">카테고리</h3>
                    </div>

                    <v-divider class="grey darken-4 mb-14px" style="border-width: 3px 0 0" />

                    <v-list flat class="product-category-list">
                        <!-- <v-list-item @click="setCategory()">
                            <v-list-item-title :class="{ 'primary--text': !$route.query.category }">전체보기</v-list-item-title>
                        </v-list-item> -->

                        <template v-for="item in items">
                            <!-- sub list 있음 -->
                            <v-list-group v-if="filter(item.children).length" :key="item._id" :value="_selecteds.includes(item._id)" active-class="product-category-list--active">
                                <template #activator>
                                    <v-list-item-title>
                                        {{ item.name }}
                                    </v-list-item-title>
                                </template>
                                <v-list-item :class="{ 'v-list-item--exact-active': $route.query.category == item.code }" @click="setCategory(item)">
                                    <v-list-item-title>전체보기</v-list-item-title>
                                </v-list-item>

                                <filter-categories-expander v-for="child in filter(item.children)" :key="child._id" v-bind="{ _selecteds, category: child }" v-on="{ setCategory }">
                                    <filter-categories-expander v-for="grandChild in filter(child.children)" :key="grandChild._id" v-bind="{ _selecteds, category: grandChild }" v-on="{ setCategory }">
                                        <filter-categories-expander v-for="granderChild in filter(grandChild.children)" :key="granderChild._id" v-bind="{ _selecteds, category: granderChild }" v-on="{ setCategory }" />
                                    </filter-categories-expander>
                                </filter-categories-expander>
                            </v-list-group>

                            <!-- sub list 없음 -->
                            <v-list-item v-else :value="$route.query.category == item.code" @click="setCategory(item)" :key="item._id">
                                <v-list-item-title :class="{ 'primary--text': $route.query.category == item.code }">
                                    {{ item.name }}
                                </v-list-item-title>
                            </v-list-item>
                        </template>
                    </v-list>
                </div>
            </nav>
        </div>
    </div>
    <!-- E: 카테고리 사이드바 -->
</template>

<script>
import FilterCategoriesExpander from "./filter-categories-expander.vue";

import { mapState } from "vuex";

export default {
    components: {
        FilterCategoriesExpander,
    },
    props: {
        brand: { type: Object, default: null },
        code: { type: String },
    },
    data: () => ({
        category: undefined,

        JSON,
        shows: true,
    }),
    computed: {
        ...mapState(["categories"]),

        categories_children() {
            return this.categories.flatMap(({ children }) => children);
        },
        categories_grandChildren() {
            return this.categories_children.flatMap(({ children }) => children);
        },
        categories_granderChildren() {
            return this.categories_grandChildren.flatMap(({ children }) => children);
        },
        items() {
            return this.filter(this.categories);
        },
        _selecteds() {
            const { depth } = this.category || {};
            switch (depth) {
                case 1:
                    return [this.category?._id];
                case 2:
                    return [this.category?._id, ...this.category._parents];
                case 3:
                    return [this.category?._id, ...this.category._parents, this.categories.filter(({ _children }) => _children.some((_child) => this.category._parents.includes(_child)))];
                case 4:
                    const _categoriesChildren = this.categories_children.filter(({ _children }) => _children.some((_child) => this.category._parents.includes(_child)));
                    return [this.category?._id, ...this.category._parents, ..._categoriesChildren, ...this.categories.filter(({ _children }) => _children.some((_child) => _categoriesChildren.includes(_child)))];
                default:
                    return [this.category?._id];
            }
        },
    },
    mounted() {
        this.sync();
    },
    watch: {
        code() {
            this.sync();
        },
        categories() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.category = this.getCategory(this.categories) ?? this.getCategory(this.categories_children) ?? this.getCategory(this.categories_grandChildren) ?? this.getCategory(this.categories_granderChildren);
        },

        emit() {
            if (this.category?.code == this.code) {
                this.$emit("search");
                return;
            }
            // if (!query.category) delete query.code;

            this.$router.push({ query: { category: this.category?.code } });
        },

        getCategory(categories) {
            return categories.find(({ code }) => code == this.code);
        },

        filter(categories) {
            if (!this.brand) return categories;
            return categories.filter(({ _brands = [] }) => _brands.includes(this.brand?._id));
        },

        setCategory(item) {
            // if (item?.code == this.code) this.category = undefined;
            // else this.category = item;
            this.category = item;

            this.$nextTick(this.emit);
        },
    },
};
</script>

<style lang="scss" scoped>
.product-category__inner {
    transform: translateY(-40px);
}
::v-deep {
    .product-category-list {
        padding: 0;
        color: var(--v-grey-darken4) !important;

        .v-list-group--sub-group .v-list-group__header,
        .v-list-item {
            padding: 0;
        }

        .v-list-item {
            display: flex;
            align-items: center;
            // min-height: 26px;
            color: var(--v-grey-base) !important;
            &,
            &__title {
                font-size: var(--txt-font-size);
            }
            &__title {
                order: 0;
            }
            // item icon
            .v-icon {
                // font-size: 18px;
                color: inherit;
                text-decoration: none;
            }
        }

        // group
        .v-list-group {
            // group icon
            &__header__prepend-icon {
                min-width: auto;
                // .mdi-menu-down::before {
                //     content: "\F0140";
                // }
            }
            &__header__append-icon {
                min-width: auto;
            }

            // group items
            &__items {
                // padding-left: 6px !important;
                .v-list-item {
                    &:not(.v-list-item--active):not(.v-list-item--disabled) {
                        color: var(--v-grey-base) !important;
                    }
                    &--exact-active {
                        color: var(--v-grey-darken4) !important;
                        > .v-list-item__content,
                        .v-list-item__title {
                            text-decoration: underline;
                        }
                        .v-icon {
                            text-decoration: none !important;
                        }
                    }
                }
            }

            // group header title
            &__header {
                .v-list-item__title {
                    font-size: var(--txt-font-size-lg);
                }
            }

            // sub-group
            .v-list-group--sub-group {
                .v-list-item__title {
                    font-size: var(--txt-font-size);
                }
                .v-list-item__icon:first-child {
                    order: 1;
                    margin-right: 0;
                }
            }
        }

        // 첫번째 item
        > .v-list-item,
        > .v-list-group > .v-list-item {
            min-height: 48px;
            font-weight: 700;
            color: var(--v-grey-darken4) !important;
            // &--exact-active {
            //     color: var(--v-primary-base) !important;
            // }
            // &:not(.product-category-list--active) {
            //     color: var(--v-grey-darken4) !important;
            // }
            &,
            .product-category-list {
                &--active {
                    // color: var(--v-primary-base) !important;
                    > .v-list-item__content,
                    .v-list-item__title {
                        text-decoration: none !important;
                    }
                }
            }
        }
        > .v-list-group {
            > .v-list-group__items {
                margin-top: -4px;
                margin-bottom: 4px;
                // 두번째 item
                > .v-list-item,
                > .v-list-group > .v-list-item {
                    &--exact-active,
                    &.product-category-list--active {
                        > .v-list-item__content,
                        .v-list-item__title {
                            font-weight: 700;
                            text-decoration: none;
                            color: var(--v-primary-base) !important;
                        }
                    }
                }
            }
        }

        .product-category-list {
            &--active {
                > .v-list-item__content,
                .v-list-item__title {
                    text-decoration: underline;
                }
                .v-icon {
                    text-decoration: none !important;
                }
            }
        }
    }
}
</style>
