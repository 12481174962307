<template>
    <!-- <vertical-form-table v-model="sender" v-bind="{ items }" @input="emit" /> -->
    <div class="divider-group divider-group--lg">
        <ul>
            <li>{{ sender?.name }}</li>
            <li>{{ sender?.phone }}</li>
            <li>{{ sender?.username }}</li>
        </ul>
    </div>
</template>

<script>
import VerticalFormTable from "@/components/dumb/vertical-form-table.vue";

// const items = [
//     {
//         key: "name",
//         term: "이름",
//         type: "text",
//         required: true,
//     },
//     {
//         key: "phone",
//         term: "연락처",
//         type: "phone",
//         required: true,
//     },
//     {
//         key: "email",
//         term: "이메일",
//         type: "email",
//         required: true,
//     },
// ].map((item) => ({ ...item, outlined: true }));

const initSender = (sender = {}) => ({
    ...sender,
    name: sender?.name || null,
    phone: sender?.phone || null,
    username: sender?.username || null,
    email: sender?.email || null,
});

export default {
    components: { VerticalFormTable },
    props: {
        value: { type: Object, default: initSender },
    },
    data: () => ({
        sender: initSender(),
    }),
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.sender = initSender(this.value);
        },
    },
};
</script>

<style lang="scss" scoped>
.divider-group > ul > li::before {
    width: 2px;
    background-color: silver;
}
</style>
