<template>
    <v-tabs v-model="query['category']" grow @change="emit">
        <tab-primary exact v-for="(item, index) in tabItems" :key="index" :tab-value="item.value">
            {{ item.text }}
        </tab-primary>
    </v-tabs>
</template>

<script>
import { mapState } from "vuex";
import TabPrimary from "@/components/client/tab/tab-pirmary.vue";

const initQuery = (query = {}) => ({
    ...query,

    category: query?.category || null,
});

export default {
    components: {
        TabPrimary,
    },
    data: () => ({
        query: initQuery(),
    }),
    computed: {
        ...mapState(["categories"]),
        tabItems() {
            let items = [
                {
                    text: "전체보기",
                    value: null,
                },
            ];

            const categories = this.categories.map((category) => ({
                text: category.name,
                value: category.code,
            }));

            return [...items, ...categories];
        },
    },
    methods: {
        sync() {
            this.query = initQuery(this.$route.query);
        },
        emit() {
            const { ...query } = this.query;

            delete query.page;
            if (!query.category) delete query.category;

            this.$router.push({ query });
        },
    },
    watch: {
        "$route.query"() {
            this.sync();
        },
    },
    mounted() {
        this.sync();
    },
};
</script>

<style>
</style>