<template>
    <v-sheet>
        <v-simple-table class="v-data-table--default v-data-table--simple-mobile v-data-table--no-hover">
            <result-head />

            <template v-for="(merchantGroup, index) in merchantGroups">
                <result-body
                    v-model="value"
                    v-bind="{
                        merchantGroup,
                    }"
                    :key="index"
                />    
            </template>
        </v-simple-table>
    </v-sheet>
</template>

<script>
import { initOrder } from "@/assets/variables";
import ProductItem from "@/components/client/mypage/product/product-item.vue";
import ResultHead from "./result-head.vue";
import ResultBody from "./result-body.vue";
export default {
    components: {
        ResultHead,
        ResultBody,
        ProductItem,
    },
    props: {
        value: { type: Object, default: initOrder },
    },

    computed: {
        merchantGroups() {
            return this.value?.merchantGroups || [];
        }
    },

};
</script>

<style>
</style>