<template>
    <v-sheet class="product-details__content">
        <div class="tit-wrap tit-wrap--xs">
            <h3 class="tit tit--xxs">출고점의 다른 상품</h3>
        </div>
        <v-divider dark style="border-width: 2px 0 0" class="mb-30px"></v-divider>
        <swiper class="swiper" :options="swiperOptions">
            <!-- <template v-for="(product, index) in products">
                <swiper-slide :key="index" :value="product">
                    <router-link :to="`/shop/products/${value._id}`" class="product-item__inner">
                        <div class="product-item__img" :style="`background-image:url(${value.thumb?.url});`">
                            <div v-if="!value.sells || value.stock == 0" class="product-item__badge">Sold Out</div>
                        </div>
                        <div class="product-item__con">
                            <div class="txt txt--sm mb-4px">{{ value?.seller?.companyName }}</div>
                            <div class="product-item__tit">
                                <span>{{ value.name }} {{ value.code || "" }}</span>
                            </div>
                            <div class="my-6px my-md-8px">
                                <em v-if="!value.sells || value.stock == 0" class="font-size-14 font-size-md-16 grey--text text--lighten-1">품절</em>
                                <div v-else class="d-flex flex-wrap align-center txt font-weight-bold">
                                    <span class="d-flex flex-wrap align-center accent1--text">{{ value.salePrice.format() }}원</span>
                                    <span v-if="value.discountPrice" class="font-size-14 grey--text text--lighten-1 font-weight-regular text-decoration-line-through mx-4px">{{ value.price.format() }}</span>
                                    <span v-if="value.discountRate" class="accent2--text">{{ value.discountRate }}%</span>
                                </div>
                            </div>
                        </div>
                    </router-link>
                </swiper-slide>
            </template> -->

            <!-- 임시 -->
            <template v-for="(product, index) in products">
                <swiper-slide :key="index" :value="product" class="product-item">
                    <router-link :to="`/shop/products/${product._id}`" class="product-item__inner">
                        <!-- <div class="product-item__img" :style="`background-image:url(${product?.thumb?.url});`"></div> -->
                        <v-card class="product-item__img" :img="product?.thumb?.url"/>
                        <div class="product-item__con">
                            <div class="txt txt--sm mb-4px">{{ product.companyName }}</div>
                            <div class="product-item__tit h-auto">{{ product.name }}</div>
                            <div class="my-6px my-md-8px">
                                <div class="d-flex flex-wrap align-center font-weight-bold txt">
                                    <span class="accent1--text">{{ product.salePrice.format() }}원</span>
                                    <span v-if="product.price" class="txt--sm font-weight-regular text-decoration-line-through mx-4px">{{ product.price.format() }}원</span>
                                    <span v-if="product.discountRate" class="accent2--text">{{ product.discountRate }}%</span>
                                </div>
                            </div>
                        </div>
                    </router-link>
                </swiper-slide>
            </template>
            <div class="swiper-control" slot="pagination" @click.prevent>
                <div class="swiper-pagination" />
                <div class="swiper-button-prev">
                    <v-icon>mdi-chevron-left</v-icon>
                </div>
                <div class="swiper-button-next">
                    <v-icon>mdi-chevron-right</v-icon>
                </div>
            </div>
        </swiper>
    </v-sheet>
</template>

<script>
import api from "@/api";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";

export default {
    components: {
        Swiper,
        SwiperSlide,
    },
    props:{
        product: { type: Object, default: () => ({}) }
    },
    data: () => ({
        swiperOptions: {
            allowTouchMove: true,
            slidesPerView: 4,
            spaceBetween: 26,
            speed: 500,
            pagination: {
                el: ".swiper-pagination",
                clickable: true,
            },
            breakpoints: {
                768: {
                    slidesPerView: 3,
                    spaceBetween: 14,
                },
                1024: {
                    slidesPerView: 4,
                    spaceBetween: 26,
                    pagination: false,
                    navigation: {
                        prevEl: ".swiper-button-prev",
                        nextEl: ".swiper-button-next",
                    },
                },
            },
        },
        products: [],
    }),
    methods: {
        async init(){
            this.search();
        },
        async search(){
            try{
                let { products } = await api.v1.shop.products.gets({
                    headers: { limit: 8 },
                    params: { _seller: this.product._seller }
                });

                products = products.filter(({ _id })=>  _id !== this.product._id);
                this.products = products;
            } catch(error){
                this.$handleError(error);
            }
        }
    },
    mounted(){
        this.init();
    }
};
</script>

<style lang="scss" scoped>
.swiper-container {
    position: relative;
    padding-bottom: 24px;
}
::v-deep() {
    .swiper-pagination {
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);

        &-bullet {
            width: 6px;
            height: 6px;
            margin: 0 2px;
            border: 0.5px solid var(--v-primary-base);
            &-active {
                background-color: var(--v-primary-base);
            }
        }
    }
}
.swiper-button-prev,
.swiper-button-next {
    display: none;
}

@media (min-width: 1024px) {
    .swiper-container {
        padding-bottom: 0;
        padding: 0 48px;
        &::before,
        &::after {
            content: "";
            position: absolute;
            top: 0;
            width: 48px;
            height: 100%;
            background: #fff;
            z-index: 2;
        }
        &::before {
            left: 0;
        }
        &::after {
            right: 0;
        }
    }
    .swiper-button-prev,
    .swiper-button-next {
        display: flex;
        width: 32px;
        height: 32px;
        background-color: var(--v-primary-base);
        border-radius: 50%;
        margin: 0;
        transform: translateY(-50%);
        &::after {
            display: none;
        }
        .v-icon {
            color: #fff;
        }
        &.swiper-button-disabled {
            opacity: 1;
            background-color: var(--v-grey-lighten3);
        }
    }
    .swiper-button-prev {
        left: 0;
    }
    .swiper-button-next {
        right: 0;
    }
}
</style>