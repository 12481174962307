var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('section', {
    staticClass: "sub-visual d-none d-lg-flex"
  }, [_c('div', {
    staticClass: "sub-visual__bg d-none d-md-block",
    style: `background-image: url(${_vm.bg})`
  }), _vm.$slots['mobileBg'] ? _c('div', {
    staticClass: "sub-visual__bg d-md-none",
    style: `background-image: url(${_vm.mobileBg})`
  }) : _vm._e(), _c('h2', {
    staticClass: "tit white--text",
    class: _vm.titClass
  }, [_vm._v(_vm._s(_vm.tit))]), _vm.$slots['tab'] ? _c('v-container', {
    staticClass: "container--lg sub-visual__nav"
  }, [_vm._t("tab")], 2) : _vm._e()], 1), _vm.$slots['path'] ? _c('v-container', {
    staticClass: "container--lg"
  }, [_vm._t("path")], 2) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }