<template>
    <result-row colTag="th" class="border-b" :colProps="{ class: 'py-3' }">
        <template #col1> 상품정보 </template>
        <template #col2> 판매금액 </template>
        <template #col3> 판매사 </template>
        <template #col4> 배송정보 </template>
    </result-row>
</template>

<script>
import ResultRow from "./result-row.vue";

export default {
    components: {
        ResultRow,
    },
};
</script>

<style>
</style>