<template>
    <div id="qna" class="product-details__content">
        <div class="product-qna">
            <!-- head -->
            <!-- <div class="pb-20px">
                <v-row align="center" class="row--sm">
                    <v-col cols="auto">
                        <h3 class="tit tit--xxs">상품문의 ({{ summary?.totalCount?.format?.() || 0 }})</h3>
                    </v-col>
                    <v-spacer />
                    <v-col cols="12" sm="auto">
                        <inquire-list-search v-model="filter" v-bind="{ loading }" @input="(page = 1) && search()" />
                    </v-col>
                </v-row>
            </div> -->

            <v-data-table :items="inquires" :headers="headers" dense hide-default-footer disable-filtering disable-sort :expanded.sync="expanded" v-bind="{ loading }" @click:row="clickRow" mobile-breakpoint="768" class="v-data-table--default v-data-table--table-fixed v-data-table--simple-mobile v-data-table--tit v-data-table--cursor-pointer">
                <template #no-data>작성된 문의사항이 없습니다.</template>
                <template #[`item.subject`]="{ item }">
                    <div>
                        <div v-if="!item.secret || item._user == payload?._user" data-idx="5" class="text-truncate">{{ item.subject }}</div>
                        <span v-else><v-icon class="font-size-16 font-size-md-18 grey--text text--lighten-3 pb-2px pr-4px">mdi-lock-outline</v-icon>비밀글입니다</span>
                    </div>
                </template>
                <template #expanded-item="{ headers, item }">
                    <td v-if="!item.secret || item._user == payload?._user" :colspan="headers.length" class="v-data-table__mobile-row__cell">
                        <div class="d-flex">
                            <!-- <span class="d-none d-md-block pr-8px">└</span> -->
                            <div>
                                <div style="white-space: pre-line; word-break: break-all">{{ item.content }}</div>
                                <div v-if="item.images && item.images.filter((image) => image)" class="pt-8px">
                                    <v-row class="row--xs">
                                        <v-col v-for="(image, index) in item.images" :key="index" cols="auto">
                                            <v-img max-width="200" :src="image" />
                                        </v-col>
                                    </v-row>
                                </div>
                            </div>
                        </div>
                        <div v-if="item.reply" class="d-flex font-weight-medium pt-8px">
                            <span class="pr-8px">└</span>
                            <div v-html="item.reply"></div>
                        </div>
                    </td>
                </template>
                <template #[`item.user`]="{ item }">
                    <span v-if="item.user"> {{ item.user.username.substr(0, 1) }}{{ item.user.username.substr(1).replace(/./g, "*") }} </span>
                    <span v-else>탈퇴회원</span>
                </template>
                <template #[`item.createdAt`]="{ item }">
                    <span>{{ item.createdAt.toDate() }}</span>
                </template>
                <template #[`item.actions`]="{ item }">
                    <span v-if="item.reply" class="primary--text font-weight-bold">[답변완료]</span>
                    <span v-else class="grey--text text--lighten-1">[답변대기]</span>
                </template>
            </v-data-table>

            <div class="btn-wrap">
                <v-row clsss="row--xs">
                    <v-spacer />
                    <v-col cols="auto">
                        <inquire-form v-bind="{ product }" v-on="{ search }">
                            <template #activator="{ attrs, on }">
                                <v-btn v-bind="{ ...btn_primary, ...attrs }" v-on="on" :disabled="!accessToken"> 문의하기 </v-btn>
                            </template>
                        </inquire-form>
                    </v-col>
                </v-row>
            </div>

            <div class="pagination-wrap">
                <v-pagination v-model="page" :length="pageCount" :total-visible="11" />
            </div>
        </div>
    </div>
</template>

<script>
import api from "@/api";
import { btn_primary } from "@/assets/variables";

import PaginationComponent from "@/components/client/pagination-component.vue";

import InquireForm from "@/components/client/shop/inquires/inquire-form.vue";
import InquireListSearch from "@/components/client/shop/inquires/inquire-list-search.vue";

const headers = [
    { text: "상태", value: "actions", align: "center", width: 120 },
    { text: "제목", value: "subject", align: "center", cellClass: "text-start" },
    { text: "작성일", value: "createdAt", align: "center", width: 200 },
    { text: "작성자", value: "user", align: "center", width: 200 },
];

export default {
    components: {
        PaginationComponent,

        InquireForm,
        InquireListSearch,
    },
    props: {
        product: { type: Object, default: null },
    },
    data: () => {
        return {
            inquires: [],

            page: 1,
            limit: 5,
            summary: { totalCount: 0 },

            filter: {
                searchKey: "subject",
                searchValue: null,
            },

            loading: false,
            headers,
            expanded: [],

            btn_primary,
        };
    },
    computed: {
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },

        accessToken() {
            return this.$store.state.accessToken;
        },
        payload() {
            return this.$store.state.payload;
        },
        params() {
            const { ...filter } = this.filter || {};
            filter._product = this.product?._id;
            if (!filter.searchValue) {
                delete filter.searchKey;
                delete filter.searchValue;
            }
            return filter;
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        "summary.totalCount"(count) {
            this.$emit("setSummaryInquires", count);
        },
        page() {
            this.search();
        },
    },
    methods: {
        async init() {
            try {
                this.page = 1;
                this.inquires = [];

                this.search();
            } catch (error) {
                reject(error);
            }
        },

        async search() {
            if (this.loading) return;
            else this.loading = true;

            try {
                let { skip, limit, params } = this;
                var { summary, inquires } = await api.v1.shop.inquires.gets({
                    headers: { skip, limit },
                    params,
                });

                this.summary = summary;
                this.inquires = inquires;
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.loading = false;
            }
        },

        clickRow(item, event) {
            if (event.isExpanded) {
                const index = this.expanded.findIndex((i) => i === item);
                this.expanded.splice(index, 1);
            } else {
                this.expanded.push(item);
            }
        },
    },
};
</script>
