var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "nicepay",
    attrs: {
      "id": "nicepay"
    }
  }, [_vm.show ? _c('iframe', {
    ref: "iframe",
    staticClass: "screen",
    staticStyle: {
      "display": "none"
    },
    attrs: {
      "src": '/plugins/nicepayments/request?' + _vm.queryString,
      "width": "100%",
      "height": "100%",
      "marginwidth": "0",
      "marginheight": "0",
      "frameborder": "no",
      "scrolling": "no",
      "allowtransparency": "true"
    }
  }) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }