<template>
    <div>
        <terms-of-agreements-short v-bind="{ params: { code: { $in: ['order'] } } }" @isValid="(isValid) => $emit('isValid', isValid)" @input="(value) => $emit('input', value)" />
    </div>
</template>

<script>
import TermsOfAgreementsShort from "@/components/client/join/terms-of-agreements-short.vue";

export default {
    components: {
        TermsOfAgreementsShort,
    },
};
</script>

<style>
</style>