var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.deliveryMessage ? _c('v-tooltip', {
    attrs: {
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_c('v-icon', _vm._g(_vm._b({
          staticClass: "ml-4px cursor-pointer",
          attrs: {
            "small": "",
            "color": "grey lighten-3"
          }
        }, 'v-icon', attrs, false), on), [_vm._v("mdi-help-circle-outline")])];
      }
    }], null, false, 1857201869)
  }, [_c('span', [_vm._v(_vm._s(_vm.deliveryMessage))])]) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }