var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    attrs: {
      "tile": "",
      "outlined": ""
    }
  }, [_c('div', {
    staticClass: "pa-18px px-md-40px py-md-32px"
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "tit-wrap"
  }, [_c('div', {
    staticClass: "tit font-weight-light"
  }, [_vm._v("결제정보")])]), _c('v-row', {
    staticClass: "row--sm txt font-weight-light",
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    staticClass: "row--xxs",
    attrs: {
      "align": "center",
      "justify": "space-between"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', [_vm._v("총 주문금액")])]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', [_vm._v(" " + _vm._s(_vm.totalProductPrice.format()) + "원")])])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    staticClass: "row--xxs",
    attrs: {
      "align": "center",
      "justify": "space-between"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', {
    staticClass: "txt font-weight-light"
  }, [_vm._v("+ 총 배송비")])]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.totalDeliveryPrice.format()) + "원")])])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    staticClass: "row--xxs",
    attrs: {
      "align": "center",
      "justify": "space-between"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', {
    staticClass: "txt font-weight-light"
  }, [_vm._v("- 총 할인금액")])]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.totalDiscountPrice.format()) + "원")])])], 1)], 1), _vm.value.pointPrice ? _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    staticClass: "row--xxs",
    attrs: {
      "align": "center",
      "justify": "space-between"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', {
    staticClass: "txt font-weight-light"
  }, [_vm._v("마일리지 사용")])]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.totalPointPrice.format()) + "원")])])], 1)], 1) : _vm._e(), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-divider')], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    staticClass: "row--xxs",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "text-end",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', [_vm._v("총 결제 금액")])]), _c('v-col', {
    staticClass: "text-end",
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "tit font-weight-light"
  }, [_c('span', {
    staticClass: "red--text"
  }, [_vm._v(_vm._s(_vm.totalPrice.format()))]), _vm._v("원 ")])])], 1)], 1)], 1)], 1)], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }