var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "d-block d-md-none grey--text text--darken-4 font-weight-medium text-left mb-4px"
  }, [_vm._v(_vm._s(_vm.shippingMethodName))]), _c('div', {
    staticClass: "d-none d-md-block"
  }, [_vm._v(_vm._s(_vm.shippingMethodName))])]);

}
var staticRenderFns = []

export { render, staticRenderFns }