<template>
    <tbody>
        <tr tile v-bind="{ loading }">
            <td colspan="100%" class="text-center pa-18px py-md-42px grey lighten-5 font-size-14 font-size-md-20">
                <span> {{ productPrice.format() }}원 </span>
                <span> + </span>
                <span>
                    <template v-if="isShippingPayOnDelivery"> 배송비 착불 </template>
                    <template v-else-if="shippingPrice > 0"> 배송비 {{ shippingPrice.format() }}원 </template>
                    <template v-else> 배송비 무료 </template>
                </span>
                <span> - </span>
                <span> 총 할인금액 {{ discountPrice.format() }}원 </span>

                <span> = </span>
                <span> <span class="red--text">{{ totalPrice.format() }}</span>원 </span>
            </td>
        </tr>
    </tbody>
</template>

<script>
import { mapGetters } from "vuex";

import { SHIPPING_OPTION_CHARGE_TYPES, USAGE_TARGET_PRICES } from "@/assets/variables/constants";
import { initOrder } from "@/assets/variables/inits";

export default {
    props: {
        sellerGroup: { type: Object, default: () => ({}) },
        loading: { type: Boolean, default: false },
        value: { type: Object, default: initOrder },
    },
    computed: {
        ...mapGetters(["userLevel"]),

        carts() {
            return this.sellerGroup.shippingGroups.flatMap((shippingGroup) => shippingGroup.productGroups.flatMap(({ carts }) => carts));
        },
        productPrice() {
            const productPrice = this.$getProductPrice(this.carts);
            return productPrice;

            // const salePrice = this.$getProductSalePrice(this.carts);

            // const coupons = this.sellerGroup.coupons.filter((coupon) => coupon?.usage?.target?.price == USAGE_TARGET_PRICES.SHOP_PRODUCT_PRICE.value);
            // const couponPrice = this.$getCouponPrice(this.carts, coupons, this.value?.receiver?.geolocation, this.userLevel);

            // return salePrice - couponPrice;
        },
        shippingPrice() {
            return this.sellerGroup.shippingGroups.reduce((totalPrice, shippingGroup) => {
                const carts = shippingGroup.productGroups.flatMap(({ carts }) => carts);

                const servicePrice = this.$getServicePrice(carts);
                const deliveryPrice = this.$getDeliveryPrice(carts, this.value?.receiver?.geolocation, this.userLevel);

                return totalPrice + servicePrice + deliveryPrice;

                // const coupons = shippingGroup.coupons.filter((coupon) => coupon?.usage?.target?.price == USAGE_TARGET_PRICES.SHOP_DELIVERY_FARE.value);
                // const couponPrice = this.$getCouponPrice(carts, coupons, this.value?.receiver?.geolocation, this.userLevel);

                // return totalPrice + servicePrice + deliveryPrice - couponPrice;
            }, 0);
        },
        discountPrice() {
            let levelDiscountPrice = this.$getLevelDiscountPrice(this.carts, this.userLevel);
            let discountPrice = this.$getDiscountPrice(this.carts);
            let couponPrice = this.$getCouponPrice(this.carts, this.sellerGroup.coupons, this.value?.receiver?.geolocation, this.userLevel);

            return levelDiscountPrice + discountPrice + couponPrice;
        },
        totalPrice() {
            return this.productPrice + this.shippingPrice - this.discountPrice;
        },

        isShippingPayOnDelivery() {
            return this.sellerGroup.shippingGroups.every((shippingGroup) => shippingGroup.productGroups.every((productGroup) => productGroup.shippingChargeType === SHIPPING_OPTION_CHARGE_TYPES.PAY_ON_DELIVERY.value));
        },
    },
};
</script>

<style>
</style>