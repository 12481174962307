var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('tbody', [_vm._l(_vm.merchantGroup.shippingGroups, function (shippingGroup, i) {
    return [_vm._l(shippingGroup.purchaseGroups, function (purchaseGroup, j) {
      return [_c('result-item', _vm._b({
        key: `${i}-${j}`
      }, 'result-item', {
        shippingGroup,
        purchaseGroup
      }, false))];
    })];
  })], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }