var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('u-dialog', {
    attrs: {
      "name": "modal",
      "title": "장바구니",
      "max-width": "400"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_vm._t("activator", null, null, {
          attrs,
          on
        })];
      }
    }], null, true),
    model: {
      value: _vm.shows,
      callback: function ($$v) {
        _vm.shows = $$v;
      },
      expression: "shows"
    }
  }, [_c('div', {
    staticClass: "py-10px"
  }, [_c('div', {
    staticClass: "txt txt--sm txt--dark text-center"
  }, [_vm._v("선택하신 상품을 장바구니에 담았습니다.")])]), _c('div', {
    staticClass: "btn-wrap"
  }, [_c('v-row', {
    staticClass: "row--sm"
  }, [_c('v-col', [_c('v-btn', _vm._b({
    staticClass: "v-size--xx-large",
    attrs: {
      "block": ""
    },
    on: {
      "click": _vm.close
    }
  }, 'v-btn', Object.assign({}, _vm.btn_secondary), false), [_vm._v("쇼핑계속하기")])], 1), _c('v-col', [_c('v-btn', _vm._b({
    staticClass: "v-size--xx-large",
    attrs: {
      "block": "",
      "to": "/cart"
    }
  }, 'v-btn', Object.assign({}, _vm.btn_primary), false), [_vm._v("장바구니 가기")])], 1)], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }