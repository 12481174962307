var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('vertical-form-table', _vm._b({
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.order,
      callback: function ($$v) {
        _vm.order = $$v;
      },
      expression: "order"
    }
  }, 'vertical-form-table', {
    items: _vm.items
  }, false), [_c('template', {
    slot: "주문 쿠폰"
  }, [_c('order-coupons-default', _vm._b({
    on: {
      "input": _vm.emit
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on,
          count_total = _ref.count_total,
          count_usable = _ref.count_usable;
        return [_c('v-row', {
          staticClass: "row--xs",
          attrs: {
            "align": "center"
          }
        }, [_c('v-col', {
          attrs: {
            "cols": "12",
            "sm": "auto"
          }
        }, [_c('v-currency-field', {
          staticClass: "v-currency-field w-xl-440px",
          attrs: {
            "value": _vm.couponPrice__default,
            "outlined": "",
            "hide-details": "",
            "type": "number",
            "suffix": "원",
            "readonly": ""
          },
          on: {
            "input": _vm.emit
          },
          scopedSlots: _vm._u([{
            key: "append-outer",
            fn: function () {
              return [_c('v-btn', _vm._g(_vm._b({
                staticClass: "min-w-120px",
                attrs: {
                  "x-large": ""
                }
              }, 'v-btn', Object.assign({}, _vm.btn_secondary, attrs), false), on), [_vm._v("보유쿠폰 사용")])];
            },
            proxy: true
          }], null, true)
        })], 1), _c('v-col', {
          attrs: {
            "cols": "auto"
          }
        }, [_c('span', {
          staticClass: "txt txt--xs txt--light pl-sm-8px"
        }, [_vm._v("(사용가능 : " + _vm._s(count_usable) + "장 / 보유 " + _vm._s(count_total) + "장)")])])], 1)];
      }
    }]),
    model: {
      value: _vm.order,
      callback: function ($$v) {
        _vm.order = $$v;
      },
      expression: "order"
    }
  }, 'order-coupons-default', {
    carts: _vm.carts
  }, false))], 1)], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }