<template>
    <!-- <v-simple-table class="v-data-table--default v-data-table--no-hover">
        <tbody>
            <tr>
                <td>
                    <v-currency-field v-model="point" v-bind="{ max, disabled }" outlined hide-details type="number" suffix="원" class="v-currency-field" @input="emit">
                        <template #append-outer>
                            <v-btn v-bind="{ ...btn_secondary, disabled }" x-large class="min-w-120px" @click="togglePointMax">전체사용</v-btn>
                        </template>
                    </v-currency-field>
                </td>
            </tr>
        </tbody>
    </v-simple-table> -->
    <div>
        <v-row align="center" class="ma-n6px">
            <v-col cols="12" lg="auto" class="pa-6px">
                <h2 class="tit--xxs mr-8px">마일리지</h2>
            </v-col>
            <v-col cols="12" lg="auto" class="pa-6px">
                <v-currency-field v-model="point" v-bind="{ max, disabled }" dense hide-details type="number" class="v-currency-field" @input="emit">
                    <template #append-outer>
                        <v-btn v-bind="{ disabled }" tile class="min-w-120px" @click="togglePointMax">모두사용</v-btn>
                    </template>
                </v-currency-field>
            </v-col>
            <v-col cols="12" lg="auto" class="pa-6px txt--sm">
                <span>사용 가능&nbsp;</span>
                <strong >{{ user?.point?.format?.() || 0 }}M</strong>
                <!-- <span>보유</span>
                <strong class="txt--lg">{{ user?.point?.format?.() || 0 }}</strong> 원 -->
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { mapState } from "vuex";
import { attrs_input_secondary, btn_secondary } from "@/assets/variables";

export default {
    props: {
        max: { type: Number, default: 0 },
        value: { type: Number, default: 0 },
    },
    data: () => ({
        attrs_input_secondary,
        btn_secondary,

        point: 0,
    }),
    computed: {
        ...mapState(["user"]),
        disabled() {
            return !this.user?.point;
        },
    },
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.point = +this.value || 0;
        },
        emit() {
            this.$emit("input", this.point);
        },
        togglePointMax() {
            if (this.max == this.point) this.point = 0;
            else this.point = this.max;
            this.emit();
        },
    },
};
</script>