var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-sheet', {
    staticClass: "pt-24px pt-lg-30px"
  }, [_c('v-row', {
    attrs: {
      "no-gutters": "",
      "justify": "space-between",
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "txt txt--sm"
  }, [_vm._v(" TOTAL "), _c('span', {
    staticClass: "grey--text text--lighten-3"
  }, [_vm._v("(QUANTITY)")])])]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', {
    staticClass: "d-flex align-center line-height-1 grey--text text--darken-4"
  }, [_c('strong', {
    staticClass: "font-size-20 font-size-lg-24"
  }, [_vm._v(_vm._s(_vm.totalPrice.format()) + "원")])])])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }