<template>
    <cart-page tit="장바구니">
        <page-section containerSize="container--lg" class="page-section--first page-section--last">
            <!-- <template slot="tit">주문/결제</template> -->

            <!-- S: 장바구니 -->
            <page-section class="page-section--sm pt-0">
                <template slot="containerImmersive">
                    <order-carts v-model="order" v-bind="{ carts }" ref="order-carts" />
                </template>
            </page-section>
            <!-- E: 장바구니 -->

            <!-- S: 마일리지 및 쿠폰 -->
            <v-divider dark />
            <div class="py-40px py-md-60px">
                <v-row class="row--xxs">
                    <v-col cols="12" lg="6">
                        <order-point v-model="order.point" :max="maxUsablePoint" @input="emit" />
                    </v-col>
                    <v-col cols="12" lg="6">
                        <v-row justify="end" align="center" class="row--sm">
                            <v-col cols="12" lg="auto">
                                <h2 class="tit--xxs">쿠폰 할인 금액</h2>
                            </v-col>
                            <v-col cols="" class="text-lg-end">
                                <strong class="red--text tit--xxs">-{{ couponPrice.format() }}원</strong>
                            </v-col>
                            <v-col cols="12" lg="5">
                                <order-coupons-dialog v-model="order" v-bind="{ carts }">
                                    <template #activator="{ attrs, on }">
                                        <v-btn block v-bind="{ ...attrs, ...btn_secondary }" v-on="on">쿠폰 변경</v-btn>
                                    </template>
                                </order-coupons-dialog>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </div>
            <v-divider dark />
            <!-- E: 마일리지 및 쿠폰 -->
            <div class="mt-40px mt-md-60px">
                <v-row class="row--sm">
                    <v-col cols="12" xl="9">
                        <div>
                            <!-- S: 배송 정보 -->
                            <page-section class="page-section--sm pt-0">
                                <template slot="containerImmersive">
                                    <tit-wrap-line noDivider title="배송 정보" titleClass="tit--xxs"> </tit-wrap-line>
                                    <order-info v-model="order" @input="emit" />
                                </template>
                            </page-section>
                            <!-- E: 배송 정보 -->

                            <!-- S: 구매자정보 -->
                            <!-- <page-section class="page-section--sm pt-0">
                            <template slot="containerImmersive">
                                <tit-wrap-line noDivider title="구매자 정보입력"> </tit-wrap-line>
                                <order-sender v-model="order.sender" @input="emit" />
                            </template>
                        </page-section> -->
                            <!-- E: 구매자정보 -->

                            <!-- S: 수취인정보 -->
                            <!-- <page-section class="page-section--sm pt-0">
                            <template slot="containerImmersive">
                                <tit-wrap-line noDivider title="수취인 정보입력"> </tit-wrap-line>

                                <order-receiver v-model="order" @input="emit" />
                            </template>
                        </page-section> -->
                            <!-- E: 수취인정보 -->

                            <!-- S: 요청사항 -->
                            <!-- <page-section class="page-section--sm pt-0">
                            <template slot="containerImmersive">
                                <tit-wrap-line noDivider title="요청사항"> </tit-wrap-line>
                                <vertical-form-table v-model="order" :items="[{ key: 'requestMessage', term: '요청사항', type: 'textarea', ...attrs_input, placeholder: '요청사항을 입력하세요.(개인통관고유번호 입력)' }]" @input="emit" />
                            </template>
                        </page-section> -->
                            <!-- E: 요청사항 -->

                            <!-- S: 쿠폰할인 -->
                            <!-- <page-section class="page-section--sm pt-0">
                            <template slot="containerImmersive">
                                <tit-wrap-line noDivider title="쿠폰할인"></tit-wrap-line>
                                <order-coupons v-model="order" v-bind="{ carts }" @input="updateCoupons__carts" />
                            </template>
                        </page-section> -->
                            <!-- E: 쿠폰할인 -->

                            <!-- S: 적립금사용 -->
                            <!-- <page-section class="page-section--sm pt-0">
                            <template slot="containerImmersive">
                                <tit-wrap-line noDivider title="적립금사용">
                                    <span class="d-flex align-center txt txt--sm">
                                        <span class="pr-10px pr-md-16px">보유 적립금</span>
                                        <span class="grey--text text--darken-4">
                                            <strong class="txt--lg">{{ user?.point?.format?.() || 0 }}</strong> 원
                                        </span>
                                    </span>
                                </tit-wrap-line>
                                <order-point v-model="order.point" :max="maxUsablePoint" @input="emit" />
                            </template>
                        </page-section> -->
                            <!-- E: 적립금사용 -->

                            <!-- S: 결제수단 선택 -->
                            <page-section class="page-section--sm pt-0 pb-xl-0">
                                <template slot="containerImmersive">
                                    <tit-wrap-line noDivider title="결제수단 선택" titleClass="tit--xxs"> </tit-wrap-line>

                                    <v-simple-table class="v-data-table--default v-data-table--no-hover">
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <v-radio-group v-model="order.paymentMethod" row hide-details @change="emit">
                                                        <v-radio v-bind="{ ...radio_primary }" name="payment_method" value="card" label="신용카드" />
                                                        <v-radio v-bind="{ ...radio_primary }" name="payment_method" value="naverpayCard" label="네이버페이" />
                                                        <v-radio v-bind="{ ...radio_primary }" name="payment_method" value="kakaopay" label="카카오페이" />
                                                        <v-radio v-bind="{ ...radio_primary }" name="payment_method" value="samsungpayCard" label="삼성페이" />
                                                        <v-radio v-bind="{ ...radio_primary }" name="payment_method" value="vbank" label="가상계좌" />
                                                    </v-radio-group>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </v-simple-table>
                                </template>
                            </page-section>
                            <!-- E: 결제수단 선택 -->
                        </div>
                    </v-col>
                    <v-col cols="12" xl="3">
                        <!-- S: 결제정보 -->
                        <page-section class="page-section--sm pt-0 pb-xl-0 h-100 position-relative">
                            <template slot="containerImmersive">
                                <v-card outlined tile class="position-sticky">
                                    
                                    <v-card-title class="px-40px pt-32px pb-24px tit font-weight-light">결제정보</v-card-title>
                                    <v-card-text class="px-40px pb-20px">
                                        <div>
                                            <v-row justify="space-between" align="center" class="row--xs txt font-weight-light">
                                                <v-col cols="auto">
                                                    <span class="txt--dark">총 판매금액</span>
                                                </v-col>
                                                <v-col cols="auto" class="text-end">
                                                    <span> {{ productPrice.format() }} 원 </span>
                                                </v-col>
                                            </v-row>
                                            <v-row justify="space-between" align="center" class="row--xs txt font-weight-light">
                                                <v-col cols="auto">
                                                    <span class="txt--dark">+ 총 배송비</span>
                                                </v-col>
                                                <v-col cols="7" sm="9" md="10" xl="7" class="text-end">
                                                    <!-- <span> {{ (servicePrice + deliveryPrice).format() }} 원 </span> -->
                                                    <span> {{ totalDeliveryPrice.format() }} 원 </span>
                                                </v-col>
                                            </v-row>
                                            <v-row justify="space-between" align="center" class="row--xs txt font-weight-light">
                                                <v-col cols="auto">
                                                    <span class="txt--dark">- 총 할인금액</span>
                                                </v-col>
                                                <v-col cols="auto" class="text-end">
                                                    <span> {{ totalDiscountPrice.format() }} 원 </span>
                                                </v-col>
                                            </v-row>

                                            <v-row justify="space-between" align="center" class="row--xs txt font-weight-light">
                                                <v-col cols="auto">
                                                    <span class="txt--dark">마일리지 사용</span>
                                                </v-col>
                                                <v-col cols="auto" class="text-end">
                                                    <span> {{ order?.point?.format?.() }} 원 </span>
                                                </v-col>
                                            </v-row>
                                            <!-- <div class="py-16px px-20px border-b" v-if="couponPrice">
                                                <v-row align="center" class="row--xs txt txt--xs">
                                                    <v-col cols="5" sm="3" md="2" xl="5">
                                                        <span class="txt--dark">쿠폰할인</span>
                                                    </v-col>
                                                    <v-col cols="7" sm="9" md="10" xl="7">
                                                        <span> {{ couponPrice.format() }} 원 </span>
                                                    </v-col>
                                                </v-row>
                                            </div> -->
                                            <div class="my-24px py-30px border-t border-b text-end">
                                                <v-row align="center" class="row--xs txt txt--dark font-weight-light">
                                                    <v-col cols="5" sm="3" md="2" xl="12">
                                                        <span>총 결제예정 금액</span>
                                                    </v-col>
                                                    <v-col cols="7" sm="9" md="10" xl="12">
                                                        <span class="d-inline-flex flex-wrap align-center">
                                                            <div class="font-size-20 font-size-md-32">
                                                                <span class="red--text">{{ totalPrice.format() }}</span
                                                                >원
                                                            </div>
                                                        </span>
                                                    </v-col>
                                                </v-row>
                                            </div>
                                        </div>
                                    </v-card-text>
                                    <div class="grey lighten-5 px-40px py-30px">
                                        <div class="tit-wrap tit-wrap--xs">
                                            <div class="txt txt--dark font-weight-medium">약관동의</div>
                                        </div>
                                        <order-point-benefit />
                                        <order-agreements v-model="order._terms" @isValid="(isValid) => (isTermsValid = isValid)" />
                                    </div>
                                    <v-card-actions class="pa-0">
                                        <v-btn block color="grey darken-4" tile class="v-size--xx-large" v-bind="{ loading, disabled: !isTermsValid }" @click="pay">결제하기</v-btn>
                                    </v-card-actions>
                                </v-card>
                            </template>
                        </page-section>
                        <!-- E: 결제정보 -->
                    </v-col>
                </v-row>
            </div>
        </page-section>

        <v-fade-transition>
            <v-overlay v-show="loading" color="white">
                <v-progress-circular indeterminate size="100" width="5" color="primary" />
            </v-overlay>
        </v-fade-transition>

        <!-- 무통장 입금 모달 -->
        <modal-order-bank ref="modal-order-bank" v-model="order" @pay="submit" :totalPrice="totalPrice"> </modal-order-bank>

        <order-geolocation v-model="order.receiver.geolocation" :address="order.receiver.address1" @input="emit" />

        <!-- <nice-pay ref="nicePay" />
        <nice-pay-u ref="nicePayU" /> -->
        <nice-payments ref="nicePay" v-on="{ complete }" />
    </cart-page>
</template>

<script>
import api from "@/api";
import { NICEPAY_PURCAHSE_TYPES, radio_primary, btn_secondary } from "@/assets/variables";
import { mapActions, mapGetters, mapState } from "vuex";

import detect from "@/plugins/detect.js";
import { attrs_input } from "@/assets/variables";
import { initOrder } from "@/assets/variables/inits";

import ClientPage from "@/components/client/templates/client-page.vue";
import PageSection from "@/components/client/templates/page-section.vue";
import TitWrapLine from "@/components/client/dumb/tit-wrap-line.vue";
import VerticalFormTable from "@/components/dumb/vertical-form-table.vue";
import CartPage from "@/components/client/templates/cart-page.vue";

import NicePay from "@/components/plugins/nice/nice-pay.vue";
import NicePayU from "@/components/plugins/nice/nice-pay-u.vue";
import NicePayments from "@/components/plugins/nice/nice-payments.vue";

import OrderCarts from "@/components/client/shop/order/order-carts.vue";
import OrderInfo from "@/components/client/shop/order/order-info.vue";
import OrderPoint from "@/components/client/shop/order/order-point.vue";
import OrderSender from "@/components/client/shop/order/order-sender.vue";
import OrderCoupons from "@/components/client/shop/order/order-coupons/order-coupons.vue";
import OrderReceiver from "@/components/client/shop/order/order-receiver.vue";
import OrderGeolocation from "@/components/client/shop/order/order-geolocation.vue";
import DestinationSelectDialog from "@/components/client/shop/destinations/destination-select-dialog.vue";
import OrderPointBenefit from "@/components/client/shop/order/order-benefits/order-point-benefit.vue";
import OrderAgreements from "@/components/client/shop/order/order-agreements.vue";
import OrderCouponsDialog from "@/components/client/shop/order/order-coupons/order-coupons-dialog.vue";

import ModalOrderBank from "../../../components/client/shop/modal-order-bank.vue";

export default {
    components: {
        ClientPage,
        PageSection,
        TitWrapLine,
        VerticalFormTable,
        CartPage,

        NicePay,
        NicePayU,
        NicePayments,

        OrderCarts,
        OrderPoint,
        OrderInfo,
        OrderSender,
        OrderCoupons,
        OrderReceiver,
        OrderGeolocation,
        DestinationSelectDialog,
        OrderPointBenefit,
        OrderAgreements,
        OrderCouponsDialog,

        ModalOrderBank,
    },

    data: () => ({
        radio_primary,
        btn_secondary,

        detect,

        loading: false,

        pickup: { _carts: [] },
        carts: [],

        order: initOrder(),
        attrs_input,

        isTermsValid: false,
    }),

    computed: {
        ...mapGetters(["userLevel"]),
        ...mapState(["user", "islands"]),

        ///////////////////////////////////////////////////////////////////////
        // 액세스 토큰
        ///////////////////////////////////////////////////////////////////////
        accessToken() {
            return this.$store.state.accessToken;
        },

        ///////////////////////////////////////////////////////////////////////
        // 우편번호
        ///////////////////////////////////////////////////////////////////////
        postcode() {
            return this.order.receiver.postcode;
        },

        ///////////////////////////////////////////////////////////////////////
        // 배송가능지역
        ///////////////////////////////////////////////////////////////////////
        isShippingAvailable() {
            return this.$getIsShippingAvailable(this.carts, this.order?.receiver);
        },

        ///////////////////////////////////////////////////////////////////////
        // 상품가 합계
        ///////////////////////////////////////////////////////////////////////
        productPrice() {
            return this.$getProductPrice(this.carts);
        },

        ///////////////////////////////////////////////////////////////////////
        // 설치서비스 합계
        ///////////////////////////////////////////////////////////////////////
        servicePrice() {
            return this.$getServicePrice(this.carts);
        },

        ///////////////////////////////////////////////////////////////////////
        // 할인가 합계
        ///////////////////////////////////////////////////////////////////////
        discountPrice() {
            return this.$getDiscountPrice(this.carts);
        },

        ///////////////////////////////////////////////////////////////////////
        // 등급할인 합계
        ///////////////////////////////////////////////////////////////////////
        levelDiscountPrice() {
            return this.$getLevelDiscountPrice(this.carts, this.userLevel);
        },

        ///////////////////////////////////////////////////////////////////////
        // 쿠폰할인금액 합계
        ///////////////////////////////////////////////////////////////////////
        couponPrice() {
            return this.$getCouponPrice(this.carts, this.order.coupons, this.order?.receiver?.geolocation, this.userLevel);
        },

        ///////////////////////////////////////////////////////////////////////
        // 배송비 합계
        ///////////////////////////////////////////////////////////////////////
        deliveryPrice() {
            return this.$getDeliveryPrice(this.carts, this.order?.receiver?.geolocation, this.userLevel);
        },

        ///////////////////////////////////////////////////////////////////////
        // 도서산간비용 합계
        ///////////////////////////////////////////////////////////////////////
        islandPrice() {
            return this.$getIslandPrice(this.carts, this.islands, this.postcode);
        },

        ///////////////////////////////////////////////////////////////////////
        // 최대사용가능포인트
        ///////////////////////////////////////////////////////////////////////
        maxUsablePoint() {
            const priceLeft = this.productPrice + this.servicePrice + this.deliveryPrice + this.islandPrice - this.discountPrice - this.couponPrice - this.levelDiscountPrice || 0;
            const pointLeft = this.user?.point || 0;
            return [priceLeft, pointLeft].sort((a, b) => a - b).shift();
        },

        ///////////////////////////////////////////////////////////////////////
        // 총 배송비
        ///////////////////////////////////////////////////////////////////////
        totalDeliveryPrice() {
            return this.servicePrice + this.deliveryPrice + this.islandPrice;
        },

        ///////////////////////////////////////////////////////////////////////
        // 총 할인금액
        ///////////////////////////////////////////////////////////////////////
        totalDiscountPrice() {
            return this.discountPrice + this.levelDiscountPrice + this.couponPrice;
        },

        ///////////////////////////////////////////////////////////////////////
        // 총 결제 금액
        ///////////////////////////////////////////////////////////////////////
        totalPrice() {
            return this.productPrice + this.servicePrice + this.deliveryPrice + this.islandPrice - this.discountPrice - this.levelDiscountPrice - this.couponPrice - this.order.point;
        },
    },
    mounted() {
        this.reroute().then(this.init);
    },
    watch: {
        accessToken() {
            this.reroute();
        },
        isShippingAvailable() {
            if (!this.isShippingAvailable) {
                alert("해당 주소로는 주문할 수 없는 배송이 있습니다.");
            }
        },
    },
    methods: {
        ...mapActions(["getUser", "getIslands"]),

        openModalOrderBank() {
            this.$refs["modal-order-bank"].open();
        },

        emit() {
            this.order = initOrder(this.order);
        },

        async reroute() {
            try {
                if (!this.accessToken) throw new Error("로그인 후 사용하실 수 있습니다");
            } catch (error) {
                this.$handleError(error);
                this.$router.replace("/login");
            }
        },

        async init() {
            if (!this.accessToken) return;
            if (this.loading) return;
            else this.loading = true;

            try {
                if (!this.$store.state.pickup.length) {
                    alert("구매할 상품을 선택해주세요");
                    this.$router.go(-1);
                    return;
                }

                const _carts = this.$store.state.pickup;
                this.order._carts = _carts;

                const { carts } = await api.v1.me.carts.gets({ params: { _carts } });
                this.carts = carts;

                this.getUser().then(() => this.$nextTick(() => (this.order.sender = { ...(this.order?.sender || {}), ...(this.user || {}) })));

                this.getIslands();
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.loading = false;
            }
        },

        async validates() {
            try {
                if (!this.order?.receiver?.name) throw new Error("수취인명을 입력해주세요");
                if (!this.order?.receiver?.phone) throw new Error("수취인 연락처를 입력해주세요");
                if (!this.order?.receiver?.address2) throw new Error("수취인 주소를 입력해주세요");

                const hasAnUncombinableCoupon = this.order?.coupons?.some?.((coupon) => !coupon?.usage?.combinable) || false;
                const isCouponsCountMoreThan1 = 1 < this.order?.coupons?.length || 0;
                if (hasAnUncombinableCoupon && isCouponsCountMoreThan1) throw new Error("중복사용이 불가능한 쿠폰이 있습니다.");

                if (!this.order?.receiver?._id) {
                    if (!this.order?.receiver?.title) throw new Error("배송지명을 입력해주세요");
                }

                return true;
            } catch (error) {
                this.$handleError(error);
                return false;
            }
        },

        async pay() {
            if (!this.isShippingAvailable) {
                alert("해당 주소로는 주문할 수 없는 배송이 있습니다.");
                return;
            }

            if (!(await this.validates())) return;

            const { receiver } = this.order || {};
            if (receiver && !receiver?._id) {
                const { destination } = await api.v1.me.destinations.post(receiver);
                this.order.receiver = destination;
            }

            switch (this.order.paymentMethod) {
                // case "account": {
                //     this.openModalOrderBank();
                //     break;
                // }
                case "card": {
                    this.submit();
                    break;
                }
                // case "cardAndEasyPay": {
                //     this.submit();
                //     break;
                // }
                case "kakaopay": {
                    this.submit();
                    break;
                }
                case "naverpayCard": {
                    this.submit();
                    break;
                }
                case "samsungpayCard": {
                    this.submit();
                    break;
                }
                case "vbank": {
                    this.submit();
                    break;
                }
                // case "bank": {
                //     this.submit();
                //     break;
                // }
            }
        },

        async submit() {
            if (this.loading) return;
            else this.loading = true;

            try {
                let { ...form } = this.order;
                let { _tag, _board } = this.$route.query;

                form._coupons = form.coupons.map(({ _id }) => _id);
                delete form.coupons;

                let { order } = await api.v1.shop.orders.post({ ...form, _tag, _board });

                if (order.totalPrice) {
                    switch (order.paymentMethod) {
                        // case "account": {
                        //     order = (await api.v1.shop.orders.process.post(order))?.order;
                        //     this.$router.push({ path: "/shop/result", query: { _order: order?._id } });
                        //     break;
                        // }
                        case "card": {
                            this.$refs.nicePay.collection = NICEPAY_PURCAHSE_TYPES.SHOP_ORDERS.value;
                            this.$refs.nicePay.orderNo = order.orderNo;
                            this.$refs.nicePay.orderName = this.carts[0].product.name;
                            this.$refs.nicePay.paymentMethod = "card";
                            this.$refs.nicePay.paymentGateway = "";
                            this.$refs.nicePay.paymentAmount = order.totalPrice;
                            this.$refs.nicePay.buyerName = order.sender.name;
                            this.$refs.nicePay.buyerPhone = order.sender.phone;
                            this.$refs.nicePay.buyerEmail = order.sender.email;
                            this.$refs.nicePay.pay();
                            break;
                        }
                        // case "cardAndEasyPay": {
                        //     this.$refs.nicePay.collection = NICEPAY_PURCAHSE_TYPES.SHOP_ORDERS.value;
                        //     this.$refs.nicePay.orderNo = order.orderNo;
                        //     this.$refs.nicePay.orderName = this.carts[0].product.name;
                        //     this.$refs.nicePay.paymentMethod = "cardAndEasyPay";
                        //     this.$refs.nicePay.paymentGateway = "";
                        //     this.$refs.nicePay.paymentAmount = order.totalPrice;
                        //     this.$refs.nicePay.buyerName = order.sender.name;
                        //     this.$refs.nicePay.buyerPhone = order.sender.phone;
                        //     this.$refs.nicePay.buyerEmail = order.sender.email;
                        //     this.$refs.nicePay.pay();

                        //     break;
                        // }
                        case "naverpayCard": {
                            this.$refs.nicePay.collection = NICEPAY_PURCAHSE_TYPES.SHOP_ORDERS.value;
                            this.$refs.nicePay.orderNo = order.orderNo;
                            this.$refs.nicePay.orderName = this.carts[0].product.name;
                            this.$refs.nicePay.paymentMethod = "naverpayCard";
                            this.$refs.nicePay.paymentGateway = "";
                            this.$refs.nicePay.paymentAmount = order.totalPrice;
                            this.$refs.nicePay.buyerName = order.sender.name;
                            this.$refs.nicePay.buyerPhone = order.sender.phone;
                            this.$refs.nicePay.buyerEmail = order.sender.email;
                            this.$refs.nicePay.pay();

                            break;
                        }

                        case "kakaopay": {
                            this.$refs.nicePay.collection = NICEPAY_PURCAHSE_TYPES.SHOP_ORDERS.value;
                            this.$refs.nicePay.orderNo = order.orderNo;
                            this.$refs.nicePay.orderName = this.carts[0].product.name;
                            this.$refs.nicePay.paymentMethod = "kakaopay";
                            this.$refs.nicePay.paymentGateway = "";
                            this.$refs.nicePay.paymentAmount = order.totalPrice;
                            this.$refs.nicePay.buyerName = order.sender.name;
                            this.$refs.nicePay.buyerPhone = order.sender.phone;
                            this.$refs.nicePay.buyerEmail = order.sender.email;
                            this.$refs.nicePay.pay();

                            break;
                        }

                        case "samsungpayCard": {
                            this.$refs.nicePay.collection = NICEPAY_PURCAHSE_TYPES.SHOP_ORDERS.value;
                            this.$refs.nicePay.orderNo = order.orderNo;
                            this.$refs.nicePay.orderName = this.carts[0].product.name;
                            this.$refs.nicePay.paymentMethod = "samsungpayCard";
                            this.$refs.nicePay.paymentGateway = "";
                            this.$refs.nicePay.paymentAmount = order.totalPrice;
                            this.$refs.nicePay.buyerName = order.sender.name;
                            this.$refs.nicePay.buyerPhone = order.sender.phone;
                            this.$refs.nicePay.buyerEmail = order.sender.email;
                            this.$refs.nicePay.pay();

                            break;
                        }

                        case "vbank": {
                            this.$refs.nicePay.collection = NICEPAY_PURCAHSE_TYPES.SHOP_ORDERS.value;
                            this.$refs.nicePay.orderNo = order.orderNo;
                            this.$refs.nicePay.orderName = this.carts[0].product.name;
                            this.$refs.nicePay.paymentMethod = "vbank";
                            this.$refs.nicePay.paymentGateway = "";
                            this.$refs.nicePay.paymentAmount = order.totalPrice;
                            this.$refs.nicePay.buyerName = order.sender.name;
                            this.$refs.nicePay.buyerPhone = order.sender.phone;
                            this.$refs.nicePay.buyerEmail = order.sender.email;
                            this.$refs.nicePay.vbankHolder = "레일리";
                            // this.$refs.nicePay.vbankValidHours = order.sender.email;
                            // this.$refs.nicePay.vbankExpDate = order.sender.email;
                            this.$refs.nicePay.pay();

                            this.$refs.nicePayU.submit();
                            break;
                        }
                        // case "bank": {
                        //     this.$refs.nicePay.collection = NICEPAY_PURCAHSE_TYPES.SHOP_ORDERS.value;
                        //     this.$refs.nicePay.orderNo = order.orderNo;
                        //     this.$refs.nicePay.orderName = this.carts[0].product.name;
                        //     this.$refs.nicePay.paymentMethod = "bank";
                        //     this.$refs.nicePay.paymentGateway = "";
                        //     this.$refs.nicePay.paymentAmount = order.totalPrice;
                        //     this.$refs.nicePay.buyerName = order.sender.name;
                        //     this.$refs.nicePay.buyerPhone = order.sender.phone;
                        //     this.$refs.nicePay.buyerEmail = order.sender.email;
                        //     this.$refs.nicePay.vbankHolder = "주식회사 레일리";
                        //     // this.$refs.nicePay.vbankValidHours = order.sender.email;
                        //     // this.$refs.nicePay.vbankExpDate = order.sender.email;
                        //     this.$refs.nicePay.pay();

                        //     this.$refs.nicePayU.submit();
                        //     break;
                        // }
                    }
                } else {
                    order = (await api.v1.shop.orders.process.post(order))?.order;
                    this.$router.push({ path: "/shop/result", query: { _order: order?._id } });
                }
            } finally {
                this.loading = false;
            }
        },

        complete({ resCode, resMessage, _order }) {
            this.$router.push({ path: "/shop/result", query: { _order, resCode, resMessage } });
        },

        async updateCoupons__carts() {
            if (this.loading) return;
            else this.loading = true;

            this.emit();

            this.$nextTick(async () => {
                try {
                    await this.$refs["order-carts"]?.emit?.();
                } finally {
                    this.loading = false;
                }
            });
        },
    },
};
</script>