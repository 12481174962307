<template>
    <v-tabs v-model="sort" model-value="" height="auto" hide-slider>
        <v-tab v-for="item in items" :key="item.text" @click="setSort(item)">
            {{ item.text }}
        </v-tab>
    </v-tabs>
</template>

<script>
import TabTertiary from "@/components/client/tab/tab-tertiary.vue";
export default {
    components: {
        TabTertiary,
    },
    data: () => ({
        sort: 0,
        items: [
            { text: "전체", value: null },
            { text: "신상품", value: { sort: "lastestAsc" } },
            { text: "낮은 가격", value: { sort: "priceAsc" } },
            { text: "높은 가격", value: { sort: "priceDesc" } },
            { text: "판매순", value: { sort: "saleDesc" } },
        ]
    }),
    mounted() {
        this.sync();
    },
    methods:{
        sync(){
            if(this.$route.query.sort) {
                const index = this.items.findIndex((item) => (item?.value?.sort || "") === this.$route.query.sort);
                this.sort = index === -1 ? 0 : index;
            };
        },
        setSort(item){
            let { sort, ...query } = this.$route.query;
            delete query.page;

            this.$router.push({ query: { ...query, ...item.value } });
        }
    },
    watch: {
        "$route.query.category"() {
            this.sort = 0;
        }
    }
};
</script>

<style lang="scss" scoped>
.v-tab {
    height: fit-content;
    border-right: 1px solid var(--border-color);
    font-size: var(--txt-font-size-sm);
    &::before,
    &::after {
        display: none;
    }
    &:first-child {
        padding-left: 0;
    }
    &:last-child {
        padding-right: 0;
        border-right: none;
    }
    &--active {
        font-weight: 700;
    }
}
</style>
