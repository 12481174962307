<template>
    <div>
        <div class="d-block d-md-none grey--text text--darken-4 font-weight-medium text-left mb-4px">{{ shippingMethodName }}</div>
        <div class="d-none d-md-block">{{ shippingMethodName }}</div>
    </div>
</template>

<script>
export default {
    props: {
        shippingGroup: { type: Object, default: () => ({}) },
    },

    computed: {
        // 기타 데이터
        shippingMethodName() {
            const { name, code } = this.shippingGroup?.shippingMethod || {};
            return name || code;
        },
    },
};
</script>

<style>
</style>