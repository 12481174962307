var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: `category-sidebar${_vm.shows ? '' : ' sidebar-hide'}`
  }, [_c('div', {
    staticClass: "category-sidebar__inner"
  }, [_c('nav', {
    staticClass: "product-category"
  }, [_c('div', {
    staticClass: "product-category__inner"
  }, [_vm._m(0), _c('v-divider', {
    staticClass: "grey darken-4 mb-14px",
    staticStyle: {
      "border-width": "3px 0 0"
    }
  }), _c('v-list', {
    staticClass: "product-category-list",
    attrs: {
      "flat": ""
    }
  }, [_vm._l(_vm.items, function (item) {
    return [_vm.filter(item.children).length ? _c('v-list-group', {
      key: item._id,
      attrs: {
        "value": _vm._selecteds.includes(item._id),
        "active-class": "product-category-list--active"
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function () {
          return [_c('v-list-item-title', [_vm._v(" " + _vm._s(item.name) + " ")])];
        },
        proxy: true
      }], null, true)
    }, [_c('v-list-item', {
      class: {
        'v-list-item--exact-active': _vm.$route.query.category == item.code
      },
      on: {
        "click": function ($event) {
          return _vm.setCategory(item);
        }
      }
    }, [_c('v-list-item-title', [_vm._v("전체보기")])], 1), _vm._l(_vm.filter(item.children), function (child) {
      return _c('filter-categories-expander', _vm._g(_vm._b({
        key: child._id
      }, 'filter-categories-expander', {
        _selecteds: _vm._selecteds,
        category: child
      }, false), {
        setCategory: _vm.setCategory
      }), _vm._l(_vm.filter(child.children), function (grandChild) {
        return _c('filter-categories-expander', _vm._g(_vm._b({
          key: grandChild._id
        }, 'filter-categories-expander', {
          _selecteds: _vm._selecteds,
          category: grandChild
        }, false), {
          setCategory: _vm.setCategory
        }), _vm._l(_vm.filter(grandChild.children), function (granderChild) {
          return _c('filter-categories-expander', _vm._g(_vm._b({
            key: granderChild._id
          }, 'filter-categories-expander', {
            _selecteds: _vm._selecteds,
            category: granderChild
          }, false), {
            setCategory: _vm.setCategory
          }));
        }), 1);
      }), 1);
    })], 2) : _c('v-list-item', {
      key: item._id,
      attrs: {
        "value": _vm.$route.query.category == item.code
      },
      on: {
        "click": function ($event) {
          return _vm.setCategory(item);
        }
      }
    }, [_c('v-list-item-title', {
      class: {
        'primary--text': _vm.$route.query.category == item.code
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")])], 1)];
  })], 2)], 1)])])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mb-10px"
  }, [_c('h3', {
    staticClass: "tit tit--xs"
  }, [_vm._v("카테고리")])]);

}]

export { render, staticRenderFns }