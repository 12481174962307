<template>
    <client-page >
        <v-container>
            <div class="tit-wrap text-center">
                <h2 class="tit">"{{ searchValue }}"검색 결과</h2>
            </div>
            <search-bar />
            <search-product-list v-if="!mode || mode === SEARCH_LIST.PRODUCT.value" @input="total" v-show="!shows" />
            <search-story-list v-if="!mode || mode === SEARCH_LIST.STORY.value" @input="total" v-show="!shows" v-bind="{ hasCompanyScope }" />
            <search-user-list v-if="!mode || mode === SEARCH_LIST.USER.value" @input="total" v-show="!shows" />
            <search-minishop-list v-if="!mode || mode === SEARCH_LIST.MINISHOP.value" @input="total" v-show="!shows" />
            <search-none-text v-if="shows" />
        </v-container>
    </client-page>
</template>

<script>
import { mapGetters } from "vuex";
import ClientPage from "@/components/client/templates/client-page.vue";
import SearchBar from "@/components/client/search/search-bar.vue";
import SearchProductList from "@/components/client/search/product/search-product-list.vue";
import SearchStoryList from "@/components/client/search/story/search-story-list.vue";
import SearchUserList from "@/components/client/search/nickname/search-user-list.vue";
import SearchMinishopList from "@/components/client/search/minishop/search-minishop-list.vue";
import SearchNoneText from "@/components/client/search/search-none-text.vue";
import { SEARCH_LIST } from "@/components/client/search/search-bar.vue";

export default {
    components: {
        ClientPage,
        SearchBar,
        SearchProductList,
        SearchStoryList,
        SearchUserList,
        SearchMinishopList,
        SearchNoneText,
    },
    data: () => ({
        SEARCH_LIST,
        productsCount: 0,
        storiesCount: 0,
        usersCount: 0,
        minishopsCount: 0,
    }),
    computed: {
        ...mapGetters(["hasCompanyScope"]),
        searchValue() {
            return this.$route.query.searchValue;
        },
        mode() {
            return this.$route.query.mode;
        },
        shows() {
            if (!this.mode) {
                if (
                    // 전체 검색 시, 검색 결과가 0개일 때
                    this.productsCount === 0 &&
                    this.storiesCount === 0 &&
                    this.usersCount === 0 &&
                    this.minishopsCount === 0
                )
                    return true;
            } else {
                // 상세 검색 시, 해당 검색 결과가 0개일 때
                switch (this.mode) {
                    case SEARCH_LIST.PRODUCT.value:
                        if (this.productsCount === 0) return true;
                        break;
                    case SEARCH_LIST.STORY.value:
                        if (this.storiesCount === 0) return true;
                        break;
                    case SEARCH_LIST.USER.value:
                        if (this.usersCount === 0) return true;
                        break;
                    case SEARCH_LIST.MINISHOP.value:
                        if (this.minishopsCount === 0) return true;
                        break;
                    default:
                        return false;
                }
            }
        },
    },
    methods: {
        total({ type, totalCount }) {
            this[type] = totalCount;
        },
    },
};
</script>

<style>
</style>