<template>
    <v-tooltip bottom v-if="deliveryMessage">
        <template #activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" small color="grey lighten-3" class="ml-4px cursor-pointer">mdi-help-circle-outline</v-icon>
        </template>
        <span>{{ deliveryMessage }}</span>
    </v-tooltip>
</template>

<script>
export default {
    props: {
        shippingGroup: { type: Object, default: () => ({}) },
    },
    computed: {
        deliveryMessage() {
            return this.shippingGroup.deliveryMessage;
        },
    },
};
</script>

<style>
</style>