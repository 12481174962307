var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('cart-page', {
    attrs: {
      "tit": "결제완료"
    }
  }, [_vm.success ? [_c('result-view', _vm._b({}, 'result-view', {
    value: _vm.order
  }, false)), _c('result-info', _vm._b({}, 'result-info', {
    value: _vm.order
  }, false)), _c('v-row', {
    staticClass: "row--xxs",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "6",
      "lg": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "v-size--xx-large",
    attrs: {
      "width": "328",
      "max-width": "100%",
      "color": "primary",
      "tile": "",
      "to": "/mypage/orders"
    }
  }, [_vm._v("주문내역")])], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "lg": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "v-size--xx-large",
    attrs: {
      "width": "328",
      "max-width": "100%",
      "outlined": "",
      "tile": "",
      "to": "/shop/products"
    }
  }, [_vm._v("쇼핑계속하기")])], 1)], 1)] : [_c('div', {
    staticClass: "text-center"
  }, [_c('div', {
    staticClass: "mb-18px mb-md-24px"
  }, [_c('v-icon', {
    attrs: {
      "size": "60px",
      "color": "primary"
    }
  }, [_vm._v("mdi-alert")])], 1), _c('div', {
    staticClass: "tit"
  }, [_vm._v("주문/결제에 실패하였습니다.")]), _c('div', {
    staticClass: "txt txt--sm txt--dark mt-8px"
  }, [_vm._v("[" + _vm._s(_vm.resCode) + "] " + _vm._s(_vm.resMessage))]), _c('v-btn', _vm._b({
    staticClass: "v-size--xx-large w-20",
    attrs: {
      "to": "/"
    }
  }, 'v-btn', Object.assign({}, _vm.btn_primary), false), [_vm._v("확인")])], 1)]], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }