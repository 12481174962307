<template>
    <info-section class="txt txt--xs" v-if="items.length">
        <tit-wrap-x-small title=" 연관상품" />
        <v-select
            :value="product._id"
            v-bind="{ items }"
            item-value="_id"
            dense
            outlined
            hide-details
            :menu-props="{
                offsetY: true,
                contentClass: 'select-menu',
                closeOnClick: true,
                closeOnContentClick: true,
            }"
        >
            <template #selection="{ item }">
                {{ item.name }}
            </template>
            <template #item="{ item }">
                <v-list-item :to="`/shop/products/${item._id}`" :disabled="!item.sells || item.stock == 0">
                    <v-list-item-content>
                        <v-row align="center" class="row--sm">
                            <v-col cols="auto">
                                <v-card>
                                    <v-img width="60" :aspect-ratio="1 / 1" :src="item?.thumb?.url">
                                        <template #place-holder>
                                            <v-icon>mdi-image-broken-variant</v-icon>
                                        </template>
                                    </v-img>
                                </v-card>
                            </v-col>
                            <v-col>
                                <div class="font-size-14 line-height-135">
                                    <div v-if="item?.flags?.length" class="font-weight-bold">{{ item?.flags?.map((text) => `[${text}]`).join(" ") }}</div>
                                    <div>{{ item?.name }}</div>
                                    <div class="mt-8px mt-md-10px">
                                        <div v-if="item.discountPrice" class="text-decoration-line-through grey--text text--lighten-1">
                                            {{ item.price.format() }}
                                        </div>
                                        <span v-if="!item.sells || item.stock == 0" class="grey--text text--lighten-1">품절</span>
                                        <div v-else>
                                            <span v-if="item.discountRate" class="font-weight-medium pr-4px">{{ item.discountRate }}%</span>
                                            <span>{{ item.salePrice.format() }}원</span>
                                        </div>
                                    </div>
                                </div>
                            </v-col>
                        </v-row>
                    </v-list-item-content>
                </v-list-item>
            </template>
        </v-select>
    </info-section>
</template>

<script>
import InfoSection from "@/components/client/shop/products/view/info/info-section.vue";
import TitWrapXSmall from "@/components/client/dumb/tit-wrap-x-small.vue";

export default {
    components: {
        InfoSection,
        TitWrapXSmall,
    },
    props: {
        product: { type: Object, default: () => ({}) },
    },
    computed: {
        items() {
            return this.product?.relatedGroupItems || [];
        },
    },
};
</script>

<style lang="scss" scoped></style>
