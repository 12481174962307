var render = function render(){
  var _vm$sender, _vm$sender2, _vm$sender3;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "divider-group divider-group--lg"
  }, [_c('ul', [_c('li', [_vm._v(_vm._s((_vm$sender = _vm.sender) === null || _vm$sender === void 0 ? void 0 : _vm$sender.name))]), _c('li', [_vm._v(_vm._s((_vm$sender2 = _vm.sender) === null || _vm$sender2 === void 0 ? void 0 : _vm$sender2.phone))]), _c('li', [_vm._v(_vm._s((_vm$sender3 = _vm.sender) === null || _vm$sender3 === void 0 ? void 0 : _vm$sender3.username))])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }