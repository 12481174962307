var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "max-width": "800px"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_vm._t("activator", null, null, {
          attrs,
          on
        })];
      }
    }], null, true),
    model: {
      value: _vm.dialog,
      callback: function ($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('v-card', [_c('v-card-title', [_vm._v(" " + _vm._s(_vm.title) + " "), _c('v-spacer'), _vm._v(" "), _c('v-icon', {
    on: {
      "click": function ($event) {
        _vm.dialog = false;
      }
    }
  }, [_vm._v("mdi-close")])], 1), _c('v-card-text', [_c('v-row', {
    staticClass: "mt-3"
  }, [_c('v-col', {
    staticClass: "py-0"
  }, [_c('v-select', _vm._b({
    attrs: {
      "items": _vm.categoryItems,
      "label": "카테고리"
    },
    model: {
      value: _vm.form._category,
      callback: function ($$v) {
        _vm.$set(_vm.form, "_category", $$v);
      },
      expression: "form._category"
    }
  }, 'v-select', Object.assign({}, _vm.attrs_input__console), false))], 1), _c('v-col', {
    staticClass: "py-0"
  }, [_c('v-select', _vm._b({
    attrs: {
      "items": _vm.typeItems,
      "label": "위치"
    },
    model: {
      value: _vm.form.type,
      callback: function ($$v) {
        _vm.$set(_vm.form, "type", $$v);
      },
      expression: "form.type"
    }
  }, 'v-select', Object.assign({}, _vm.attrs_input__console), false))], 1)], 1), _c('div', {
    staticClass: "caption mt-4 mb-1"
  }, [_vm._v("내용")]), _c('naver-smarteditor', {
    key: _vm.smarteditorKey,
    attrs: {
      "rows": "10"
    },
    model: {
      value: _vm.form.body,
      callback: function ($$v) {
        _vm.$set(_vm.form, "body", $$v);
      },
      expression: "form.body"
    }
  })], 1), _c('v-card-actions', [_c('v-col', {
    staticClass: "py-0"
  }, [_c('v-switch', _vm._b({
    attrs: {
      "dense": ""
    },
    model: {
      value: _vm.form.enabled,
      callback: function ($$v) {
        _vm.$set(_vm.form, "enabled", $$v);
      },
      expression: "form.enabled"
    }
  }, 'v-switch', _vm.attrs_switch__console, false), [_c('span', {
    staticClass: "subtitle-2",
    attrs: {
      "slot": "label"
    },
    slot: "label"
  }, [_vm._v(" " + _vm._s(_vm.form.enabled ? "적용" : "미적용") + " ")])])], 1), _c('v-btn', {
    attrs: {
      "text": "",
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("저장")])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }