<template>
    <v-row align="center" class="row--xs">
        <v-col cols="3" md="2"><div class="txt txt--sm txt--dark">배송비 쿠폰</div></v-col>
        <v-col cols="9" md="10">
            <cart-item-coupons-shipping
                v-model="coupons"
                v-bind="{
                    order: value,
                    carts,
                    _seller,
                    _product,
                    shippingBundleType,
                    shippingMethodCode,
                    shippingRegionCode,
                }"
                v-on="{ pullCoupon }"
                @input="emit"
                ref="cart-item-coupons"
            >
                <template #activator="{ attrs, on, selected }">
                    <v-btn v-bind="{ ...btn_secondary, ...attrs }" block v-on="on">
                        {{ attrs?.disabled ? "사용 가능한 쿠폰이 없습니다." : selected.length ? selected.map(({ name }) => name).join(", ") : "선택안함" }}
                    </v-btn>
                </template>
            </cart-item-coupons-shipping>
        </v-col>
    </v-row>
</template>

<script>
import { initOrder, btn_secondary, USAGE_TARGET_PRICES } from "@/assets/variables";
import CartItemCouponsShipping from "../carts/cart-item-coupons-shipping.vue";
export default {
    components: {
        CartItemCouponsShipping,
    },
    props: {
        value: { type: Object, default: initOrder },
        shippingGroup: { type: Object, default: () => ({}) },
    },
    data: () => ({
        btn_secondary,
        loading: false,

        coupons: [],
    }),
    computed: {
        carts() {
            return this.shippingGroup.productGroups.flatMap(({ carts }) => carts);
        },
        _seller() {
            return this.shippingGroup?._seller;
        },
        _product() {
            return this.shippingGroup?._product;
        },
        shippingBundleType() {
            return this.shippingGroup?.shippingBundleType;
        },
        shippingMethodCode() {
            return this.shippingGroup?.shippingMethodCode;
        },
        shippingRegionCode() {
            return this.shippingGroup?.shippingRegionCode;
        },
    },
    methods: {
        async init() {
            await this.$refs["cart-item-coupons"]?.init?.();
        },
        sync() {
            this.coupons = this.value.coupons.filter((coupon) => !["_seller", "_product", "shippingBundleType", "shippingMethodCode", "shippingRegionCode"].some((key) => coupon[key] != this.shippingGroup[key]) && coupon.usage.target.price == USAGE_TARGET_PRICES.SHOP_DELIVERY_FARE.value);
        },
        emit() {
            let [...coupons] = this.coupons;
            coupons = coupons.concat(this.value.coupons.filter((coupon) => ["_seller", "_product", "shippingBundleType", "shippingMethodCode", "shippingRegionCode"].some((key) => coupon[key] != this[key]) || coupon.usage.target.price != USAGE_TARGET_PRICES.SHOP_DELIVERY_FARE.value));

            this.$emit("input", initOrder({ ...this.value, coupons }));
        },
        pullCoupon(coupon) {
            this.$emit("pullCoupon", coupon);
        },
    },
    watch: {
        value() {
            this.sync();
        },
    },
    mounted() {
        this.sync();
    },
};
</script>

<style>
</style>