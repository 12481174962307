<template>
    <client-page class="app--product">
        <page-section containerSize="container--lg" class="page-section--first page-section--last" v-if="product?._id">
            <!-- S: 상품 개요 -->
            <section class="product-intro">
                <v-row class="row--sm">
                    <v-col cols="12" lg="6">
                        <product-view-imgs v-bind="{ product }" />
                    </v-col>
                    <v-col cols="12" lg="6">
                        <product-view-info v-bind="{ product }">
                            <template #form>
                                <product-view-coupons v-bind="{ product }" />
                                <!-- <product-form-related-group-items v-bind="{ product }" /> -->
                                <product-form-options v-model="carts" v-bind="{ product }" />
                                <v-row class="row--xxs">
                                    <v-col cols="12">
                                        <product-form-shipping v-model="shippingOption" v-bind="{ product }" />
                                    </v-col>
                                    <v-col cols="12">
                                        <product-form-seller v-bind="{ product }" />
                                    </v-col>
                                    <v-col cols="12">
                                        <product-form-carts v-model="carts" />
                                    </v-col>
                                </v-row>
                            </template>

                            <template #actions>
                                <product-form-price v-bind="{ carts }" />

                                <div class="btn-wrap btn-wrap--lg">
                                    <v-row class="row--xxs">
                                        <template v-if="product?.link?.enabled">
                                            <v-col cols="12">
                                                <product-view-link v-bind="{ ...btn_secondary, loading, product }" block class="v-size--xx-large" />
                                            </v-col>
                                        </template>
                                        <template v-else>
                                            <v-col cols="12">
                                                <v-btn v-bind="{ ...btn_primary, loading }" block class="v-size--xx-large mobonCart" @click="buyNow">BUY IT NOW</v-btn>
                                            </v-col>
                                            <v-col cols="12" sm="6">
                                                <v-btn v-bind="{ ...btn_secondary, loading }" block class="v-size--xx-large mobonCart" @click="addToCart">CART</v-btn>
                                            </v-col>
                                            <v-col cols="12" sm="6">
                                                <v-btn v-bind="{ ...btn_secondary, loading }" block class="v-size--xx-large mobonCart" @click="like">WISHLIST</v-btn>
                                            </v-col>
                                        </template>
                                    </v-row>
                                </div>
                            </template>

                            <template #foot>
                                <product-view-coupons v-bind="{ product }" class="d-lg-none" />
                                <!-- <div class="btn-wrap">
                                    <naver-pay disabled button-key="E8FAC592-0582-4E64-B0A7-410385FFD5AA" :carts="carts" :_product="product?._id" />
                                </div> -->
                            </template>
                        </product-view-info>
                    </v-col>
                </v-row>
            </section>
            <!-- E: 상품 개요 -->

            <!-- S: 상품 상세 -->
            <section class="product-details">
                <!-- <product-view-nav v-bind="{ product, summary }">
                    <template #form>
                        <product-form-related-group-items v-bind="{ product }" />
                        <product-form-options v-model="carts" v-bind="{ product }" />
                        <product-form-shipping v-model="shippingOption" v-bind="{ product }" />
                    </template>
                    <template #actions>
                        <product-form-carts v-model="carts" />
                        <product-form-price v-bind="{ carts }" />

                        <div class="btn-wrap">
                            <v-row class="row--sm">
                                <template v-if="product?.link?.enabled">
                                    <v-col cols="12">
                                        <product-view-link v-bind="{ ...btn_secondary, loading, product }" block class="v-size--xx-large" />
                                    </v-col>
                                </template>
                                <template v-else>
                                    <v-col cols="12" sm="6">
                                        <v-btn v-bind="{ ...btn_secondary, loading }" block class="v-size--xx-large mobonCart" @click="addToCart">장바구니</v-btn>
                                    </v-col>
                                    <v-col cols="12" sm="6">
                                        <v-btn v-bind="{ ...btn_primary, loading }" block class="v-size--xx-large mobonCart" @click="buyNow">바로구매</v-btn>
                                    </v-col>
                                </template>
                            </v-row>
                        </div>
                    </template>
                </product-view-nav> -->

                <v-tabs v-model="tab" grow>
                    <tab-primary>상세정보</tab-primary>
                    <tab-primary>리뷰({{ product?.meta?.reviews?.totalCount }})</tab-primary>
                    <tab-primary>상품 문의하기</tab-primary>
                    <tab-primary>배송/교환/환불 안내</tab-primary>
                </v-tabs>

                <v-tabs-items v-model="tab">
                    <v-tab-item>
                        <!-- 상세정보 -->
                        <product-view-content v-bind="{ product }" />

                        <!-- 출고점 정보 -->
                        <product-view-table v-bind="{ product }" />

                        <!-- 출고점의 다른 상품 -->
                        <product-view-slide v-bind="{ product }" />
                    </v-tab-item>

                    <v-tab-item eager>
                        <!-- 리뷰 -->
                        <product-view-reviews v-bind="{ product }" @setSummaryReviews="(count) => (summary.reviews = count)" />
                    </v-tab-item>

                    <v-tab-item>
                        <!-- 상품 문의하기 -->
                        <product-view-inquires :product="product" @setSummaryInquires="(count) => (summary.inquires = count)" />
                    </v-tab-item>

                    <v-tab-item>
                        <!-- 배송/교환/환불 안내 -->
                        <product-view-others />
                    </v-tab-item>
                </v-tabs-items>
            </section>
            <!-- E: 상품 상세 -->

            <!-- S: 모바일하단 고정영역 -->
            <product-view-mobile-bottom-sheet>
                <template #form>
                    <product-form-related-group-items v-bind="{ product }" />
                    <product-form-options v-model="carts" v-bind="{ product }" />
                    <product-form-shipping v-model="shippingOption" v-bind="{ product }" />
                    <product-form-carts v-model="carts" />
                </template>

                <template #actions>
                    <product-form-price v-bind="{ carts }" />
                    <div class="btn-wrap">
                        <v-row class="row--sm">
                            <template v-if="product?.link?.enabled">
                                <v-col cols="12">
                                    <product-view-link v-bind="{ ...btn_secondary, loading, product }" block class="v-size--xx-large" />
                                </v-col>
                            </template>
                            <template v-else>
                                <v-col cols="6">
                                    <v-btn v-bind="{ ...btn_secondary, loading }" block class="v-size--xx-large mobonCart" @click="addToCart">장바구니</v-btn>
                                </v-col>
                                <v-col cols="6">
                                    <v-btn v-bind="{ ...btn_primary, loading }" block class="v-size--xx-large mobonCart" @click="buyNow"><span class="white--text">바로구매</span></v-btn>
                                </v-col>
                            </template>
                        </v-row>
                    </div>
                </template>
            </product-view-mobile-bottom-sheet>
            <!-- E: 모바일하단 고정영역 -->

            <product-form-modal-cart ref="modal-cart" />
        </page-section>
        <template v-else-if="!loading">
            <div class="text-center">
                <div class="mb-18px mb-md-24px">
                    <v-icon size="60px" color="primary">mdi-alert</v-icon>
                </div>
                <div class="tit tit--sm">상품을 찾을 수 없습니다.</div>
                <div class="txt txt--sm mt-8px">다른 상품을 확인해보세요.</div>

                <div class="btn-wrap btn-wrap--lg">
                    <v-row justify="center" class="row--sm">
                        <v-col cols="6" sm="auto">
                            <v-btn v-bind="{ ...btn_primary }" to="/shop" class="v-size--xx-large w-100 min-w-sm-200px">쇼핑 계속하기</v-btn>
                        </v-col>
                    </v-row>
                </div>
            </div>
        </template>
    </client-page>
</template>

<script>
import api from "@/api";
import { mapActions, mapMutations, mapState } from "vuex";
import { btn_primary, btn_secondary } from "@/assets/variables";
import { initProduct, initShopShippingOption, PRODUCT_TYPES, COMMON_LOG_TYPES } from "@/assets/variables";

import ClientPage from "@/components/client/templates/client-page.vue";
import PageSection from "@/components/client/templates/page-section.vue";

// import NaverPay from "@/components/plugins/naver/naver-pay.vue";

import ProductViewNav from "@/components/client/shop/products/view/product-view-nav.vue";
import ProductViewImgs from "@/components/client/shop/products/view/product-view-imgs.vue";
import ProductViewInfo from "@/components/client/shop/products/view/product-view-info.vue";
import ProductViewLink from "@/components/client/shop/products/view/product-view-link.vue";
import ProductViewOthers from "@/components/client/shop/products/view/product-view-others.vue";
import ProductViewContent from "@/components/client/shop/products/view/product-view-content.vue";
import ProductViewReviews from "@/components/client/shop/products/view/product-view-reviews.vue";
import ProductViewInquires from "@/components/client/shop/products/view/product-view-inquires.vue";
import ProductViewMobileBottomSheet from "@/components/client/shop/products/view/product-view-mobile-bottom-sheet.vue";

import ProductViewTable from "@/components/client/shop/products/view/product-view-table.vue";
import ProductViewSlide from "@/components/client/shop/products/view/product-view-slide.vue";

import ProductFormCarts from "@/components/client/shop/products/form/product-form-carts.vue";
import ProductFormPrice from "@/components/client/shop/products/form/product-form-price.vue";
import ProductFormOptions from "@/components/client/shop/products/form/product-form-options.vue";
import ProductFormShipping from "@/components/client/shop/products/form/product-form-shipping.vue";
import ProductFormSeller from "@/components/client/shop/products/form/product-form-seller.vue";
import ProductFormModalCart from "@/components/client/shop/products/form/product-form-modal-cart.vue";
import ProductFormRelatedGroupItems from "@/components/client/shop/products/form/product-form-related-group-items.vue";

import ProductViewCoupons from "@/components/client/shop/products/view/product-view-coupons.vue";

import TabPrimary from "@/components/client/tab/tab-pirmary.vue";

export default {
    components: {
        ClientPage,
        PageSection,

        // NaverPay,

        ProductViewNav,
        ProductViewImgs,
        ProductViewInfo,
        ProductViewLink,
        ProductViewOthers,
        ProductViewContent,
        ProductViewReviews,
        ProductViewInquires,
        ProductViewMobileBottomSheet,

        ProductViewTable,
        ProductViewSlide,

        ProductFormCarts,
        ProductFormPrice,
        ProductFormOptions,
        ProductFormShipping,
        ProductFormSeller,
        ProductFormModalCart,
        ProductFormRelatedGroupItems,

        ProductViewCoupons,

        TabPrimary,
    },
    props: {
        _product: { type: String, default: null },
    },
    data: () => ({
        product: initProduct(),

        summary: {
            reviews: 0,
            inquires: 0,
        },

        carts: [],

        shippingOption: initShopShippingOption(),

        loading: false,

        tab: null,

        btn_primary,
        btn_secondary,
    }),
    computed: {
        ...mapState(["watches", "accessToken", "likes"]),
        seo() {
            return this.product?.seo;
        },
        query() {
            return this.$route.query;
        },
    },
    metaInfo() {
        return {
            ...this.seo,
            title: this.seo?.title || this.product?.name,
            titleTemplate: "%s | 모아고",
            meta: [...(this.seo?.meta || []), { property: "og:image", content: this.product?.thumb?.url }],
        };
    },
    beforeRouteLeave(to, from, next) {
        document.title = "모아고";
        const ogImageMetaTag = document.querySelector('meta[property="og:image"]');
        if (ogImageMetaTag) {
            ogImageMetaTag.setAttribute("content", "/images/og.png");
        }
        next();
    },
    mounted() {
        this.init();
    },
    watch: {
        _product() {
            this.init();
        },
    },
    methods: {
        ...mapActions(["addToWatch"]),
        ...mapMutations(["setWatches", "setLikes"]),
        // ...mapActions("metaPixel", ["fbq"]),
        // ...mapActions("naverWcs", ["wcs_do_cnv"]),
        // ...mapActions("enlipleTracker", ["callMtmOnProductView"]),

        async init() {
            if (this.loading) return;
            else this.loading = true;

            this.summary = { reviews: 0, inquires: 0 };
            this.carts = [];
            this.shippingOption = initShopShippingOption();

            try {
                const { product } = await api.v1.shop.products.get({ _id: this._product, headers: { ["message-on-error"]: encodeURIComponent("상품을 찾을 수 없습니다.") } });
                this.product = product;

                const isVarietyBundle = product?.type == PRODUCT_TYPES.VARIETY_BUNDLE.value;
                const isProductBundle = product?.type == PRODUCT_TYPES.PRODUCT_BUNDLE.value;
                const isOptionEnabled = product?.optionsConf?.enabled;
                const isChoiceEnabled = product?.choiceesConf?.enabled;

                if (!isVarietyBundle) {
                    if (!isOptionEnabled && !isChoiceEnabled) {
                        if (isProductBundle) {
                            this.$set(this.carts, 0, {
                                _seller: product._seller,
                                seller: product.seller,
                                _product: product._id,
                                product,
                                name: "본품",
                                price: product.price,
                                salePrice: product.salePrice,
                                discountPrice: product.discountPrice,
                                stock: product.stock__bundleds,
                                amount: 1,
                            });
                        } else {
                            this.$set(this.carts, 0, {
                                _seller: product._seller,
                                seller: product.seller,
                                _product: product._id,
                                product,
                                name: "본품",
                                price: product.price,
                                salePrice: product.salePrice,
                                discountPrice: product.discountPrice,
                                stock: product.stock,
                                amount: 1,
                            });
                        }
                    }
                }

                const _watches = (this.$store?.state?.watches || []).map(({ _id }) => _id);
                const watches = (await api.v1.me.watches.gets({ params: { _watches } }))?.watches;
                this.setWatches({ watches });

                let watch = watches.find(({ _product }) => _product == product._id);

                if (!watch) {
                    watch = (await api.v1.me.watches.post({ _product: product._id }))?.watch;
                } else {
                    watch = (await api.v1.me.watches.put({ _id: watch._id, createdAt: Date.now() }))?.watch;
                }
                this.addToWatch([watch]);

                ////////////////////////////////////////////////////////////////
                // 상품 해쉬태그 로그 저장
                ////////////////////////////////////////////////////////////////
                if (product.hashtags.length > 0) {
                    if (this.$store.state?.payload?._user !== product._seller) {
                        await Promise.all(
                            product.hashtags.map((value) => {
                                api.v1.shop.logs.saving({ value, type: COMMON_LOG_TYPES.TAG.value, _product: product._id });
                            })
                        );
                    }
                }

                // this.enlipleTracker();
            } finally {
                this.loading = false;
            }
        },

        ////////////////////////////////////////////////////////////////////////
        // 장바구니 담기
        ////////////////////////////////////////////////////////////////////////
        async addToCart() {
            if (this.loading) return;
            else this.loading = true;

            try {
                if (!this.loginValidation()) return;
                if (!this.carts.length) throw new Error("구매하실 상품을 선택해주세요");
                if (!this.shippingOption?._id) throw new Error("배송받을 방법을 선택해주세요");

                var soldOut = this.carts.find((cart) => cart.stock == 0);
                if (soldOut) throw new Error("품절된 상품입니다");

                const { shippingOption } = this;
                const { carts } = await api.v1.me.carts.post({
                    carts: this.carts,
                    shippingOption,
                });
                this.$store.dispatch("addToCart", carts);

                this.$refs["modal-cart"].open();

                // this.fbq({ type: "AddToCart" });
                // this.wcs_do_cnv({ type: "3" });
            } catch (error) {
                console.error(error);
                if (error.response) alert(error.response.data.message);
                else alert(error.message.toString());
            } finally {
                this.loading = false;
            }
        },

        ////////////////////////////////////////////////////////////////////////
        // 바로구매
        ////////////////////////////////////////////////////////////////////////
        async buyNow() {
            if (this.loading) return;
            else this.loading = true;

            try {
                if (!this.loginValidation()) return;
                if (!this.carts.length) throw new Error("구매하실 상품을 선택해주세요");
                if (!this.shippingOption?._id) throw new Error("배송받을 방법을 선택해주세요");
                var soldOut = this.carts.find((cart) => cart.stock == 0);
                if (soldOut) throw new Error("품절된 상품입니다");

                const { shippingOption } = this;
                const { carts } = await api.v1.me.carts.post(
                    {
                        carts: this.carts,
                        shippingOption,
                    },
                    { headers: { mode: "buy" } }
                );
                this.$store.dispatch("addToCart", carts);

                const _carts = carts.map(({ _id }) => _id);
                this.$store.dispatch("pickup", _carts);

                // 태그 상품 바로 구매 시, 태그 이벤트, 태그 스토리 저장
                if (["_tag", "_board"].every((query) => Object.keys(this.query).includes(query))) {
                    this.$router.push({ path: "/shop/order", query: { _tag: this.query._tag, _board: this.query._board } });
                } else {
                    this.$router.push({ path: "/shop/order" });
                }

                // this.fbq({ type: "AddToCart" });
                // this.wcs_do_cnv({ type: "3" });
            } catch (error) {
                console.error(error);
                if (error.response) alert(error.response.data.message);
                else alert(error.message.toString());
            } finally {
                this.loading = false;
            }
        },
        ////////////////////////////////////////////////////////////////////////
        //  좋아요 추가
        ////////////////////////////////////////////////////////////////////////
        async like() {
            if (this.loading) return;
            else this.loading = true;

            try {
                if (!this.loginValidation()) return;
                let like = this.likes.find((item) => item._product == this._product);
                if (like) {
                    if (confirm("이미 좋아요가 등록된 상품입니다. 취소하시겠습니까?")) {
                        await api.v1.me.likes.delete({ _id: like._id });
                        this.setLikes({ likes: this.likes.filter(({ _id }) => _id !== like._id) });
                    }
                } else {
                    const { like } = await api.v1.me.likes.post({ _product: this._product });
                    this.setLikes({ likes: [...this.likes, like] });
                    alert("좋아요가 등록되었습니다.");
                }
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.loading = false;
            }
        },

        loginValidation() {
            if (!this.accessToken) {
                alert("로그인 후 사용하실 수 있습니다");
                this.$router.push("/login");
                return false;
            }
            return true;
        },
        // enlipleTracker() {
        //     let categories = [...this.product?.category?.parents, this.product?.category].filter((item) => item);
        //     /** @type {import('@/store/enliple-tracker').EnlipleTrackerCallMtmProps & import('@/store/enliple-tracker').EnlipleTrackerProductViewProps} */
        //     let props = {
        //         convType: "etc",
        //         productCode: this.product?.code || "",
        //         productName: this.product?.name || "",
        //         price: `${this.product?.price || ""}`,
        //         dcPrice: `${this.product?.salePrice || ""}`,
        //         soldOut: this.product?.sells ? "N" : "Y",
        //         imageUrl: this.product?.thumb?.url || "",
        //         secondImageUrl: this.product?.images?.[0]?.url || "",
        //         thirdImageUrl: this.product?.images?.[1]?.url || "",
        //         fourthImageUrl: this.product?.images?.[2]?.url || "",
        //         topCategory: categories?.[0]?.name || "",
        //         firstSubCategory: categories?.[1]?.name || "",
        //         secondSubCategory: categories?.[2]?.name || "",
        //         thirdSubCategory: categories?.[3]?.name || "",
        //     };
        //     this.callMtmOnProductView(props);
        // },
    },
};
</script>

<style lang="scss" scoped>
.product-intro {
    position: relative;
    height: auto;
    &__info {
        position: sticky;
        top: 0;
    }
}
::v-deep {
    .v-slide-group__prev {
        display: none !important;
    }
}
</style>
