var render = function render(){
  var _vm$user, _vm$user$point, _vm$user$point$format;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-row', {
    staticClass: "ma-n6px",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "pa-6px",
    attrs: {
      "cols": "12",
      "lg": "auto"
    }
  }, [_c('h2', {
    staticClass: "tit--xxs mr-8px"
  }, [_vm._v("마일리지")])]), _c('v-col', {
    staticClass: "pa-6px",
    attrs: {
      "cols": "12",
      "lg": "auto"
    }
  }, [_c('v-currency-field', _vm._b({
    staticClass: "v-currency-field",
    attrs: {
      "dense": "",
      "hide-details": "",
      "type": "number"
    },
    on: {
      "input": _vm.emit
    },
    scopedSlots: _vm._u([{
      key: "append-outer",
      fn: function () {
        return [_c('v-btn', _vm._b({
          staticClass: "min-w-120px",
          attrs: {
            "tile": ""
          },
          on: {
            "click": _vm.togglePointMax
          }
        }, 'v-btn', {
          disabled: _vm.disabled
        }, false), [_vm._v("모두사용")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.point,
      callback: function ($$v) {
        _vm.point = $$v;
      },
      expression: "point"
    }
  }, 'v-currency-field', {
    max: _vm.max,
    disabled: _vm.disabled
  }, false))], 1), _c('v-col', {
    staticClass: "pa-6px txt--sm",
    attrs: {
      "cols": "12",
      "lg": "auto"
    }
  }, [_c('span', [_vm._v("사용 가능 ")]), _c('strong', [_vm._v(_vm._s(((_vm$user = _vm.user) === null || _vm$user === void 0 ? void 0 : (_vm$user$point = _vm$user.point) === null || _vm$user$point === void 0 ? void 0 : (_vm$user$point$format = _vm$user$point.format) === null || _vm$user$point$format === void 0 ? void 0 : _vm$user$point$format.call(_vm$user$point)) || 0) + "M")])])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }