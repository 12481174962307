var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-simple-table', {
    staticClass: "v-data-table--default v-data-table--simple-mobile v-data-table--no-hover",
    attrs: {
      "dense": ""
    }
  }, [_c('carts-head', {
    staticClass: "d-none d-md-table-row"
  }), _vm._l(_vm.sellerGroups, function (sellerGroup, index) {
    return [_c('carts-body', _vm._b({
      key: `body-${sellerGroup._store || index}`,
      ref: "carts-body",
      refInFor: true,
      on: {
        "input": _vm.emit
      },
      model: {
        value: _vm.order,
        callback: function ($$v) {
          _vm.order = $$v;
        },
        expression: "order"
      }
    }, 'carts-body', {
      sellerGroup,
      loading: _vm.loading
    }, false)), _c('carts-foot', _vm._b({
      key: `foot-${sellerGroup._store || index}`
    }, 'carts-foot', {
      value: _vm.value,
      sellerGroup,
      loading: _vm.loading
    }, false))];
  })], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }