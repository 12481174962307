<template>
    <v-dialog v-model="shows" v-bind="{ ...$attrs, width }">
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>

        <v-card tile>
            <v-row no-gutters justify="end">
                <v-btn large icon tile text color="primary" @click="closeDialog">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-row>

            <v-sheet class="px-12px pb-12px px-md-52px pb-md-52px">
                <slot />
            </v-sheet>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props: {
        value: { type: Boolean, default: false },
        width: { type: String, default: null },
    },
    data: () => ({
        shows: false,
    }),
    methods: {
        sync() {
            this.shows = this.value;
        },
        emit() {
            this.$emit("input", this.shows);
        },
        closeDialog() {
            this.shows = !this.shows;
            this.emit();
        },
    },
    watch: {
        value() {
            this.sync();
        },
        shows() {
            this.emit();
        },
    },
    mounted() {
        this.sync();
    },
};
</script>