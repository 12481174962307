<template>
    <result-row v-bind="{ col3Props, col5Props }">
        <template #col1>
            <product-item v-bind="{ product }" hideDivider>
                <template #content>
                    <template v-for="(purchase, index) in purchaseGroup.purchases">
                        <div class="txt txt--sm" :key="index">{{ $decode__productOptionName(purchase?.name) }} / 수량 : {{ purchase?.amount }}개</div>
                    </template>
                </template>
            </product-item>
        </template>
        <template #col2>
            <div class="text-center">{{ purchaseGroup.salePrice.format() }}원</div>
        </template>
        <template #col3>
            <div class="text-center">{{ shippingGroup?.seller?.nickname }}</div>
        </template>
        <template #col4>
            <v-sheet color="transparent" class="text-center">
                <!-- shipping name -->
                <div>
                    <div class="d-block d-md-none grey--text text--darken-4 font-weight-medium text-left mb-4px">{{ purchaseGroup?.shippingOption?.method?.name }}</div>
                    <div class="d-none d-md-block">{{ purchaseGroup?.shippingOption?.method?.name }}</div>
                </div>
                <!-- shipping price -->
                <span v-if="hasShippingPrice">
                    {{ shippingPrice ? `${shippingPrice.format()}원` : "무료배송" }}
                </span>
            </v-sheet>
        </template>
    </result-row>
</template>

<script>
import api from "@/api";
import ResultRow from "./result-row.vue";
import ProductItem from "@/components/client/mypage/product/product-item.vue";
import { SHIPPING_OPTION_CHARGE_TYPES } from "@/assets/variables";

export default {
    components: {
        ResultRow,
        ProductItem,
    },
    props: {
        shippingGroup: { type: Object, default: () => ({}) },
        purchaseGroup: { type: Object, default: () => ({}) },

        col3Props: { type: Object, default: () => ({}) },
        col5Props: { type: Object, default: () => ({}) },
    },
    data: () => ({
        product: {},
    }),
    computed: {
        hasShippingPrice() {
            return this.purchaseGroup !== SHIPPING_OPTION_CHARGE_TYPES.PAY_ON_DELIVERY.value;
        },
        shippingPrice() {
            const { deliveryPrice, 
                couponPrice__shopDeliveryFare, islandPrice, servicePrice } = this.purchaseGroup;

            return deliveryPrice + islandPrice + servicePrice - couponPrice__shopDeliveryFare;
        },
    },
    methods: {
        async init() {
            try {
                if(this.purchaseGroup?._product){
                    const { product } = await api.v1.shop.products.get({ _id: this.purchaseGroup?._product });
                    this.product = product;
                }
            } catch (error) {
                this.$handleError(error);
            }
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        purchaseGroup() {
            this.init();
        },
    },
};
</script>

<style>
</style>