<template>
    <v-dialog v-model="dialog" max-width="800px">
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>
        <v-card>
            <v-card-title> {{ title }} <v-spacer /> <v-icon @click="dialog = false">mdi-close</v-icon> </v-card-title>
            <v-card-text>
                <v-row class="mt-3">
                    <v-col class="py-0">
                        <v-select v-model="form._category" :items="categoryItems" v-bind="{ ...attrs_input__console }" label="카테고리" />
                    </v-col>
                    <v-col class="py-0">
                        <v-select v-model="form.type" :items="typeItems" v-bind="{ ...attrs_input__console }" label="위치" />
                    </v-col>
                </v-row>
                <div class="caption mt-4 mb-1">내용</div>
                <naver-smarteditor v-model="form.body" :key="smarteditorKey" rows="10"></naver-smarteditor>
            </v-card-text>
            <v-card-actions>
                <v-col class="py-0">
                    <v-switch v-model="form.enabled" dense v-bind="attrs_switch__console">
                        <span slot="label" class="subtitle-2"> {{ form.enabled ? "적용" : "미적용" }} </span>
                    </v-switch>
                </v-col>
                <v-btn text color="primary" @click="save">저장</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import api from "@/api";
import NaverSmarteditor from "@/components/plugins/naver/naver-smarteditor.vue";
import { attrs_input__console, attrs_switch__console, CATEGORY_DETAILS_TYPES } from "@/assets/variables";

export const initCategoryDetail = (form = {}) => ({
    ...form,

    _id: form?._id || null,
    _category: form?._category || null,
    _seller: form?._seller || null,

    type: form?.type || null,
    body: form?.body || "",
    enabled: form?.enabled || false,
});

const typeItems = Object.values(CATEGORY_DETAILS_TYPES);

export default {
    components: {
        NaverSmarteditor,
    },
    props: {
        _id: { type: String, default: null },
    },
    data: () => ({
        attrs_input__console,
        attrs_switch__console,
        dialog: false,
        typeItems,
        smarteditorKey: 1,

        form: initCategoryDetail(),
    }),
    computed: {
        isCreate() {
            return !this._id;
        },
        title() {
            let title = `카테고리 공통 이미지`;
            title += this.isCreate ? " 생성" : " 수정";
            return title;
        },
        categoryItems() {
            return this.$store.state.categories?.map((category) => ({ text: category.name, value: category._id }));
        },
    },
    methods: {
        async init(){
            if (this.loading) return;
            else this.loading = true;
            try {
                if (this.isCreate) {
                    this.form = initCategoryDetail();
                } else {
                    let { _id } = this;
                    this.form = initCategoryDetail((await api.console.shop.categoryDetails.get({ _id }))?.categoryDetail);
                    this.$emit("input", { ...this.form });
                }
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.loading = false;
            }
        },
        async save() {
            this.loading = true;
            try {
                if(!this.validates()) return;

                const { put, post } = api.console.shop.categoryDetails;

                await (this.isCreate ? post : put)(this.form);
                
                alert("저장되었습니다.");
                this.$emit("input");
                this.dialog = false;
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.loading = false;
            }
        },
        validates(){
            const { _category, type } = this.form;
            if(!_category) throw new Error("카테고리를 선택해주세요.");
            if(!type) throw new Error("위치를 선택해주세요.");

            return true;
        }
    },
    watch: {
        dialog() {
            if (this.dialog) {
                this.smarteditorKey += 1;
                this.init();
            }
        },
    },
};
</script>

<style>
</style>