<template>
    <tr v-bind="$attrs">
        <component v-if="$slots['col1']" :is="colTag" v-bind="{ ...colProps, ...col1Props }">
            <slot name="col1" />
        </component>
        <component v-if="$slots['col2']" :is="colTag" v-bind="{ ...colProps, ...col2Props }">
            <slot name="col2" />
        </component>
        <component v-if="$slots['col3']" :is="colTag" v-bind="{ ...colProps, ...col3Props }">
            <slot name="col3" />
        </component>
        <component v-if="$slots['col4']" :is="colTag" v-bind="{ ...colProps, ...col4Props }">
            <slot name="col4" />
        </component>
        <component v-if="$slots['col5']" :is="colTag" v-bind="{ ...colProps, ...col5Props }">
            <slot name="col5" />
        </component>
    </tr>
</template>

<script>
export default {
    props: {
        colTag: { type: String, default: "td" },
        colProps: { type: Object, default: () => ({}) },
        col1Props: { type: Object, default: () => ({}) },
        col2Props: { type: Object, default: () => ({}) },
        col3Props: { type: Object, default: () => ({}) },
        col4Props: { type: Object, default: () => ({}) },
        col5Props: { type: Object, default: () => ({}) },
    },
};
</script>

<style lang="scss" scoped>
// td:not(:last-child) {
//     border-right: thin solid rgba(0, 0, 0, 0.12);
// }
</style>