<template>
    <product-item v-bind="{ product }" hideDivider>
        <template #content>
            <v-row class="row--sm" no-gutters>
                <template v-for="(item, index) in carts">
                    <v-col :key="`item-${index}`" cols="12" :class="{ 'mt-n4px': index != 0 }">
                        <v-card tile :color="$vuetify.breakpoint.lgAndDown ? 'grey lighten-5' : 'transparent'">
                            <div class="pa-10px pa-xl-0">
                                <v-row class="row--xs">
                                    <v-col>
                                        <template v-if="item.isShippingOutdated">
                                            <v-tooltip bottom color="error" close-delay="100" max-width="200px">
                                                <template v-slot:activator="{ attrs, on }">
                                                    <v-icon small color="error" class="mr-1" v-bind="attrs" v-on="on"> mdi-alert-circle-outline </v-icon>
                                                </template>
                                                <v-card-subtitle class="py-1 px-0">[만료배송정책]</v-card-subtitle>
                                                <v-card-text class="px-0 pb-1"> 배송정책이 변경되었습니다. 주문수정 버튼을 눌러 배송정책을 변경해주세요. </v-card-text>
                                            </v-tooltip>
                                        </template>
                                        <span class="txt txt--xs">{{ formatCart(item) }}</span>
                                    </v-col>
                                </v-row>
                            </div>
                        </v-card>
                    </v-col>
                </template>
            </v-row>
        </template>
    </product-item>
</template>

<script>
import CartForm from "@/components/client/shop/cart/cart-form.vue";
import ProductItem from "@/components/client/mypage/product/product-item.vue";

export default {
    components: {
        CartForm,
        ProductItem,
    },
    props: {
        carts: { type: Array, default: () => [] },
        product: { type: Object, default: () => ({}) },
    },
    data: () => ({}),
    computed: {
        carts__supply() {
            return this.carts.filter(({ _supply }) => _supply);
        },
        carts__related() {
            return this.carts.filter(({ _related }) => _related);
        },
    },
    methods: {
        formatCart({ name, amount, price }) {
            let text = "";

            if (name) text += `${this.$decode__productOptionName(name)} `;
            if (amount) text += `/ ${amount.format()}개 `;
            if (name != "본품") {
                if (0 < price) text += `(+${(price * amount).format()}원)`;
                if (price < 0) text += `(${(price * amount).format()}원)`;
            }
            return text.trim();
        },
    },
};
</script>

<style></style>
