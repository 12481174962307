<template>
    <div>
        <tit-wrap-line noDivider title="주문서" titleClass="tit--xs" />
        <vertical-form-table v-bind="{ items }">
            <template #주문고객>
                <div class="divider-group divider-group--lg">
                    <ul>
                        <li>{{ value?.sender?.name }}</li>
                        <li>{{ value?.sender?.phone }}</li>
                        <li>{{ value?.sender?.email }}</li>
                    </ul>
                </div>
            </template>
            <template #받는분>
                {{ value?.receiver?.name }}
            </template>
            <template #주소>
                <p>{{ value?.receiver?.title }}</p>
                <p>{{ address }}</p>
                <p>{{ value?.receiver?.address2}}</p>
            </template>
            <template #휴대폰>
                <p>{{ value?.receiver?.phone }}</p>
            </template>
            <template #전화번호>
                <p>{{ value?.receiver?.tel }}</p>
            </template>
        </vertical-form-table>
    </div>
</template>

<script>
import { initOrder } from "@/assets/variables";
import VerticalFormTable from "@/components/dumb/vertical-form-table.vue";
import TitWrapLine from "@/components/client/dumb/tit-wrap-line.vue";
export default {
    components: {
        VerticalFormTable,
        TitWrapLine,
    },
    props: {
        value: { type: Object, default: initOrder }, //order
    },
    computed: {
        items() {
            return [
                { term: "주문고객" }, 
                { term: "받는분" }, 
                { term: "주소" }, 
                { term: "휴대폰" }, 
                { term: "전화번호" }
            ];
        },
        address() {
            let { receiver } = this.value;
            return `[${receiver?.postcode}]${receiver?.address1}`
        }
    },
};
</script>

<style>
</style>