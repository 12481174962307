var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('tr', _vm._b({}, 'tr', _vm.$attrs, false), [_vm.$slots['col1'] ? _c(_vm.colTag, _vm._b({
    tag: "component"
  }, 'component', Object.assign({}, _vm.colProps, _vm.col1Props), false), [_vm._t("col1")], 2) : _vm._e(), _vm.$slots['col2'] ? _c(_vm.colTag, _vm._b({
    tag: "component"
  }, 'component', Object.assign({}, _vm.colProps, _vm.col2Props), false), [_vm._t("col2")], 2) : _vm._e(), _vm.$slots['col3'] ? _c(_vm.colTag, _vm._b({
    tag: "component"
  }, 'component', Object.assign({}, _vm.colProps, _vm.col3Props), false), [_vm._t("col3")], 2) : _vm._e(), _vm.$slots['col4'] ? _c(_vm.colTag, _vm._b({
    tag: "component"
  }, 'component', Object.assign({}, _vm.colProps, _vm.col4Props), false), [_vm._t("col4")], 2) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }