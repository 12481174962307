<template>
    <div id="delivery" class="product-details__content">
        <!-- <div class="pb-20px">
            <v-row align="center" class="row--sm">
                <v-col cols="auto">
                    <h3 class="tit tit--xxs">배송/교환/반품 정보</h3>
                </v-col>
            </v-row>
        </div> -->
        <v-row class="row--sm">
            <v-col cols="12">
                <div class="tit-wrap tit-wrap--xs">
                    <div class="txt txt--dark">상품결제정보</div>
                </div>
                <div class="txt txt--sm">
                    <p>
                        고액결제의 경우 안전을 위해 카드사에서 확인전화를 드릴 수도 있습니다.<br />
                        확인과정에서 도난카드의 사용이나 타인 명의의 주문 등 정상적인 주문이 아니라고 판단될 경우 임의로 주문을 보류 또는 취소할 수 있습니다.
                    </p>
                    <p>
                        상품 구매 대금은 PC뱅킹, 인터넷뱅킹, 텔레뱅킹 혹은 가까운 은행에서 직접 입금하시면 됩니다.<br />
                        주문시 입력한 입금자명과 실제 입금자의 성명이 반드시 일치하여야 하며, 7일 이내로 입금되지 않은 주문은 자동 취소됩니다.
                    </p>
                </div>
            </v-col>
            <!-- <v-col cols="12">
                <div class="tit-wrap tit-wrap--xs">
                    <div class="txt txt--dark">배송정보</div>
                </div>
                <div class="txt txt--sm">
                    <p class="dot">배송방법 : 택배, 직접수령</p>
                    <p class="dot">배송지역 : 전국지역</p>
                    <p class="dot">배송비용 : 2,500원</p>
                    <p class="dot">배송기간 : 3일~7일</p>
                    <p class="dot">배송안내 : 도서산간지역은 별도의 추가금액을 지불하셔야 하는 경우가 있습니다. 고객님께서 주문하신 상품은 입금 확인 후 배송해드립니다. 다만, 상품종류에 따라서 상품의 배송이 다소 지연될 수 있습니다.</p>
                </div>
            </v-col> -->
            <v-col cols="12">
                <div class="tit-wrap tit-wrap--xs">
                    <div class="txt txt--dark">교환 및 반품정보</div>
                </div>
                <div class="txt txt--sm tit-wrap tit-wrap--xs">
                    <p class="txt--dark">교환 및 반품이 가능한 경우</p>

                    <p class="dot">상품을 공급 받으신 날로부터 7일이내 단, 포장을 개봉하였거나 포장이 훼손되어 상품 가치가 상실된 경우에는 교환/반품이 불가능합니다.</p>
                    <p class="dot">공급받으신 상품의 내용이 표시, 광고 내용과 다르거나 다르게 이행된 경우에는 공급받은 날로부터 3월이내, 그 사실을 알게된 날로부터 30일이내</p>
                </div>

                <div class="txt txt--sm">
                    <p class="txt--dark">교환 및 반품이 불가능한 경우</p>

                    <p class="dot">고객님의 책임 있는 사유로 상품등이 멸실 또는 훼손된 경우 단, 상품의 내용을 확인하기 위하여 포장 등을 훼손한 경우는 제외</p>
                    <p class="dot">포장이 훼손되어 상품가치가 상실된 경우</p>
                    <p class="dot">고객님의 사용 또는 일부 소비에 의하여 상품의 가치가 현저히 감소한 경우</p>
                    <p class="dot">시간의 경과에 의하여 재판매가 곤란할 정도로 상품가치가 현저히 감소한 경우</p>
                    <p>
                        (자세한 내용은 CScenter를 이용해 주시기 바랍니다.)<br />
                        ※ 고객님의 마음이 바뀌어 교환, 반품을 하실 경우 상품반송 비용은 고객님께서 부담하셔야 합니다.(색상 교환, 사이즈 교환 등)
                    </p>
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import TitWrapXSmall from "@/components/client/dumb/tit-wrap-x-small.vue";

export default {
    components: {
        TitWrapXSmall,
    },
};
</script>

<style>
</style>