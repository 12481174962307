<template>
    <cart-page tit="결제완료">
        <template v-if="success">
            <result-view v-bind="{ value: order }" />
            <result-info v-bind="{ value: order }" />
            <v-row justify="center" class="row--xxs">
                <v-col cols="6" lg="auto">
                    <v-btn width="328" max-width="100%" color="primary" tile to="/mypage/orders" class="v-size--xx-large">주문내역</v-btn>
                </v-col>
                <v-col cols="6" lg="auto">
                    <v-btn width="328" max-width="100%" outlined tile to="/shop/products" class="v-size--xx-large">쇼핑계속하기</v-btn>
                </v-col>
            </v-row>
        </template>
        <template v-else>
            <div class="text-center">
                <div class="mb-18px mb-md-24px">
                    <v-icon size="60px" color="primary">mdi-alert</v-icon>
                </div>
                <div class="tit">주문/결제에 실패하였습니다.</div>
                <div class="txt txt--sm txt--dark mt-8px">[{{ resCode }}] {{ resMessage }}</div>
                <v-btn v-bind="{ ...btn_primary }" to="/" class="v-size--xx-large w-20">확인</v-btn>
            </div>
        </template>
    </cart-page>
</template>

<script>
import api from "@/api";
import { btn_primary } from "@/assets/variables";
import CartPage from "@/components/client/templates/cart-page.vue";
import ResultView from "@/components/client/shop/order/results/result-view.vue";
import ResultSlide from "@/components/client/shop/order/results/result-slide.vue";
import ResultInfo from "@/components/client/shop/order/results/result-info.vue";
export default {
    components: {
        CartPage,
        ResultView,
        ResultSlide,
        ResultInfo,
    },
    data: () => ({
        btn_primary,
        order: null,
        loading: false,
    }),
    computed: {
        resCode() {
            return this.$route.query.resCode || "3001";
        },
        resMessage() {
            return this.$route.query.resMessage;
        },
        success() {
            return ["0000", "3001"].includes(this.resCode);
        },
    },
    methods: {
        async init() {
            if (this.loading) return;
            else this.loading = true;

            try {
                const { order } = await api.v1.me.orders.get({ _id: this.$route.query._order });
                this.order = order;

                if (order?.paymentMethod == "account") {
                    const { setting } = await api.v1.setting.get();
                    this.setting = setting;
                }
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.loading = false;
            }
        },
    },
    mounted() {
        this.init();
    },
};
</script>

<style>
</style>