<template id="modal-template">
    <u-dialog v-model="shows" name="modal" title="장바구니" max-width="400">
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>

        <div class="py-10px">
            <div class="txt txt--sm txt--dark text-center">선택하신 상품을 장바구니에 담았습니다.</div>
        </div>

        <div class="btn-wrap">
            <v-row class="row--sm">
                <v-col>
                    <v-btn v-bind="{ ...btn_secondary }" block class="v-size--xx-large" @click="close">쇼핑계속하기</v-btn>
                </v-col>
                <v-col>
                    <v-btn v-bind="{ ...btn_primary }" block to="/cart" class="v-size--xx-large">장바구니 가기</v-btn>
                </v-col>
            </v-row>
        </div>
    </u-dialog>
</template>

<script>
import { btn_primary, btn_secondary } from "@/assets/variables";

import UDialog from "@/components/client/dumb/u-dialog.vue";

export default {
    components: {
        UDialog,
    },
    data: () => ({
        shows: false,

        btn_primary,
        btn_secondary,
    }),
    methods: {
        open() {
            this.shows = true;
        },
        close() {
            this.shows = false;
        },
    },
};
</script>
