<template>
    <span v-if="hasShippingPrice">
        {{ totalShippingPrice ? `${totalShippingPrice?.format?.()}원` : "무료배송" }}
    </span>
</template>

<script>
import { mapGetters, mapState } from "vuex";

import { initOrder } from "@/assets/variables/inits";
import { SHIPPING_OPTION_CHARGE_TYPES, USAGE_TARGET_PRICES } from "@/assets/variables/constants";

export default {
    props: {
        value: { type: Object, default: initOrder },
        carts: { type: Array, default: () => [] },
        shippingGroup: { type: Object, default: () => ({}) },
    },
    computed: {
        ...mapState(["islands"]),
        ...mapGetters(["userLevel"]),

        deliveryPrice() {
            return this.$getDeliveryPrice(this.carts, this.value?.receiver?.geolocation, this.userLevel);
        },
        servicePrice() {
            return this.$getServicePrice(this.carts);
        },
        islandPrice() {
            return this.$getIslandPrice(this.carts, this.islands, this.value?.receiver?.postcode);
        },
        shippingCouponPrice() {
            const coupons = this.shippingGroup.coupons.filter((coupon) => coupon?.usage?.target?.price == USAGE_TARGET_PRICES.SHOP_DELIVERY_FARE.value);
            return this.$getCouponPrice(this.carts, coupons, this.value?.receiver?.geolocation, this.userLevel);
        },
        totalShippingPrice() {
            return this.deliveryPrice + this.servicePrice + this.islandPrice - this.shippingCouponPrice;
        },

        // 상태 데이터
        hasShippingPrice() {
            return this.shippingGroup.productGroups.every((productGroup) => productGroup.shippingChargeType != SHIPPING_OPTION_CHARGE_TYPES.PAY_ON_DELIVERY.value);
        },
    },
};
</script>

<style>
</style>