<template>
    <v-sheet class="product-details__content">
        <div class="tit-wrap tit-wrap--xs">
            <h3 class="tit tit--xxs">출고점 정보</h3>
        </div>
        <v-simple-table class="v-data-table--no-hover">
            <template #default>
                <tbody>
                    <template v-for="item in items">
                        <tr :key="item.th">
                            <th>{{ item.th }}</th>
                            <td>
                                <span class="primary--text">{{ item.td }}</span>
                            </td>
                        </tr>
                    </template>
                </tbody>
            </template>
        </v-simple-table>
    </v-sheet>
</template>

<script>
export default {
    props:{
        product: { type: Object, default: ()=> ({}) }
    },
    computed: {
        items() {
            return [
                // { th: "상호명", td: this.value.companyName },
                // { th: "사업자등록번호", td: this.value.registrationNumber },
                // { th: "통신판매업번호", td: this.value.licenseCode },
                // { th: "대표자", td: this.value.ceoName },
                // { th: "사업장소재지", td: `${this.value.address1} ${this.value.address2}` },
                // { th: "사업장번호", td: this.value?.tel },
                // { th: "e-mail", td: this.value.email },

                { th: "상호명", td: this.product?.seller?.companyName },
                { th: "사업자등록번호", td: this.product?.seller?.registrationNumber },
                { th: "통신판매업번호", td: this.product?.seller?.licenseCode },
                { th: "대표자", td: this.product?.seller?.ceoName },
                { th: "사업장소재지", td: `${this.product?.seller?.address1} ${this.product.seller?.address2}` },
                { th: "사업장번호", td: this.product?.seller?.tel },
            ];
        },
    }
};
</script>

<style lang="scss" scoped>
.v-data-table {
    border-top: 2px solid var(--v-primary-base);
    tr {
        border-bottom: 1px solid var(--border-color);
        > th {
            width: 25%;
            border-right: 1px solid var(--border-color);
        }
    }
}
@media (min-width: 1024px) {
    .v-data-table {
        tr {
            > th {
                width: 18%;
            }
        }
    }
}
</style>