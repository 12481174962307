import { render, staticRenderFns } from "./product-view-content.vue?vue&type=template&id=3c0a6710&scoped=true&"
import script from "./product-view-content.vue?vue&type=script&lang=js&"
export * from "./product-view-content.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3c0a6710",
  null
  
)

export default component.exports