<template>
    <v-tabs v-model="query.sort" height="auto" hide-slider @change="emit">
        <v-tab v-for="item in items" :key="item.text" :tab-value="item.value">
            {{ item.text }}
        </v-tab>
    </v-tabs>
</template>

<script>
import TabTertiary from "@/components/client/tab/tab-tertiary.vue";
export default {
    components: {
        TabTertiary,
    },
    data: () => ({
        query: { sort: "monthly" },
        items: [
            { text: "월간", value: "monthly" },
            { text: "주간", value: "weekly" },
        ],
    }),
    methods: {
        sync() {
            this.query = { ...this.$route.query, sort: this.$route.query.sort || "monthly" };
        },
        emit() {
            const { ...query } = this.query;
            delete query.page;
            if (query.sort == "monthly") delete query.sort;
            this.$router.push({ query });
        },
    },
    watch: {
        "$route.query"() {
            this.sync();
        },
    },
    mounted() {
        this.sync();
    },
};
</script>

<style lang="scss" scoped>
.v-tab {
    height: fit-content;
    border-right: 1px solid var(--border-color);
    font-size: var(--txt-font-size-sm);
    &::before,
    &::after {
        display: none;
    }
    &:first-child {
        padding-left: 0;
    }
    &:last-child {
        padding-right: 0;
        border-right: none;
    }
    &--active {
        font-weight: 700;
    }
}
</style>