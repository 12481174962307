var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-sheet', {
    staticClass: "product-details__content"
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--xs"
  }, [_c('h3', {
    staticClass: "tit tit--xxs"
  }, [_vm._v("출고점의 다른 상품")])]), _c('v-divider', {
    staticClass: "mb-30px",
    staticStyle: {
      "border-width": "2px 0 0"
    },
    attrs: {
      "dark": ""
    }
  }), _c('swiper', {
    staticClass: "swiper",
    attrs: {
      "options": _vm.swiperOptions
    }
  }, [_vm._l(_vm.products, function (product, index) {
    var _product$thumb;
    return [_c('swiper-slide', {
      key: index,
      staticClass: "product-item",
      attrs: {
        "value": product
      }
    }, [_c('router-link', {
      staticClass: "product-item__inner",
      attrs: {
        "to": `/shop/products/${product._id}`
      }
    }, [_c('v-card', {
      staticClass: "product-item__img",
      attrs: {
        "img": product === null || product === void 0 ? void 0 : (_product$thumb = product.thumb) === null || _product$thumb === void 0 ? void 0 : _product$thumb.url
      }
    }), _c('div', {
      staticClass: "product-item__con"
    }, [_c('div', {
      staticClass: "txt txt--sm mb-4px"
    }, [_vm._v(_vm._s(product.companyName))]), _c('div', {
      staticClass: "product-item__tit h-auto"
    }, [_vm._v(_vm._s(product.name))]), _c('div', {
      staticClass: "my-6px my-md-8px"
    }, [_c('div', {
      staticClass: "d-flex flex-wrap align-center font-weight-bold txt"
    }, [_c('span', {
      staticClass: "accent1--text"
    }, [_vm._v(_vm._s(product.salePrice.format()) + "원")]), product.price ? _c('span', {
      staticClass: "txt--sm font-weight-regular text-decoration-line-through mx-4px"
    }, [_vm._v(_vm._s(product.price.format()) + "원")]) : _vm._e(), product.discountRate ? _c('span', {
      staticClass: "accent2--text"
    }, [_vm._v(_vm._s(product.discountRate) + "%")]) : _vm._e()])])])], 1)], 1)];
  }), _c('div', {
    staticClass: "swiper-control",
    attrs: {
      "slot": "pagination"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
      }
    },
    slot: "pagination"
  }, [_c('div', {
    staticClass: "swiper-pagination"
  }), _c('div', {
    staticClass: "swiper-button-prev"
  }, [_c('v-icon', [_vm._v("mdi-chevron-left")])], 1), _c('div', {
    staticClass: "swiper-button-next"
  }, [_c('v-icon', [_vm._v("mdi-chevron-right")])], 1)])], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }