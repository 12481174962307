var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    staticClass: "row--xxs",
    attrs: {
      "justify": "space-between"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "d-flex align-center h-100"
  }, [_c('v-btn', {
    staticClass: "v-size--xx-small",
    attrs: {
      "disabled": _vm.page === 1,
      "icon": ""
    },
    on: {
      "click": function ($event) {
        return _vm.go('prev');
      }
    }
  }, [_c('v-icon', {
    staticClass: "font-size-24 white--text"
  }, [_vm._v("mdi-chevron-left")])], 1)], 1)]), _vm._l(_vm.images, function (image, index) {
    var _image$_images;
    return _c('v-col', {
      key: index,
      attrs: {
        "cols": "4",
        "lg": "2"
      }
    }, [_c('review-images-dialog', _vm._b({
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function (_ref) {
          var attrs = _ref.attrs,
            on = _ref.on;
          return [_c('v-card', _vm._g(_vm._b({
            staticClass: "review-thumb",
            attrs: {
              "tile": ""
            }
          }, 'v-card', attrs, false), on), [_c('v-img', {
            attrs: {
              "aspect-ratio": 1 / 1,
              "src": image === null || image === void 0 ? void 0 : image.src
            }
          })], 1)];
        }
      }], null, true)
    }, 'review-images-dialog', {
      _product: image === null || image === void 0 ? void 0 : image._product,
      _review: image === null || image === void 0 ? void 0 : image._review,
      _image: image === null || image === void 0 ? void 0 : (_image$_images = image._images) === null || _image$_images === void 0 ? void 0 : _image$_images[0]
    }, false))], 1);
  }), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "d-flex align-center h-100"
  }, [_c('v-btn', {
    staticClass: "v-size--xx-small",
    attrs: {
      "disabled": _vm.page === _vm.pageCount,
      "icon": ""
    },
    on: {
      "click": function ($event) {
        return _vm.go('next');
      }
    }
  }, [_c('v-icon', {
    staticClass: "font-size-24 white--text"
  }, [_vm._v("mdi-chevron-right")])], 1)], 1)])], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }