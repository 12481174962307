var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('tbody', [_vm._l(_vm.sellerGroup.shippingGroups, function (shippingGroup, i) {
    return [_vm._l(shippingGroup.productGroups, function (productGroup, j) {
      return [_c('body-item', _vm._b({
        key: productGroup._id,
        ref: "body-item",
        refInFor: true,
        on: {
          "input": _vm.emit
        },
        model: {
          value: _vm.order,
          callback: function ($$v) {
            _vm.order = $$v;
          },
          expression: "order"
        }
      }, 'body-item', {
        seller: _vm.sellerGroup.seller,
        // seller: i == 0 && j == 0 ? sellerGroup.seller : null,
        shippingGroup: j == 0 ? shippingGroup : null,
        productGroup,
        // col2Props: j == 0 ? col2Props : null,

        col5Props: {
          rowspan: i == 0 ? shippingGroup.productGroups.length : null
        }
      }, false))];
    })];
  })], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }