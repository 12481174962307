var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-tabs', {
    attrs: {
      "grow": ""
    },
    on: {
      "change": _vm.emit
    },
    model: {
      value: _vm.query['category'],
      callback: function ($$v) {
        _vm.$set(_vm.query, 'category', $$v);
      },
      expression: "query['category']"
    }
  }, _vm._l(_vm.tabItems, function (item, index) {
    return _c('tab-primary', {
      key: index,
      attrs: {
        "exact": "",
        "tab-value": item.value
      }
    }, [_vm._v(" " + _vm._s(item.text) + " ")]);
  }), 1);

}
var staticRenderFns = []

export { render, staticRenderFns }