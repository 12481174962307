var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-sheet', {
    staticClass: "text-center d-flex justify-space-between justify-md-center",
    attrs: {
      "color": "transparent"
    }
  }, [_c('div', {
    staticClass: "d-md-none grey--text text--darken-4 font-weight-medium text-left mb-4px"
  }, [_vm._v("상품금액(할인포함)")]), _c('v-row', {
    attrs: {
      "no-gutters": "",
      "align": "center",
      "justify": "end"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto",
      "md": "12",
      "order-md": "2"
    }
  }, [_c('strong', {
    staticClass: "d-inline-flex align-center font-size-18 font-size-md-20 grey--text text--darken-4"
  }, [_vm._v(" " + _vm._s(_vm.totalPrice.format())), _c('small', {
    staticClass: "font-size-14 font-weight-regular pl-2px"
  }, [_vm._v("원")])])]), _vm.productPrice != _vm.totalPrice ? _c('v-col', {
    attrs: {
      "cols": "auto",
      "md": "12",
      "order-md": "1"
    }
  }, [_c('span', {
    staticClass: "d-md-block font-size-14 text-decoration-line-through grey--text text--lighten-1 pl-8px pl-md-0"
  }, [_vm._v(_vm._s(_vm.productPrice.format()) + "원")])]) : _vm._e()], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }