<template>
    <v-row justify="space-between" class="row--xxs">
        <v-col cols="auto">
            <div class="d-flex align-center h-100">
                <v-btn :disabled="page === 1" icon class="v-size--xx-small" @click="go('prev')">
                    <v-icon class="font-size-24 white--text">mdi-chevron-left</v-icon>
                </v-btn>
            </div>
        </v-col>
        <v-col v-for="(image, index) in images" :key="index" cols="4" lg="2">
            <review-images-dialog v-bind="{ _product: image?._product, _review: image?._review, _image: image?._images?.[0] }">
                <template #activator="{ attrs, on }">
                    <v-card tile class="review-thumb" v-bind="attrs" v-on="on">
                        <v-img :aspect-ratio="1 / 1" :src="image?.src" />
                    </v-card>
                </template>
            </review-images-dialog>
        </v-col>
        <v-col cols="auto">
            <div class="d-flex align-center h-100">
                <v-btn :disabled="page === pageCount" icon class="v-size--xx-small" @click="go('next')">
                    <v-icon class="font-size-24 white--text">mdi-chevron-right</v-icon>
                </v-btn>
            </div>
        </v-col>
    </v-row>
</template>

<script>
import api from "@/api";
import ReviewImagesDialog from "../review-images/dialog/review-images-dialog.vue";

export default {
    components: {
        ReviewImagesDialog,
    },
    data: () => ({
        images: [],

        page: 1,
        limit: 6,
        summary: { totalCount: 0 },

        loading: false,
        shows: false,
    }),
    computed: {
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
    },
    methods: {
        async search() {
            if (this.loading) return;
            else this.loading = true;

            try {
                let { skip, limit } = this;
                var { summary, images } = await api.v1.shop.reviewImages.gets({
                    headers: { skip, limit },
                    params: { _product: this.$route.params._product },
                });

                this.summary = summary;
                this.images = images;
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.loading = false;
            }
        },
        go(type) {
            if (type === "prev") this.page--;
            if (type === "next") this.page++;
        },
    },
    mounted() {
        this.search();
    },
    watch: {
        page() {
            this.search();
        },
    },
};
</script>

<style lang="scss" scoped>
.review-thumb {
    position: relative;
    &__img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center;
    }
}
</style>