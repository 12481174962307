<template>
    <v-simple-table dense class="v-data-table--default v-data-table--simple-mobile v-data-table--no-hover">
        <carts-head class="d-none d-md-table-row" />
        <template v-for="(sellerGroup, index) in sellerGroups">
            <carts-body
                v-model="order"
                v-bind="{
                    sellerGroup,
                    loading,
                }"
                @input="emit"
                :key="`body-${sellerGroup._store || index}`"
                ref="carts-body"
            />
            <carts-foot
                v-bind="{
                    value,
                    sellerGroup,
                    loading,
                }"
                :key="`foot-${sellerGroup._store || index}`"
            />
        </template>
    </v-simple-table>
</template>

<script>
import { initOrder } from "@/assets/variables";
import { mapGetters, mapState } from "vuex";

import CartsBody from "./carts/carts-body.vue";
import CartsFoot from "./carts/carts-foot.vue";
import CartsHead from "./carts/carts-head.vue";

export default {
    components: {
        CartsBody,
        CartsFoot,
        CartsHead,
    },
    props: {
        value: { type: Object, default: initOrder },
        carts: { type: Array, default: () => [] },
    },
    data: () => ({
        order: initOrder(),
        sellerGroups: [],

        loading: false,
    }),
    computed: {
        ...mapGetters(["userLevel"]),
        ...mapState(["islands", "setting"]),
    },
    methods: {
        sync() {
            this.order = initOrder(this.value);
            this.sellerGroups = this.$getCartSellerGroups(this.carts, this.order.coupons);
        },
        async emit() {
            if (this.loading) return;
            else this.loading = true;

            try {
                await Promise.all([this.$refs["carts-body"]].flat().map(async (child) => await child.init()));
            } finally {
                this.$nextTick(() => {
                    let { coupons } = this.order;
                    this.$emit("input", { ...this.value, coupons });
                    this.loading = false;
                });
            }
        },
    },
    watch: {
        value() {
            this.sync();
        },
        carts() {
            this.sync();
        }
    },
    mounted() {
        this.sync();
    },
};
</script>
