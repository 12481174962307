<template>
  <v-row class="mt-50px">
    <v-col cols="12" lg="9">
        <result-order v-bind="{ value }"/>
        <result-purchase v-bind="{ value }"/>
    </v-col>
    <v-col cols="12" lg="3">
        <result-total v-bind="{ value }"/>
    </v-col>
  </v-row>
</template>

<script>
import { initOrder } from '@/assets/variables';
import ResultTotal from "./result-total.vue";
import ResultOrder from "./result-order.vue";
import ResultPurchase from "./result-purchase.vue"

export default {
    components: {
        ResultTotal,
        ResultOrder,
        ResultPurchase
    },
    props: {
        value: { type: Object, default: initOrder }, //order
    }

}
</script>

<style>

</style>