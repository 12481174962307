<template>
    <v-sheet color="transparent" class="text-center d-flex justify-space-between d-md-block">
        <shipping-code v-bind="{ shippingGroup }" />

        <shipping-price v-bind="{ value, carts, shippingGroup }" />

        <shipping-disabled v-bind="{ value, carts, shippingGroup }" />

        <shipping-messages v-bind="{ shippingGroup }" />
        <!-- <div>
            <cart-item-coupons-shipping
                v-model="coupons"
                v-bind="{
                    order: value,
                    carts,
                    _seller,
                    _product,
                    shippingBundleType,
                    shippingMethodCode,
                    shippingRegionCode,
                }"
                @input="emit"
                ref="cart-item-coupons"
            >
                <template #activator="{ attrs, isActive, on }">
                    <v-btn v-bind="{ ...btn_secondary, ...attrs, outlined: !isActive }" v-on="on" x-small class="mt-2">
                        <template v-if="isActive"> 쿠폰 적용완료 </template>
                        <template v-else> 쿠폰 사용하기 </template>
                    </v-btn>
                </template>
            </cart-item-coupons-shipping>
        </div> -->
    </v-sheet>
</template>

<script>
import { btn_secondary } from "@/assets/variables/attrs";
import { initOrder } from "@/assets/variables/inits";
import { USAGE_TARGET_PRICES } from "@/assets/variables/constants";

import ShippingCode from "./shipping/shipping-code.vue";
import ShippingDisabled from "./shipping/shipping-disabled.vue";
import ShippingMessages from "./shipping/shipping-messages.vue";
import ShippingPrice from "./shipping/shipping-price.vue";
import CartItemCouponsShipping from "@/components/client/shop/order/carts/cart-item-coupons-shipping.vue";

export default {
    components: {
        ShippingCode,
        ShippingDisabled,
        ShippingMessages,
        ShippingPrice,
        CartItemCouponsShipping,
    },
    props: {
        value: { type: Object, default: initOrder },
        shippingGroup: { type: Object, default: () => ({}) },
    },
    data: () => ({
        coupons: [],

        btn_secondary,
    }),
    computed: {
        carts() {
            return this.shippingGroup.productGroups.flatMap(({ carts }) => carts);
        },
        _seller() {
            return this.shippingGroup?._seller;
        },
        _product() {
            return this.shippingGroup?._product;
        },
        shippingBundleType() {
            return this.shippingGroup?.shippingBundleType;
        },
        shippingMethodCode() {
            return this.shippingGroup?.shippingMethodCode;
        },
        shippingRegionCode() {
            return this.shippingGroup?.shippingRegionCode;
        },
    },
    methods: {
        async init() {
            await this.$refs["cart-item-coupons"]?.init?.();
        },
        sync() {
            this.coupons = this.value.coupons.filter((coupon) => !["_seller", "_product", "shippingBundleType", "shippingMethodCode", "shippingRegionCode"].some((key) => coupon[key] != this.shippingGroup[key]) && coupon.usage.target.price == USAGE_TARGET_PRICES.SHOP_DELIVERY_FARE.value);
        },
        emit() {
            let [...coupons] = this.coupons;
            coupons = coupons.concat(this.value.coupons.filter((coupon) => ["_seller", "_product", "shippingBundleType", "shippingMethodCode", "shippingRegionCode"].some((key) => coupon[key] != this[key]) || coupon.usage.target.price != USAGE_TARGET_PRICES.SHOP_DELIVERY_FARE.value));

            this.$emit("input", initOrder({ ...this.value, coupons }));
        },
    },
    watch: {
        value() {
            this.sync();
        },
    },
    mounted() {
        this.sync();
    },
};
</script>

<style>
</style>