<template>
    <div>
        <template v-if="detect == 'pc'">
            <v-breadcrumbs :items="breadcrumbsItems" class="d-none d-lg-flex">
                <template #divider>
                    <v-icon small>mdi-chevron-right</v-icon>
                </template>
                <template #item="{ item }">
                    <template v-if="item.text == 'HOME'">
                        <v-breadcrumbs-item :to="item.to" exact>
                            {{ item.text }}
                        </v-breadcrumbs-item>
                    </template>
                    <template v-else-if="item.text == 'Category'">
                        <v-breadcrumbs-item :to="item.to" exact>
                            {{ item.text }}
                        </v-breadcrumbs-item>
                    </template>
                    <template v-else>
                        <v-breadcrumbs-item :to="item.to" exact>
                            {{ item.text }}
                        </v-breadcrumbs-item>
                    </template>
                </template>
            </v-breadcrumbs>
        </template>

        <template v-if="detect == 'mobile'">
            <v-breadcrumbs :items="breadcrumbsItems" class="d-lg-none">
                <template #divider>
                    <v-icon small>mdi-chevron-right</v-icon>
                </template>
                <template #item="{ item }">
                    <template v-if="item.text == 'HOME'">
                        <v-breadcrumbs-item to="/" exact>
                            <v-icon class="font-size-20">mdi-home-outline</v-icon>
                        </v-breadcrumbs-item>
                    </template>
                    <template v-else-if="item.text == 'Category'">
                        <v-breadcrumbs-item to="/shop/products" exact>
                            {{ item.text }}
                        </v-breadcrumbs-item>
                    </template>
                    <template v-else>
                        <v-breadcrumbs-item @click="siblingMenu !== item.sibling ? (siblingMenu = item.sibling) : (siblingMenu = undefined)">
                            {{ item.text }}
                        </v-breadcrumbs-item>
                    </template>
                </template>
            </v-breadcrumbs>
            <template v-for="(item, index) in breadcrumbsItems">
                <v-expand-transition v-if="item.sibling" :key="item.text">
                    <v-list v-show="siblingMenu === item.sibling" class="breadcrumbs-list">
                        <template v-if="1 < index">
                            <v-list-item v-if="getIsActive(item)" exact :to="{ ...breadcrumbsItems[index - 1]?.value, query: { ...$route.query, ...(breadcrumbsItems[index - 1]?.value?.query || {}) } }" @click.prevent="((siblingMenu = undefined) || true) && $router.push({ query: breadcrumbsItems[index - 1]?.value?.query || {} })"> 전체보기 </v-list-item>
                            <v-list-item v-else exact :to="{ ...item.value, query: { ...$route.query, ...(item?.value?.query || {}) } }" @click.prevent="((siblingMenu = undefined) || true) && $router.push({ query: item?.value?.query || {} })"> 전체보기 </v-list-item>
                        </template>

                        <template v-for="item in item.sibling">
                            <v-list-item exact :to="{ ...(item?.value || {}), query: { ...$route.query, ...(item?.value?.query || {}) } }" :key="item._id" @click.prevent="((siblingMenu = undefined) || true) && $router.push({ ...(item?.value || {}), query: item?.value?.query || {} })">
                                {{ item.text }}
                            </v-list-item>
                        </template>
                    </v-list>
                </v-expand-transition>
            </template>
        </template>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import detect from "@/plugins/detect";

export default {
    data: () => ({
        detect,
        siblingMenu: undefined,
    }),
    computed: {
        ...mapGetters("ui", ["gnbs"]),
        breadcrumbsItems() {
            /** @type {import("@/store/ui").GnbItem[]} */
            const gnbs = this.gnbs;

            const breadcrumbsItems = function recursiveFinder(/** @type {array} */ breadcrumbsItems, /** @type {import("@/store/ui").GnbItem[]} */ items) {
                for (const item of items) {
                    if (!this.getIsActive(item)) continue;

                    breadcrumbsItems.push({
                        ...item,
                        sibling: items,
                    });

                    if ((item.children || []).length < 1) return breadcrumbsItems;

                    return recursiveFinder.bind(this)(breadcrumbsItems, item.children);
                }
                breadcrumbsItems.push({
                    text: "전체보기",
                    value: breadcrumbsItems.at(-1)?.value,
                    sibling: items,
                });
                return breadcrumbsItems;
            }.bind(this)([{ text: "HOME", to: "/" }], gnbs);

            return breadcrumbsItems;
        },
    },
    methods: {
        getIsActive(item) {
            const samePath = this.$route.path.includes(item.value.path);
            const sameQuery = !Object.keys(item?.value?.query || {}).some((key) => this.$route.query[key] != item?.value?.query?.[key]);
            return samePath && sameQuery;
        },
    },
};
</script>

<style lang="scss" scoped>
</style>