<template>
    <div>
        <section class="sub-visual d-none d-lg-flex">
            <div class="sub-visual__bg d-none d-md-block" :style="`background-image: url(${bg})`" />
            <div class="sub-visual__bg d-md-none" :style="`background-image: url(${mobileBg})`" v-if="$slots['mobileBg']" />

            <h2 class="tit white--text" :class="titClass">{{ tit }}</h2>

            <v-container class="container--lg sub-visual__nav" v-if="$slots['tab']">
                <slot name="tab" />
            </v-container>
        </section>
        <v-container class="container--lg" v-if="$slots['path']">
            <slot name="path" />
        </v-container>
    </div>
</template>

<script>
export default {
    props: {
        tit: { type: String, default: "" },
        titClass: { type: String, default: "" },
        bg: { type: String, default: "" },
        mobileBg: { type: String, default: "" },
    },
};
</script>

<style lang="scss" scoped>
.sub-visual {
    height: 360px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    &__bg {
        background: center no-repeat;
        background-size: cover;
        width: calc(100% + 2px);
        height: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: -1;
    }
    &__nav {
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translateX(-50%);
    }
}
.tit {
    margin-bottom: var(--tabs-height);
}
::v-deep() {
    .v-breadcrumbs {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
}
@media (min-width: 1200px) {
    .sub-visual {
        height: 540px;
    }
    ::v-deep() {
        .v-breadcrumbs {
            justify-content: flex-end;
        }
    }
}
</style>
