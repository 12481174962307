<template>
    <v-row align="center" class="row--xxs">
        <v-col cols="auto">
            <div class="txt txt--sm txt--dark font-weight-bold">출고점</div>
        </v-col>
        <v-col cols="auto">
            <!-- <mini-seller-info :value="seller">
                <template #activator="{ attrs, on }">
                    <v-btn v-bind="{ ...btn_primary, attrs }" v-on="on" class="v-size--xx-small">
                        {{ seller.nickname }}
                    </v-btn>
                </template>
            </mini-seller-info> -->
            <router-link :to="{ path: `/minishop/${seller?._id}` }" class="d-inline-flex align-center txt txt--sm grey--text">
                {{ seller.nickname }}
                <v-img width="16" height="16" src="/images/icon/icon-home-grey.svg" class="ml-4px" />
            </router-link>
        </v-col>
    </v-row>
</template>

<script>
import api from "@/api";
import TitWrapXSmall from "@/components/client/dumb/tit-wrap-x-small.vue";
import MiniSellerInfo from "@/components/client/miniShop/dialog/mini-seller-info.vue";
import { initProduct } from "@/assets/variables";
import { btn_primary } from "@/assets/variables/attrs";

export default {
    components: {
        MiniSellerInfo,
        TitWrapXSmall,
    },
    props: {
        product: { type: Object, default: initProduct },
    },
    data: () => ({
        stores: [],
        btn_primary,
    }),
    computed: {
        seller() {
            return this.product?.seller;
        },
    },
};
</script>

<style lang="scss" scoped>
.minishop-link {
    padding: 0 8px !important;
}
</style>