var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    staticClass: "row--xs",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "3",
      "md": "2"
    }
  }, [_c('div', {
    staticClass: "txt txt--sm txt--dark"
  }, [_vm._v("상품 쿠폰")])]), _c('v-col', {
    attrs: {
      "cols": "9",
      "md": "10"
    }
  }, [_c('cart-item-coupons-products', _vm._g(_vm._b({
    ref: "cart-item-coupons",
    on: {
      "input": _vm.emit
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on,
          selected = _ref.selected;
        return [_c('v-btn', _vm._g(_vm._b({
          attrs: {
            "block": ""
          }
        }, 'v-btn', Object.assign({}, _vm.btn_secondary, attrs), false), on), [_vm._v(" " + _vm._s(attrs !== null && attrs !== void 0 && attrs.disabled ? "사용 가능한 쿠폰이 없습니다." : selected.length ? selected.map(function (_ref2) {
          var name = _ref2.name;
          return name;
        }).join(", ") : "선택안함") + " ")])];
      }
    }]),
    model: {
      value: _vm.coupons,
      callback: function ($$v) {
        _vm.coupons = $$v;
      },
      expression: "coupons"
    }
  }, 'cart-item-coupons-products', {
    order: _vm.value,
    carts: _vm.carts,
    product: _vm.product
  }, false), {
    pullCoupon: _vm.pullCoupon
  }))], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }