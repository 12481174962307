<template>
    <div class="mt-10">
        <tit-wrap-line noDivider title="주문결제 정보" titleClass="tit--xs" />
        <vertical-form-table v-bind="{ items }">
            <template #주문번호>
                <p>{{ value?.orderNo }}</p>
            </template>
            <template #주문상테>
                <p>{{ ORDER_STATUSES[value?.status].text }}</p>
            </template>
            <template #결제일시>
                <p>{{ value?.createdAt.toDateTime() }}</p>
            </template>
            <template #결제방식>
                <p>{{ paymentMethods[value?.paymentMethod].text }}</p>
            </template>
            <template #입금계좌>
                <p>{{ vbankInfo }}</p>
            </template>
            <template #결제금액>
                <p>{{ value?.totalPrice.format() }}원</p>
            </template>
        </vertical-form-table>
    </div>
</template>

<script>
import { initOrder, ORDER_STATUSES } from "@/assets/variables";
import VerticalFormTable from "@/components/dumb/vertical-form-table.vue";
import TitWrapLine from "@/components/client/dumb/tit-wrap-line.vue";

const paymentMethods = {
    card: { value: "card", text: "신용카드" },
    kakaopay: { value: "kakaopay", text: "카카오페이" },
    naverpayCard: { value: "naverpayCard", text: "네이버페이" },
    samsungpayCard: { value: "samsungpayCard", text: "삼성페이" },
    vbank: { value: "vbank", text: "가상계좌" },
    account: { value: "account", text: "무통장입금" },
};

export default {
    components: {
        VerticalFormTable,
        TitWrapLine,
    },
    props: {
        value: { type: Object, default: initOrder }, //order
    },
    data: () => ({
        ORDER_STATUSES,
        paymentMethods
    }),
    computed: {
        items() {
            return this.value?.paymentMethod === 'vbank' 
            ? [
                { term: "주문번호" }, 
                { term: "주문상테" }, 
                { term: "결제일시" }, 
                { term: "결제방식" }, 
                { term: "입금계좌" },
                { term: "결제금액" },
            ]
            : [
                { term: "주문번호" }, 
                { term: "주문상테" }, 
                { term: "결제일시" }, 
                { term: "결제방식" }, 
                { term: "결제금액" },
            ]
        },
        vbankInfo() {
            let { vbankName, vbankNumber, vbankHolder } = this.value?.vbank;
            return `${vbankName} ${vbankNumber} 예금주: ${vbankHolder}`
        }
    },
};
</script>

<style>
</style>