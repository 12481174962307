<template>
    <carts-row colTag="th" class="border-b" :colProps="{ class: 'py-3' }">
        <template #col1> 상품정보 </template>
        <template #col2> 판매사 </template>
        <template #col3> 판매금액 </template>
        <template #col4> 쿠폰적용가 </template>
        <template #col5> 배송정보 </template>
    </carts-row>
</template>

<script>
import CartsRow from "./carts-row.vue";

export default {
    components: {
        CartsRow,
    },
};
</script>

<style>
</style>