<template>
    <div>
        <vertical-form-table v-model="form" v-bind="{ mode, items }" @input="emit" noDivider>
            <template #주문고객>
                <order-sender v-model="form.sender" />
            </template>

            <template #받는분>
                <v-row class="row--xxs">
                    <v-col cols="8">
                        <v-text-field v-if="isModeForm" hide-details v-model="form.receiver.name" dense @input="emit" />
                        <span v-else>{{ form.receiver.name }}</span>
                    </v-col>
                    <v-col cols="4">
                        <destination-select-dialog v-model="form.receiver" @input="emit">
                            <template #activator="{ attrs, on }">
                                <v-btn outlined tile color="grey" small v-bind="{ ...attrs }" v-on="on">
                                    <span class="primary--text">배송지 목록</span></v-btn>
                            </template>
                        </destination-select-dialog>
                    </v-col>
                </v-row>
                <v-radio-group v-model="mode" row hide-details class="mt-12px">
                    <v-radio v-bind="{ ...radio_primary }" value="default" label="기본배송지"  :disabled="!defaultReceiver?._id"/>
                    <v-radio v-bind="{ ...radio_primary }" value="form" label="새로입력" />
                </v-radio-group>
            </template>

            <template #요청사항>
                <v-text-field dense v-model="form.requestMessage" v-bind="attrs_input_secondary" placeholder="배송지 요청사항을 입력해 주세요.(개인 통관 고유번호 입력)" @input="emit"/>
            </template>
        </vertical-form-table>
    </div>
</template>

<script>
import api from "@/api";
import { mapState } from "vuex";
import { attrs_input, attrs_input_secondary } from "@/assets/variables";
import { initDestination, initOrder, initOrderReceiver } from "@/assets/variables/inits";
import { btn_tertiary, radio_primary, checkbox_secondary } from "@/assets/variables/attrs";
import OrderSender from "./order-sender.vue";
import VerticalFormTable from "@/components/dumb/vertical-form-table.vue";
import DestinationSelectDialog from "../destinations/destination-select-dialog.vue";

export default {
    components: {
        VerticalFormTable,
        OrderSender,
        DestinationSelectDialog,
    },
    props: {
        value: { type: Object, default: initOrder },
    },
    data: () => ({
        attrs_input,
        attrs_input_secondary,
        btn_tertiary,
        radio_primary,
        checkbox_secondary,

        form: initOrder(),
        defaultReceiver: initDestination({ geolocation: { coordinates: [] } }),
        mode: "default",
    }),
    computed: {
        ...mapState(["accessToken"]),
        items() {
            return [
                { term: "주문고객" },
                {
                    term: "배송지명",
                    key: "receiver.title",
                    type: "text",
                    required: true,
                    dense: true,
                },
                {
                    term: "받는분",
                    required: true,
                },
                {
                    term: "주소",
                    key: "receiver",
                    type: "address",
                    persistentPlaceholder: true,
                    btnAttrs: { large: true, color: "primary" },
                    required: true,
                },
                {
                    term: "휴대폰",
                    key: "receiver.phone",
                    type: "phone",
                    required: true,
                    dense: true,
                },
                {
                    term: "연락처",
                    key: "receiver.tel",
                    type: "phone",
                    dense: true,
                },
                {
                    term: "요청사항",
                    type: "text",
                },
            ];
        },
        isDefaultItem() {
            const { _id: _default } = this.defaultReceiver || {};
            return (_default && _default == this.form?.receiver?._id) || false;
        },
        isModeForm() {
            return this.mode === "form";
        }
    },
    methods: {
        async init() {
            if (!this.accessToken) return;
            if (this.loading) return;
            else this.loading = true;

            try {
                this.defaultReceiver = (await api.v1.me.destinations.gets({ headers: { limit: 1 } }))?.destinations[0] ?? initDestination({ geolocation: { coordinates: [] } });
                this.form = initOrder({ ...this.value, receiver: this.defaultReceiver });
                this.emit();
            } finally {
                this.loading = false;
            }
        },
        sync() {
            this.form = initOrder(this.value);

            if (this.isDefaultItem) {
                if (this.mode != "default") this.mode = "default";
            } else if (this.form?.receiver?._id) {
                if (this.mode != "neither") this.mode = "neither";
            } else {
                if (this.mode != "form") this.mode = "form";
            }
        },
        emit() {
            this.$emit("input", this.form);
        }
    },
    mounted() {
        this.sync();
        this.init();
    },
    watch: {
        value() {
            this.sync();
        },
        mode() {
            switch (this.mode) {
                case "default": {
                    this.form = initOrder({ ...this.form, receiver: this.defaultReceiver });
                    this.emit();
                    break;
                }
                case "form": {
                    this.form = initOrder({ ...this.form, receiver: initOrderReceiver({ geolocation: { coordinates: [] } }) });
                    this.emit();
                    break;
                }
            }
        },
        isDefaultItem() {
            this.sync();
        },
        accessToken() {
            this.init();
        },
    },
};
</script>

<style lang="scss" scoped>
.order-info {
    ::v-deep {
        .v-divider {
            display: none !important;
        }
    }
}
</style>