var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('v-container', [_c('div', {
    staticClass: "tit-wrap text-center"
  }, [_c('h2', {
    staticClass: "tit"
  }, [_vm._v("\"" + _vm._s(_vm.searchValue) + "\"검색 결과")])]), _c('search-bar'), !_vm.mode || _vm.mode === _vm.SEARCH_LIST.PRODUCT.value ? _c('search-product-list', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.shows,
      expression: "!shows"
    }],
    on: {
      "input": _vm.total
    }
  }) : _vm._e(), !_vm.mode || _vm.mode === _vm.SEARCH_LIST.STORY.value ? _c('search-story-list', _vm._b({
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.shows,
      expression: "!shows"
    }],
    on: {
      "input": _vm.total
    }
  }, 'search-story-list', {
    hasCompanyScope: _vm.hasCompanyScope
  }, false)) : _vm._e(), !_vm.mode || _vm.mode === _vm.SEARCH_LIST.USER.value ? _c('search-user-list', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.shows,
      expression: "!shows"
    }],
    on: {
      "input": _vm.total
    }
  }) : _vm._e(), !_vm.mode || _vm.mode === _vm.SEARCH_LIST.MINISHOP.value ? _c('search-minishop-list', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.shows,
      expression: "!shows"
    }],
    on: {
      "input": _vm.total
    }
  }) : _vm._e(), _vm.shows ? _c('search-none-text') : _vm._e()], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }