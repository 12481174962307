<template>
    <div class="d-none d-xl-block">
        <v-row no-gutters justify="space-between" align="center">
            <v-col cols="auto">
                <h3 class="tit tit--xs font-weight-light">필터</h3>
            </v-col>
            <v-col cols="auto">
                <div class="d-flex align-center">
                    <div class="txt txt--xs mr-8px">품절 제외</div>
                    <v-switch v-model="filter.exceptSoldOut" @change="search" v-bind="{ ...attrs_switch }" />
                </div>
            </v-col>
        </v-row>
        <v-list expand>
            <v-list-group active>
                <template #activator>
                    <v-list-item-title>상품명</v-list-item-title>
                </template>
                <v-list-item to="">
                    <v-text-field  v-model="filter.name" v-bind="{ ...attrs_input, loading }" dense clearable @keydown.enter="search"/>
                </v-list-item>
            </v-list-group>

            <v-list-group active>
                <template #activator>
                    <v-list-item-title>미니샵명</v-list-item-title>
                </template>
                <v-list-item to="">
                    <!-- <v-text-field v-model="filter.shop" v-bind="{ ...attrs_input, loading }" /> -->
                    <autocomplete-company v-model="filter._seller" v-bind="{ ...attrs_input, loading }" dense clearable @input="search"/>
                </v-list-item>
            </v-list-group>

            <v-list-group v-if="!isBrand" active>
                <template #activator>
                    <v-list-item-title>브랜드명</v-list-item-title>
                </template>
                <v-list-item to="">
                    <v-text-field v-model="filter.brand" v-bind="{ ...attrs_input, loading }" clearable @keydown.enter="search"/>
                </v-list-item>
            </v-list-group>

            <v-list-group active>
                <template #activator>
                    <v-list-item-title>가격</v-list-item-title>
                </template>
                <v-list-item class="d-block">
                    <div class="w-100">
                        <v-row no-gutters justify="space-between" class="txt txt--xs">
                            <v-col cols="auto"><span class="pl-4px">0</span></v-col>
                            <v-col cols="auto"><span class="pr-4px">10,000,000</span></v-col>
                        </v-row>
                        <v-range-slider v-model="filter.prices" :min="min" :max="max" darkhide-details class="align-center">
                        </v-range-slider>
                    </div>
                    <v-row no-gutters align="center">
                        <v-col>
                            <v-currency-field v-bind="{ ...attrs_input, loading }"
                            v-model="filter.prices[0]"
                            :min=0
                            :max=10000000
                            suffix="원"
                            dense
                            class="font-size-12"
                            />
                        </v-col>
                        <v-col cols="auto"><span class="txt txt--xs txt--dark px-4px">~</span></v-col>
                        <v-col>
                            <v-currency-field v-bind="{ ...attrs_input, loading }"
                            v-model="filter.prices[1]"
                            :min=0
                            :max=10000000
                            suffix="원"
                            dense
                            class="font-size-12"
                            />
                        </v-col>
                    </v-row>
                </v-list-item>
            </v-list-group>
        </v-list>
        <!-- <div class="btn-wrap">
            <v-btn-toggle multiple>
                <v-btn class="rounded-pill">무료배송</v-btn>
                <v-btn class="rounded-pill">오늘배송</v-btn>
            </v-btn-toggle>
        </div> -->
        <div class="btn-wrap">
            <v-btn block v-bind="{ ...btn_primary }" @click="search">검색</v-btn>
        </div>
    </div>
    <!-- <div v-if="$vuetify.breakpoint.xlOnly">
        <div class="tit-wrap tit-wrap--sm">
            <h3 class="tit tit--xs">가격</h3>
        </div>
        <v-radio-group :value="activeIndex" hide-details>
            <template v-for="({ label, value }, index) in items">
                <v-radio :key="label" v-bind="{ ...radio_primary, label, value: index }" @click="change(value)" />
            </template>
        </v-radio-group>
    </div>

    <div v-else>
        <v-select :value="active" v-bind="{ ...attrs_input }" :items="[{ label: '전체' }].concat(items)" v-on="{ change }" item-text="label" item-value="value" placeholder="가격" class="mw-md-220px ml-auto" />
    </div> -->
</template>

<script>
import { attrs_input, attrs_switch, btn_primary, btn_secondary } from "@/assets/variables";
import { radio_primary } from "@/assets/variables";
import AutocompleteCompany from "@/components/dumb/autocomplete-company.vue"

export const initPriceFilter=(form = {})=>{
    return {
        name: form?.name || null,
        _seller: form?._seller || null,
        brand: form?.brand || null,
        prices: form?.prices || [0, 10000000],
        exceptSoldOut: form?.exceptSoldOut || true,
    }
}

export default {
    components: {
        AutocompleteCompany
    },
    data() {
        return {
            radio_primary,
            attrs_switch,
            attrs_input,
            btn_primary,
            btn_secondary,
            loading: false,

            min: 0,
            max: 10000000,
            filter: initPriceFilter(),
        };
    },
    computed: {
        // items() {
        //     return prices.map((value) => ({ label: `${value[0]?.format?.()}원 ~ ${value[1]?.format?.()}원`, value }));
        // },
        // activeIndex() {
        //     const { prices } = this.$route.query;
        //     return (
        //         this.items.findIndex(({ value }) => {
        //             const isSame = value[0] == prices?.[0] && value[1] == prices?.[1];
        //             return isSame;
        //         }) ?? "전체"
        //     );
        // },
        // active() {
        //     const { prices } = this.$route.query;
        //     return this.items.find(({ value }) => value[0] == prices?.[0] && value[1] == prices?.[1]) || "전체";
        // },
        isBrand() {
            //브랜드페이지에서 브랜드명 필터 제외
            return this.$route.path.split("/").includes("brands");
        }
    },
    methods: {
        search() {
            let { ...query } = this.$route.query;

            query = {...query, ...this.filter};

            if(!query['category']) delete query.category;
            if(!query['name']) delete query.name;
            if(!query['_seller']) delete query._seller;
            if(!query['brand']) delete query.brand;
            if(!query['exceptSoldOut']) delete query.exceptSoldOut

            delete query.page;

            this.$router.push({ query });
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .v-radio {
        &.v-item--active {
            .v-label {
                color: var(--v-grey-darken4);
            }
        }
    }
    .v-list-item {
        padding: 0;
        &__title {
            font-weight: bold;
        }
    }
    .theme--light.v-btn-toggle:not(.v-btn-toggle--group) .v-btn.v-btn {
        border-color: var(--v-primary-base) !important;
    }
    .v-btn-toggle {
        display: flex;
        justify-content: space-between;
        .v-btn {
            width: calc(50% - 4px);
            background-color: #fff !important;
            border-width: 1px !important;
            opacity: 1 !important;
            &__content {
                color: var(--v-primary-base);
            }
            &::before {
                display: none;
            }
        }
        .v-item--active {
            background-color: var(--v-primary-base) !important;
            .v-btn__content {
                color: #fff;
            }
        }
    }
    .v-input--selection-controls__input {
        margin-right: 0;
    }
    .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded).v-input--dense>.v-input__control>.v-input__slot {
        padding: 0 8px;
    }
    .v-slider__thumb::before {
        display: none;
    }
}
</style>
