var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.categoryDetail ? _c('div', {
    domProps: {
      "innerHTML": _vm._s(_vm.categoryDetail.body)
    }
  }) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }