var render = function render(){
  var _vm$totalDiscountPric, _vm$totalDiscountPric2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-sheet', {
    staticClass: "text-center d-flex justify-space-between justify-md-center",
    attrs: {
      "color": "transparent"
    }
  }, [_c('div', {
    staticClass: "d-md-none grey--text text--darken-4 font-weight-medium text-left mb-4px"
  }, [_vm._v("할인")]), _c('div', [_vm._v(_vm._s((_vm$totalDiscountPric = _vm.totalDiscountPrice) === null || _vm$totalDiscountPric === void 0 ? void 0 : (_vm$totalDiscountPric2 = _vm$totalDiscountPric.format) === null || _vm$totalDiscountPric2 === void 0 ? void 0 : _vm$totalDiscountPric2.call(_vm$totalDiscountPric)) + "원")])]);

}
var staticRenderFns = []

export { render, staticRenderFns }