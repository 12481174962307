var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.items.length ? _c('info-section', {
    staticClass: "txt txt--xs"
  }, [_c('tit-wrap-x-small', {
    attrs: {
      "title": " 연관상품"
    }
  }), _c('v-select', _vm._b({
    attrs: {
      "value": _vm.product._id,
      "item-value": "_id",
      "dense": "",
      "outlined": "",
      "hide-details": "",
      "menu-props": {
        offsetY: true,
        contentClass: 'select-menu',
        closeOnClick: true,
        closeOnContentClick: true
      }
    },
    scopedSlots: _vm._u([{
      key: "selection",
      fn: function (_ref) {
        var item = _ref.item;
        return [_vm._v(" " + _vm._s(item.name) + " ")];
      }
    }, {
      key: "item",
      fn: function (_ref2) {
        var _item$thumb, _item$flags, _item$flags2;
        var item = _ref2.item;
        return [_c('v-list-item', {
          attrs: {
            "to": `/shop/products/${item._id}`,
            "disabled": !item.sells || item.stock == 0
          }
        }, [_c('v-list-item-content', [_c('v-row', {
          staticClass: "row--sm",
          attrs: {
            "align": "center"
          }
        }, [_c('v-col', {
          attrs: {
            "cols": "auto"
          }
        }, [_c('v-card', [_c('v-img', {
          attrs: {
            "width": "60",
            "aspect-ratio": 1 / 1,
            "src": item === null || item === void 0 ? void 0 : (_item$thumb = item.thumb) === null || _item$thumb === void 0 ? void 0 : _item$thumb.url
          },
          scopedSlots: _vm._u([{
            key: "place-holder",
            fn: function () {
              return [_c('v-icon', [_vm._v("mdi-image-broken-variant")])];
            },
            proxy: true
          }], null, true)
        })], 1)], 1), _c('v-col', [_c('div', {
          staticClass: "font-size-14 line-height-135"
        }, [item !== null && item !== void 0 && (_item$flags = item.flags) !== null && _item$flags !== void 0 && _item$flags.length ? _c('div', {
          staticClass: "font-weight-bold"
        }, [_vm._v(_vm._s(item === null || item === void 0 ? void 0 : (_item$flags2 = item.flags) === null || _item$flags2 === void 0 ? void 0 : _item$flags2.map(function (text) {
          return `[${text}]`;
        }).join(" ")))]) : _vm._e(), _c('div', [_vm._v(_vm._s(item === null || item === void 0 ? void 0 : item.name))]), _c('div', {
          staticClass: "mt-8px mt-md-10px"
        }, [item.discountPrice ? _c('div', {
          staticClass: "text-decoration-line-through grey--text text--lighten-1"
        }, [_vm._v(" " + _vm._s(item.price.format()) + " ")]) : _vm._e(), !item.sells || item.stock == 0 ? _c('span', {
          staticClass: "grey--text text--lighten-1"
        }, [_vm._v("품절")]) : _c('div', [item.discountRate ? _c('span', {
          staticClass: "font-weight-medium pr-4px"
        }, [_vm._v(_vm._s(item.discountRate) + "%")]) : _vm._e(), _c('span', [_vm._v(_vm._s(item.salePrice.format()) + "원")])])])])])], 1)], 1)], 1)];
      }
    }], null, false, 2115294768)
  }, 'v-select', {
    items: _vm.items
  }, false))], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }