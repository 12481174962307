var render = function render(){
  var _vm$product;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-sheet', [_c('div', {
    staticClass: "product-details__content",
    attrs: {
      "id": "detail"
    }
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--xs"
  }, [_c('h3', {
    staticClass: "tit tit--xxs"
  }, [_vm._v("상세정보")])]), _c('v-divider', {
    staticStyle: {
      "border-width": "2px 0 0"
    },
    attrs: {
      "dark": ""
    }
  }), _c('product-view-content-cover', _vm._b({}, 'product-view-content-cover', {
    type: _vm.CATEGORY_DETAILS_TYPES.HEAD.value,
    product: _vm.product
  }, false)), _c('section', {
    staticClass: "product-sub",
    domProps: {
      "innerHTML": _vm._s((_vm$product = _vm.product) === null || _vm$product === void 0 ? void 0 : _vm$product.content)
    }
  }), _c('product-view-content-cover', _vm._b({}, 'product-view-content-cover', {
    type: _vm.CATEGORY_DETAILS_TYPES.FOOT.value,
    product: _vm.product
  }, false))], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }