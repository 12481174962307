var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('vertical-form-table', _vm._b({
    attrs: {
      "noDivider": ""
    },
    on: {
      "input": _vm.emit
    },
    scopedSlots: _vm._u([{
      key: "주문고객",
      fn: function () {
        return [_c('order-sender', {
          model: {
            value: _vm.form.sender,
            callback: function ($$v) {
              _vm.$set(_vm.form, "sender", $$v);
            },
            expression: "form.sender"
          }
        })];
      },
      proxy: true
    }, {
      key: "받는분",
      fn: function () {
        var _vm$defaultReceiver;
        return [_c('v-row', {
          staticClass: "row--xxs"
        }, [_c('v-col', {
          attrs: {
            "cols": "8"
          }
        }, [_vm.isModeForm ? _c('v-text-field', {
          attrs: {
            "hide-details": "",
            "dense": ""
          },
          on: {
            "input": _vm.emit
          },
          model: {
            value: _vm.form.receiver.name,
            callback: function ($$v) {
              _vm.$set(_vm.form.receiver, "name", $$v);
            },
            expression: "form.receiver.name"
          }
        }) : _c('span', [_vm._v(_vm._s(_vm.form.receiver.name))])], 1), _c('v-col', {
          attrs: {
            "cols": "4"
          }
        }, [_c('destination-select-dialog', {
          on: {
            "input": _vm.emit
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref) {
              var attrs = _ref.attrs,
                on = _ref.on;
              return [_c('v-btn', _vm._g(_vm._b({
                attrs: {
                  "outlined": "",
                  "tile": "",
                  "color": "grey",
                  "small": ""
                }
              }, 'v-btn', Object.assign({}, attrs), false), on), [_c('span', {
                staticClass: "primary--text"
              }, [_vm._v("배송지 목록")])])];
            }
          }]),
          model: {
            value: _vm.form.receiver,
            callback: function ($$v) {
              _vm.$set(_vm.form, "receiver", $$v);
            },
            expression: "form.receiver"
          }
        })], 1)], 1), _c('v-radio-group', {
          staticClass: "mt-12px",
          attrs: {
            "row": "",
            "hide-details": ""
          },
          model: {
            value: _vm.mode,
            callback: function ($$v) {
              _vm.mode = $$v;
            },
            expression: "mode"
          }
        }, [_c('v-radio', _vm._b({
          attrs: {
            "value": "default",
            "label": "기본배송지",
            "disabled": !((_vm$defaultReceiver = _vm.defaultReceiver) !== null && _vm$defaultReceiver !== void 0 && _vm$defaultReceiver._id)
          }
        }, 'v-radio', Object.assign({}, _vm.radio_primary), false)), _c('v-radio', _vm._b({
          attrs: {
            "value": "form",
            "label": "새로입력"
          }
        }, 'v-radio', Object.assign({}, _vm.radio_primary), false))], 1)];
      },
      proxy: true
    }, {
      key: "요청사항",
      fn: function () {
        return [_c('v-text-field', _vm._b({
          attrs: {
            "dense": "",
            "placeholder": "배송지 요청사항을 입력해 주세요.(개인 통관 고유번호 입력)"
          },
          on: {
            "input": _vm.emit
          },
          model: {
            value: _vm.form.requestMessage,
            callback: function ($$v) {
              _vm.$set(_vm.form, "requestMessage", $$v);
            },
            expression: "form.requestMessage"
          }
        }, 'v-text-field', _vm.attrs_input_secondary, false))];
      },
      proxy: true
    }]),
    model: {
      value: _vm.form,
      callback: function ($$v) {
        _vm.form = $$v;
      },
      expression: "form"
    }
  }, 'vertical-form-table', {
    mode: _vm.mode,
    items: _vm.items
  }, false))], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }