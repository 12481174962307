var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return !_vm.isShippingAvailable ? _c('v-dialog', {
    attrs: {
      "width": "320"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_c('v-icon', _vm._g(_vm._b({
          attrs: {
            "small": "",
            "dense": ""
          }
        }, 'v-icon', attrs, false), on), [_vm._v(" mdi-information-outline ")])];
      }
    }], null, false, 1231735717)
  }, [_c('v-card', [_c('v-card-title', [_vm._v("배송가능지역")]), _c('v-card-text')], 1)], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }