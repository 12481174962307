var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "product-details__content",
    attrs: {
      "id": "review"
    }
  }, [_c('div', {
    staticClass: "product-review"
  }, [_c('review-image-slide'), _c('v-divider', {
    staticClass: "my-40px my-lg-50px"
  }), _c('v-row', {
    staticClass: "row--xxs",
    attrs: {
      "justify": "end",
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-text-field', _vm._b({
    staticClass: "w-100 w-lg-240px",
    attrs: {
      "dense": "",
      "placeholder": "제목, 내용, 상품명, 상품설명, 작성자"
    },
    on: {
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.search.apply(null, arguments);
      }
    },
    model: {
      value: _vm.filter.searchValue,
      callback: function ($$v) {
        _vm.$set(_vm.filter, "searchValue", $$v);
      },
      expression: "filter.searchValue"
    }
  }, 'v-text-field', Object.assign({}, _vm.attrs_input, {
    loading: _vm.loading
  }), false))], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "auto"
    }
  }, [_c('v-btn', _vm._b({
    attrs: {
      "small": ""
    },
    on: {
      "click": _vm.search
    }
  }, 'v-btn', Object.assign({}, _vm.btn_primary, {
    loading: _vm.loading
  }), false), [_vm._v("검색")])], 1)], 1), _vm._l(_vm.reviews, function (review) {
    return _c('review-list-item', _vm._b({
      key: review._id
    }, 'review-list-item', {
      value: review
    }, false));
  }), _c('div', {
    staticClass: "pagination-wrap"
  }, [_c('v-pagination', {
    attrs: {
      "length": _vm.pageCount,
      "total-visible": 11
    },
    on: {
      "input": _vm.search
    },
    model: {
      value: _vm.page,
      callback: function ($$v) {
        _vm.page = $$v;
      },
      expression: "page"
    }
  })], 1)], 2)]);

}
var staticRenderFns = []

export { render, staticRenderFns }