<template>
    <v-sheet>
        <div id="detail" class="product-details__content">
            <div class="tit-wrap tit-wrap--xs">
                <h3 class="tit tit--xxs">상세정보</h3>
            </div>
            <v-divider dark style="border-width: 2px 0 0"></v-divider>

            <product-view-content-cover v-bind="{ type: CATEGORY_DETAILS_TYPES.HEAD.value, product }" />
            <section v-html="product?.content" class="product-sub" />
            <product-view-content-cover v-bind="{ type: CATEGORY_DETAILS_TYPES.FOOT.value, product }" />
        </div>
    </v-sheet>
</template>

<script>
import { CATEGORY_DETAILS_TYPES } from "@/assets/variables";
import ProductViewContentCover from "./product-view-content-cover.vue";

export default {
    components: {
        ProductViewContentCover,
    },
    props: {
        product: { type: Object, default: () => ({}) },
    },
    data: () => ({
        CATEGORY_DETAILS_TYPES,
    }),
};
</script>

<style lang="scss" scoped>
</style>