<template>
    <v-dialog width="320" v-if="!isShippingAvailable">
        <template #activator="{ attrs, on }">
            <v-icon small dense v-bind="attrs" v-on="on"> mdi-information-outline </v-icon>
        </template>
        <v-card>
            <v-card-title>배송가능지역</v-card-title>
            <v-card-text>
                <!-- {{ shippingAreas.join(" / ") || "전체" }} -->
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import { initOrder } from "@/assets/variables";

export default {
    props: {
        value: { type: Object, default: initOrder },
        carts: { type: Array, default: () => [] },
        shippingGroup: { type: Object, default: () => ({}) },
    },
    computed: {
        isShippingAvailable() {
            return this.$getIsShippingAvailable(this.carts, this.value?.receiver);
        },
        shippingAreas() {
            // return this.shippingCodes.find(({ value }) => value == this.shippingGroup.shippingCode)?.areas__available;
        },
    },
};
</script>

<style>
</style>