var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    staticClass: "mt-50px"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "9"
    }
  }, [_c('result-order', _vm._b({}, 'result-order', {
    value: _vm.value
  }, false)), _c('result-purchase', _vm._b({}, 'result-purchase', {
    value: _vm.value
  }, false))], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "3"
    }
  }, [_c('result-total', _vm._b({}, 'result-total', {
    value: _vm.value
  }, false))], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }