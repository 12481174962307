<template>
    <u-dialog v-model="shows" width="800" maxWidth="800" eager v-bind="{ loading }">
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs: { ...attrs, loading, disabled }, on }" />
        </template>

        <!-- <v-row v-for="(cart, index) in carts" :key="index" no-gutters>
            <v-col>
                <order-coupons-dialog-card v-model="order" v-bind="{ cart }" />
            </v-col>
        </v-row> -->
        <template v-for="(sellerGroup, i) in sellerGroups">
            <template v-for="(shippingGroup, j) in sellerGroup.shippingGroups">
                <template v-for="(productGroup, k) in shippingGroup.productGroups">
                    <v-row :key="`${i}-${j}-${k}`" no-gutters>
                        <v-col>
                            <order-coupons-dialog-card v-model="order" v-bind="{
                                seller: sellerGroup.seller,
                                shippingGroup: k == 0 ? shippingGroup : null,
                                productGroup
                                }" 
                                v-on="{ pullCoupon }"
                                @input="emit"
                                ref="carts-body"/>
                        </v-col>
                    </v-row>
                </template>
            </template>
        </template>

        <v-divider style="border: 1px solid black" />
        <p class="py-5">모아고 쿠폰</p>
        <order-coupons-default v-model="order" v-bind="{ carts }" ref="carts-default" @input="emit">
            <template #activator="{ attrs, on, selected }">
                <v-btn v-bind="{ ...btn_secondary, ...attrs, loading }" width="100%" v-on="on">
                    {{ attrs?.disabled ? "사용 가능한 쿠폰이 없습니다." : selected.length ? selected.map(({ name }) => name).join(", ") : "선택안함" }}
                </v-btn>
            </template>
        </order-coupons-default>

        <template #actions>
            <v-btn v-bind="btn_primary" width="100%" @click="save"> 쿠폰 사용하기 </v-btn>
        </template>
    </u-dialog>
</template>

<script>
import { initOrder, btn_primary, btn_secondary } from "@/assets/variables";
import UDialog from "@/components/client/dumb/u-dialog.vue";
import OrderCouponsDialogCard from "./order-coupons-dialog-card.vue";
import OrderCouponsDefault from "./order-coupons-default.vue";

export default {
    components: {
        UDialog,
        OrderCouponsDialogCard,
        OrderCouponsDefault,
    },
    props: {
        value: { type: Object, default: initOrder },
        carts: { type: Array, default: () => [] },
    },
    data: () => ({
        btn_primary,
        btn_secondary,
        shows: false,
        loading: false,
        disabled: false,

        order: initOrder(),
        sellerGroups: []
    }),
    computed: {},
    methods: {
        sync(value) {
            this.order = initOrder(value ? value : this.value);
            this.sellerGroups = this.$getCartSellerGroups(this.carts, this.order.coupons);
        },
        async emit() {
            if (this.loading) return;
            else this.loading = true;

            try {
                await Promise.all([this.$refs["carts-body"]].flat().map(async (child) => await child?.init?.()));
                await Promise.all([this.$refs["carts-default"]].flat().map(async (child) => await child?.init?.()));
            } finally {
                this.$nextTick(() => {
                    let { coupons } = this.order;
                    this.$emit("input", { ...this.value, coupons });
                    this.loading = false;
                });
            }
        },
        save() {
            if (this.loading) return;
            else this.loading = true;

            try {
                this.$emit("input", initOrder(this.order));
                this.shows = false;
            } finally {
                this.loading = false;
            }
        },
        pullCoupon(coupon) {
            this.order.coupons = this.order.coupons.filter(({ id }) => id !== coupon.id);
            this.order = initOrder(this.order);
        }
    },
    watch: {
        value() {
            this.sync();
        },
        carts() {
            this.sync();
        }
    },
    mounted() {
        this.sync();
    },
};
</script>

<style>
</style>