var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mt-10"
  }, [_c('tit-wrap-line', {
    attrs: {
      "noDivider": "",
      "title": "주문결제 정보",
      "titleClass": "tit--xs"
    }
  }), _c('vertical-form-table', _vm._b({
    scopedSlots: _vm._u([{
      key: "주문번호",
      fn: function () {
        var _vm$value;
        return [_c('p', [_vm._v(_vm._s((_vm$value = _vm.value) === null || _vm$value === void 0 ? void 0 : _vm$value.orderNo))])];
      },
      proxy: true
    }, {
      key: "주문상테",
      fn: function () {
        var _vm$value2;
        return [_c('p', [_vm._v(_vm._s(_vm.ORDER_STATUSES[(_vm$value2 = _vm.value) === null || _vm$value2 === void 0 ? void 0 : _vm$value2.status].text))])];
      },
      proxy: true
    }, {
      key: "결제일시",
      fn: function () {
        var _vm$value3;
        return [_c('p', [_vm._v(_vm._s((_vm$value3 = _vm.value) === null || _vm$value3 === void 0 ? void 0 : _vm$value3.createdAt.toDateTime()))])];
      },
      proxy: true
    }, {
      key: "결제방식",
      fn: function () {
        var _vm$value4;
        return [_c('p', [_vm._v(_vm._s(_vm.paymentMethods[(_vm$value4 = _vm.value) === null || _vm$value4 === void 0 ? void 0 : _vm$value4.paymentMethod].text))])];
      },
      proxy: true
    }, {
      key: "입금계좌",
      fn: function () {
        return [_c('p', [_vm._v(_vm._s(_vm.vbankInfo))])];
      },
      proxy: true
    }, {
      key: "결제금액",
      fn: function () {
        var _vm$value5;
        return [_c('p', [_vm._v(_vm._s((_vm$value5 = _vm.value) === null || _vm$value5 === void 0 ? void 0 : _vm$value5.totalPrice.format()) + "원")])];
      },
      proxy: true
    }])
  }, 'vertical-form-table', {
    items: _vm.items
  }, false))], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }